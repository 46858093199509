import { CancelledError, TimeoutError } from './retry'

export interface RequestInitWithTimeout extends RequestInit {
  timeout?: number
}

export default function fetchWithTimeout(
  input: RequestInfo | URL,
  options: RequestInitWithTimeout = {},
): { response: Promise<Response>; cancel: () => void } {
  const { timeout = 15_000 } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(new TimeoutError()), timeout)

  const response = fetch(input, {
    ...options,
    signal: controller.signal,
  })
    .then(r => {
      clearTimeout(id)
      return r
    })
    .catch(e => {
      clearTimeout(id)
      throw e
    })

  const cancel = () => {
    controller.abort(new CancelledError())
    clearTimeout(id)
  }

  return {
    response,
    cancel,
  }
}
