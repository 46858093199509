import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import NumericalInput from '../../../NumericalInput'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ZERO_FRACTION } from '../../../../constants'
import { AssetRewardsBody, AssetRewardsWrapper } from './GLPBottomSection'
import { processGmxAndGlpData } from '../../../../utils/processGmxAndGlpData'
import { ExpandedContent } from './GLPTotalRewards'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
  useEsGmxToken,
  useGlpAum,
  useGmxBalanceWithSupplies,
  useGmxDepositInfo,
  useGmxPriceUSD,
  useGmxStakingInfos,
  useGmxSupply,
  useGmxToken,
  useGmxVestingInfo,
  useSbfGmxBalance,
  useStakedGmxSupply,
} from '../../../../hooks/gmx/useGmxProtocol'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../../../hooks/useFiatValue'
import useMidAum from '../../../../hooks/gmx/useMidAum'
import { tryParseAmount } from '../../../../state/trade/hooks'
import {
  useDGmxToken,
  useStakeEsGmx,
  useStakeGmx,
  useUnstakeEsGmx,
  useUnstakeGmx,
} from '../../../../hooks/gmx/useGmxDolomiteProxyProtocol'
import { useTokenBalance } from '../../../../state/wallet/hooks'
import useGlpProxyVaultAccount from '../../../../hooks/gmx/useGlpProxyVaultAccount'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useWethToken } from '../../../../hooks/Tokens'
import useIsolationModeUserVaultAddressIfCreated from '../../../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useCreateVaultIsolationModeVault } from '../../../../hooks/useDolomiteIsolationModeProtoocol'
import { formatAmount } from '../../../../utils/formatAmount'
import useGmxProxyVaultAccount from '../../../../hooks/gmx/useGmxProxyVaultAccount'
import { useShowYieldAsApr } from '../../../../state/user/hooks'
import calculateAprToApy from '../../../../utils/calculateAprToApy'

const RewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;
`

const DataValue = styled.div`
  font-weight: 600;
`

const ModifySection = styled.div<{ visible: boolean }>`
  width: calc(100% - 75px);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 10px;
`

const DepositSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const WithdrawSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const SubmitButton = styled.div<{ disabled?: boolean; blue?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    background-color: ${({ theme, blue }) => (blue ? theme.blue2 : theme.green2)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    text-align: center;
        /*${({ disabled }) => disabled && 'pointer-events: none;'}*/
    margin-top: 12px;

    :hover {
        background-color: ${({ theme, disabled, blue }) =>
          blue ? !disabled && theme.blue2 : !disabled && theme.green1};
    }

    > div {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
        margin-top: -1px;
        margin-bottom: -5px;
    }

    svg {
        color: ${({ theme }) => theme.text1};
    }
`

const ActionButton = styled.div<{ selected: boolean }>`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg1)};

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg7)};
  }

  :nth-of-type(1) {
    border-top-right-radius: 8px;
  }

  :nth-of-type(2) {
    border-top-left-radius: 8px;
  }
`

const ActionButtonsWrapper = styled.div`
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-top: 20px;
`

const BalanceWrapper = styled.div`
  color: #d5d6e1;
  display: inline-block;
  vertical-align: top;
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
`

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  font-weight: 500;

  svg {
    margin-bottom: -2px !important;
  }
`

const MaxButton = styled.div`
  cursor: pointer;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: -50px;
  margin-bottom: 15px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  > div > div {
    justify-content: end;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const YieldTooltipWrapper = styled.div`
  width: 240px;
  height: auto;
  padding: 10px 12px;
`

const TooltipStatRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;

  div:nth-of-type(1) {
    font-weight: 500;
  }
`

const BoostSection = styled.div`
  margin-bottom: 8px;
`

const HorizontalLine = styled.div`
  border-top: 1px solid ${({ theme }) => theme.text1};
`

const TooltipDescriptionText = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 500;
  margin-top: 10px;
`

interface AprTooltipProps {
  ethYield: string
  ethBoostedYield?: string
  ethTotalYieldWithBoost: string
  esGmxYield: string
}

const YieldTooltip = ({ ethYield, ethBoostedYield, ethTotalYieldWithBoost, esGmxYield }: AprTooltipProps) => {
  const [showYieldAsApr] = useShowYieldAsApr()
  const [t] = useTranslation()
  const aprText = showYieldAsApr ? t('APR') : t('APY')
  return (
    <YieldTooltipWrapper>
      <TooltipStatRow>
        <div>ETH Base {aprText}:</div>
        <div>{ethYield}</div>
      </TooltipStatRow>
      {!!ethBoostedYield && (
        <BoostSection>
          <TooltipStatRow>
            <div>ETH Boosted {aprText}:</div>
            <div>{ethBoostedYield}</div>
          </TooltipStatRow>
          <HorizontalLine />
          <TooltipStatRow>
            <div>ETH Total {aprText}:</div>
            <div>{ethTotalYieldWithBoost}</div>
          </TooltipStatRow>
        </BoostSection>
      )}
      <TooltipStatRow>
        <div>esGMX {aprText}:</div>
        <div>{esGmxYield}</div>
      </TooltipStatRow>
      <TooltipDescriptionText>
        Yields are updated weekly on Wednesday and will depend on the fees collected for the week.
      </TooltipDescriptionText>
    </YieldTooltipWrapper>
  )
}

enum SelectedAsset {
  GMX_TAB = 0,
  ES_GMX_TAB = 1,
}

enum StakeOrUnstakeTab {
  STAKE_TAB = 0,
  UNSTAKE_TAB = 1,
}

const OPTIONS = ['GMX', 'esGMX']

export default function StakeGmx() {
  const [showYieldAsApr] = useShowYieldAsApr()
  const glpVaultAccount = useGlpProxyVaultAccount()
  const gmxVaultAccount = useGmxProxyVaultAccount()
  const { t } = useTranslation()
  const [selectedAsset, setSelectedAsset] = useState(SelectedAsset.GMX_TAB)
  const [selectedTab, setSelectedTab] = useState(StakeOrUnstakeTab.STAKE_TAB)
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)

  const gmxToken = useGmxToken()
  const esGmxToken = useEsGmxToken()
  const token = useMemo(() => {
    if (selectedAsset === SelectedAsset.GMX_TAB) {
      return gmxToken
    } else if (selectedAsset === SelectedAsset.ES_GMX_TAB) {
      return esGmxToken
    }
    return undefined
  }, [gmxToken, esGmxToken, selectedAsset])
  const parsedInputValue = useMemo(() => {
    if (!token) {
      return undefined
    }
    return tryParseAmount(inputValue, token)
  }, [inputValue, token])

  const wethToken = useWethToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => [wethToken], [wethToken]))
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(glpVaultAccount)
  const [vestingData] = useGmxVestingInfo(glpVaultAccount)
  const [gmxPrice] = useGmxPriceUSD()
  const [aums] = useGlpAum()
  const aum = useMidAum(aums)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(glpVaultAccount)
  const [depositBalanceData] = useGmxDepositInfo(glpVaultAccount)
  const stakedGmxSupply = useStakedGmxSupply()
  const gmxSupply = useGmxSupply()
  const gmxWalletBalance = useTokenBalance(gmxVaultAccount, useGmxToken())
  const sbfGmxBalance = useSbfGmxBalance(glpVaultAccount)

  const processedData = useMemo(() => {
    return processGmxAndGlpData({
      balanceData: balanceAndSupplyData?.balanceData,
      supplyData: balanceAndSupplyData?.supplyData,
      depositBalanceData,
      stakingData,
      vestingData,
      aum,
      nativeTokenPrice: wethPrice,
      stakedGmxSupply,
      gmxPrice,
      gmxSupply,
      sbfGmxBalance,
    })
  }, [
    aum,
    balanceAndSupplyData,
    depositBalanceData,
    gmxPrice,
    gmxSupply,
    stakedGmxSupply,
    stakingData,
    vestingData,
    wethPrice,
    sbfGmxBalance,
  ])

  const dataLoaded =
    !!processedData &&
    Object.keys(processedData).length !== 0 &&
    !!vestingData &&
    Object.keys(vestingData).length !== 0 &&
    !!stakingData &&
    Object.keys(stakingData).length !== 0

  const maxAmount = useMemo(() => {
    if (selectedAsset === SelectedAsset.ES_GMX_TAB) {
      if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
        return processedData?.esGmxBalance
      } else {
        return processedData?.maxUnstakeableGmx.lessThan(processedData.esGmxInStakedGmx) &&
          vestingData?.gmxVester?.pairAmount.greaterThan(ZERO_FRACTION)
          ? processedData.maxUnstakeableGmx
          : processedData?.esGmxInStakedGmx
      }
    } else {
      if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
        return gmxWalletBalance?.asFraction
      } else {
        return processedData?.maxUnstakeableGmx.lessThan(processedData.gmxInStakedGmx) &&
          vestingData?.gmxVester?.pairAmount.greaterThan(ZERO_FRACTION)
          ? processedData.maxUnstakeableGmx
          : processedData?.gmxInStakedGmx
      }
    }
  }, [
    gmxWalletBalance?.asFraction,
    processedData?.esGmxBalance,
    processedData?.esGmxInStakedGmx,
    processedData?.gmxInStakedGmx,
    processedData?.maxUnstakeableGmx,
    selectedAsset,
    selectedTab,
    vestingData?.gmxVester?.pairAmount,
  ])
  const insufficientBalance = !maxAmount || parsedInputValue?.asFraction.greaterThan(maxAmount)
  const isButtonActive = !submitting && parsedInputValue?.greaterThan(ZERO_FRACTION) && !insufficientBalance

  const updateSelectedAsset = useCallback(
    (newSelectedAsset: SelectedAsset) => {
      if (newSelectedAsset !== selectedAsset) {
        setSelectedAsset(newSelectedAsset)
        setInputValue('')
      }
    },
    [selectedAsset],
  )

  const updateSelectedTab = useCallback(
    (newSelectedTab: StakeOrUnstakeTab) => {
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab)
        setInputValue('')
      }
    },
    [selectedTab],
  )

  const updateInput = useCallback((inputString: string) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (inputString === '' || re.test(inputString)) {
      setInputValue(inputString)
    }
  }, [])

  const { callback: stakeGmxCallback } = useStakeGmx(parsedInputValue)
  const { callback: stakeEsGmxCallback } = useStakeEsGmx(parsedInputValue)
  const { callback: unstakeGmxCallback } = useUnstakeGmx(parsedInputValue)
  const { callback: unstakeEsGmxCallback } = useUnstakeEsGmx(parsedInputValue)
  const dGmx = useDGmxToken()
  const gmxUserVault = useIsolationModeUserVaultAddressIfCreated(dGmx)
  const { callback: createVaultCallback } = useCreateVaultIsolationModeVault(dGmx)

  const submitTransaction = useCallback(() => {
    if (!createVaultCallback) {
      // GUARD statement
      return
    }

    if (!gmxUserVault) {
      setSubmitting(true)
      setAwaitingSignature(true)
      createVaultCallback()
        .then(() => {
          setSubmitting(false)
          setAwaitingSignature(false)
        })
        .catch(() => {
          setSubmitting(false)
          setAwaitingSignature(false)
        })
      return
    }

    if (!stakeGmxCallback || !unstakeGmxCallback || !stakeEsGmxCallback || !unstakeEsGmxCallback) {
      // GUARD statement
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    let callback: () => Promise<string>
    if (selectedAsset === SelectedAsset.GMX_TAB) {
      if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
        callback = stakeGmxCallback
      } else if (selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
        callback = unstakeGmxCallback
      } else {
        console.warn('Invalid selected tab for GMX', selectedTab)
        return
      }
    } else if (selectedAsset === SelectedAsset.ES_GMX_TAB) {
      if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
        callback = stakeEsGmxCallback
      } else if (selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
        callback = unstakeEsGmxCallback
      } else {
        console.warn('Invalid selected tab for esGMX', selectedTab)
        return
      }
    } else {
      console.warn('Invalid selected asset', selectedAsset)
      return
    }

    callback()
      .then(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [
    createVaultCallback,
    selectedAsset,
    selectedTab,
    stakeEsGmxCallback,
    stakeGmxCallback,
    unstakeEsGmxCallback,
    unstakeGmxCallback,
    gmxUserVault,
  ])

  const setMax = useCallback(() => {
    if (token && maxAmount) {
      setInputValue(maxAmount.toFixed(token.decimals))
    }
  }, [maxAmount, token])

  const gmxYieldForNativeToken =
    showYieldAsApr || !processedData?.gmxAprForNativeToken
      ? processedData?.gmxAprForNativeToken
      : calculateAprToApy(processedData.gmxAprForNativeToken)
  const gmxBoostYieldForNativeToken =
    showYieldAsApr || !processedData?.gmxBoostAprForNativeToken
      ? processedData?.gmxBoostAprForNativeToken
      : calculateAprToApy(processedData.gmxBoostAprForNativeToken)
  const gmxYieldForNativeTokenWithBoost =
    showYieldAsApr || !processedData?.gmxAprForNativeTokenWithBoost
      ? processedData?.gmxAprForNativeTokenWithBoost
      : calculateAprToApy(processedData.gmxAprForNativeTokenWithBoost)
  const gmxYieldForEsGmx =
    showYieldAsApr || !processedData?.gmxAprForEsGmx
      ? processedData?.gmxAprForEsGmx
      : calculateAprToApy(processedData.gmxAprForEsGmx)
  const gmxYieldTotalWithBoost =
    gmxYieldForNativeToken && gmxBoostYieldForNativeToken
      ? gmxYieldForNativeToken.add(gmxBoostYieldForNativeToken)
      : undefined
  const reserveAmount = useMemo(() => {
    if (!processedData || !sbfGmxBalance) {
      return undefined
    }
    return processedData.gmxInStakedGmx.add(processedData.esGmxInStakedGmx).subtract(sbfGmxBalance.asFraction)
  }, [processedData, sbfGmxBalance])

  return (
    <AssetRewardsWrapper>
      <h2>Stake</h2>
      <StyledTabs
        value={selectedAsset}
        onChange={(_: any, index: number) => updateSelectedAsset(index)}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(OPTIONS ?? []).map((option: string, index: number) => (
          <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
        ))}
      </StyledTabs>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>Staked</DataName>
              <DataValue>
                {selectedAsset === SelectedAsset.GMX_TAB
                  ? `${formatAmount(processedData?.gmxInStakedGmx, 2, true)} GMX ($${formatAmount(
                      processedData?.gmxInStakedGmxUsd,
                      2,
                      true,
                    )})`
                  : `${formatAmount(processedData?.esGmxInStakedGmx, 2, true)} esGMX ($${formatAmount(
                      processedData?.esGmxInStakedGmxUsd,
                      2,
                      true,
                    )})`}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>{showYieldAsApr ? t('APR') : t('APY')}</DataName>
              <DataValue>
                <StyledTooltip
                  title={
                    <YieldTooltip
                      ethYield={formatAmount(gmxYieldForNativeToken, 2, true)}
                      ethBoostedYield={
                        gmxBoostYieldForNativeToken?.greaterThanOrEqual(new Fraction(1, 10000))
                          ? formatAmount(gmxBoostYieldForNativeToken, 2, true)
                          : undefined
                      }
                      ethTotalYieldWithBoost={formatAmount(gmxYieldForNativeTokenWithBoost, 2, true)}
                      esGmxYield={formatAmount(gmxYieldForEsGmx, 2, true)}
                    />
                  }
                  position={'top'}
                >
                  <div style={{ cursor: 'default' }}>{formatAmount(gmxYieldTotalWithBoost)}</div>
                </StyledTooltip>
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Reserved for Vesting</DataName>
              <DataValue>
                <StyledTooltip title={''} position={'top'}>
                  <div style={{ cursor: 'default' }}>{formatAmount(reserveAmount, 4, true)}</div>
                </StyledTooltip>
              </DataValue>
            </DataRow>
          </DataSection>
          <ActionButtonsWrapper>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.STAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.STAKE_TAB)
              }}
            >
              Stake
            </ActionButton>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.UNSTAKE_TAB)
              }}
            >
              Unstake
            </ActionButton>
          </ActionButtonsWrapper>
          <ExpandedContent expandedHeight={180}>
            <DepositSection visible={selectedTab === StakeOrUnstakeTab.STAKE_TAB}>
              <h3>
                Stake {selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'}
                <StyledTooltipWithIcon
                  tooltipText={
                    selectedAsset === SelectedAsset.GMX_TAB
                      ? 'Stake GMX from your Dolomite Vault in order to earn rewards.'
                      : 'Stake esGMX from your Dolomite Vault (not your Dolomite balance) in order to earn rewards.'
                  }
                />
              </h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {selectedAsset === SelectedAsset.GMX_TAB
                      ? t('unstakedGmxBalance', {
                          balanceInput: formatAmount(gmxWalletBalance ?? ZERO_FRACTION, 4, true),
                        })
                      : t('vaultBalance', { balanceInput: formatAmount(maxAmount ?? ZERO_FRACTION, 6, true) })}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={
                      selectedAsset === SelectedAsset.GMX_TAB
                        ? 'This represents the balance in your Dolomite Vault (this is different from your Dolomite Balance) that is not staked. You should stake your full balance to start accruing staking rewards.'
                        : 'This represents the balance in your Dolomite Vault, not what is deposited onto Dolomite. Currently Dolomite does not support esGMX deposits.'
                    }
                  />
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !dataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientBalance
                    ? 'Entered amount is greater than the balance in your Dolomite Vault'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !dataLoaded}
                  blue={!gmxUserVault}
                  onClick={() => isButtonActive && dataLoaded && submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !dataLoaded ? (
                    'Loading data...'
                  ) : !gmxUserVault ? (
                    'Create Dolomite Vault'
                  ) : (
                    'Stake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </DepositSection>
            <WithdrawSection visible={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}>
              <h3>
                Unstake {selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'}
                <StyledTooltipWithIcon
                  tooltipText={
                    selectedAsset === SelectedAsset.GMX_TAB
                      ? 'Unstake GMX from earning rewards. Unstaked GMX is moved back to your Dolomite Vault and does not impact your Dolomite Balance.'
                      : 'Unstake esGMX from earnings rewards to your Dolomite Vault (not your Dolomite Balance).'
                  }
                />
              </h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {t('stakedBalance', { balanceInput: formatAmount(maxAmount ?? ZERO_FRACTION, 6, true) })}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    // tooltipText={`This represents the amount of ${
                    //   selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'
                    // } you currently have staked. If you unstake, the ${
                    //   selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'
                    // } will withdraw to your Dolomite Vault. This does not impact your Dolomite Balance.`}
                    tooltipText={
                      selectedAsset === SelectedAsset.GMX_TAB
                        ? `This represents the amount of GMX you have available to be unstaked. If you have GMX being
                        used to vest esGMX, it may subtract from your available unstake amount. If you unstake, the GMX
                        will withdraw to your Dolomite Vault. This does not impact your Dolomite Balance`
                        : `This represents the amount of esGMX you have available to be unstaked. If you unstake, the
                        esGMX will withdraw to your Dolomite Vault. This does not impact your Dolomite Balance`
                    }
                  />
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={selectedAsset === SelectedAsset.GMX_TAB ? 'GMX' : 'esGMX'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !dataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientBalance
                    ? 'Entered amount is greater than the amount staked.'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !dataLoaded}
                  blue={!gmxUserVault}
                  onClick={() => isButtonActive && dataLoaded && submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !dataLoaded ? (
                    'Loading data...'
                  ) : !gmxUserVault ? (
                    'Create Dolomite Vault'
                  ) : (
                    'Unstake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </WithdrawSection>
          </ExpandedContent>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
