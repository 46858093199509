import { useCallback, useEffect } from 'react'
import { useActiveWeb3React, useWeb3ChainId } from '../../hooks'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { updateBlockNumber, updateBlockTimestamp, updateChainId, updateSubgraphBlockNumber } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApolloClient, gql } from '@apollo/client'
import { dolomiteSubgraphClient, getSubgraphLagThreshold, incrementUrlIndexByClientType } from '../../apollo/client'
import { useMulticallContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../multicall/hooks'
import { ChainId } from '../../constants'
import { BaseProvider } from '@ethersproject/providers'
import state, { AppState } from '../index'
import { GraphqlClientType } from '../graphql/actions'
import { ACTIVE_NETWORKS } from '../../constants/chainId'

interface MetaResult {
  _meta: {
    block: {
      number: number
    }
  }
}

export default function Updater(): null {
  const web3ChainId = useWeb3ChainId()
  const { library } = useActiveWeb3React()
  const dispatch = useDispatch()
  const windowVisible = useIsWindowVisible()

  const blockNumberCallback = useCallback(
    (chainId: ChainId, blockNumber: number) => {
      dispatch(
        updateBlockNumber({
          chainId,
          blockNumber,
        }),
      )
    },
    [dispatch],
  )

  const blockTimestampCallback = useCallback(
    (chainId: ChainId, blockTimestamp: number) => {
      dispatch(
        updateBlockTimestamp({
          chainId,
          blockTimestamp,
        }),
      )
    },
    [dispatch],
  )

  const subgraphBlockNumberCallback = useCallback(
    (chainId: ChainId, subgraphBlockNumber: number) => {
      dispatch(
        updateSubgraphBlockNumber({
          chainId,
          subgraphBlockNumber,
        }),
      )
    },
    [dispatch],
  )

  // attach/detach listeners
  useEffect(() => {
    if (!library || !windowVisible) {
      return undefined
    }

    getLatestBlockFromLibraryAndPerformCallback(library, blockNumberCallback)

    const intervalId = setInterval(() => {
      getLatestBlockFromLibraryAndPerformCallback(library, blockNumberCallback)
    }, 5_000)

    return () => {
      clearInterval(intervalId)
    }
  }, [blockNumberCallback, library, windowVisible])

  useEffect(() => {
    if (!windowVisible) {
      return undefined
    }

    ACTIVE_NETWORKS.forEach(chainId =>
      getLatestBlockFromSubgraphAndPerformCallback(dolomiteSubgraphClient, subgraphBlockNumberCallback, chainId),
    )

    const intervalId = setInterval(() => {
      ACTIVE_NETWORKS.forEach(chainId =>
        getLatestBlockFromSubgraphAndPerformCallback(dolomiteSubgraphClient, subgraphBlockNumberCallback, chainId),
      )
    }, 5_000)

    return () => {
      clearInterval(intervalId)
    }
  }, [subgraphBlockNumberCallback, windowVisible])

  const multicall = useMulticallContract()
  const blockTimestampResult = useSingleCallResult(multicall, 'getCurrentBlockTimestamp')

  const blockTimestampChainId = blockTimestampResult.chainId
  const blockTimestamp = blockTimestampResult.result?.[0]?.toNumber() ?? 0
  useEffect(() => {
    blockTimestampCallback(blockTimestampChainId, blockTimestamp)
  }, [blockTimestamp, blockTimestampCallback, blockTimestampChainId])

  const [chainId, libraryBlockNumber, subgraphBlockNumber] = useSelector((state: AppState) => [
    state.chain.chainId,
    state.chain.blockNumberMap[state.chain.chainId],
    state.chain.subgraphBlockNumberMap[state.chain.chainId],
  ])
  useEffect(() => {
    if (!windowVisible) {
      return
    }

    if (
      subgraphBlockNumber &&
      libraryBlockNumber &&
      libraryBlockNumber - subgraphBlockNumber > getSubgraphLagThreshold(chainId)
    ) {
      const didIncrement = incrementUrlIndexByClientType(GraphqlClientType.Dolomite, dispatch, chainId)
      if (didIncrement) {
        console.warn(
          'Subgraph block number is too far behind head, incrementing url index',
          subgraphBlockNumber,
          libraryBlockNumber,
        )
      }
    }
  }, [windowVisible, dispatch, chainId, libraryBlockNumber, subgraphBlockNumber])

  useEffect(() => {
    dispatch(updateChainId({ chainId: web3ChainId }))
  }, [dispatch, web3ChainId])

  return null
}

function getLatestBlockFromLibraryAndPerformCallback(
  library: BaseProvider,
  blockNumberCallback: (chainId: ChainId, blockNumber: number) => void,
) {
  const networkPromise = library.getNetwork()
  networkPromise
    .then(network =>
      library
        .getBlockNumber()
        .then((blockNumber: number) => blockNumberCallback(network.chainId, blockNumber))
        .catch(error => console.warn(`Failed to get block number for chainId: ${network.chainId}`, error)),
    )
    .catch(error => console.warn(`Failed to get network from library`, error))
}

const GET_BLOCK_NUMBER_GQL = gql`
  query getLatestBlockNumber {
    _meta {
      block {
        number
      }
    }
  }
`

function getLatestBlockFromSubgraphAndPerformCallback(
  client: ApolloClient<any>,
  subgraphBlockNumberCallback: (chainId: ChainId, subgraphBlockNumber: number) => void,
  chainId: ChainId,
) {
  if (state.getState().chain.chainId !== chainId) {
    return
  }

  client
    .query<MetaResult>({
      query: GET_BLOCK_NUMBER_GQL,
      fetchPolicy: 'network-only',
      context: { chainId },
    })
    .then(result => {
      const blockNumber = result.data._meta.block.number
      if (blockNumber) {
        subgraphBlockNumberCallback(chainId, blockNumber)
      }
    })
    .catch(error => {
      console.warn(`Failed to get subgraph block number for chainId for interval: ${chainId}`, error.message, error)
    })
}
