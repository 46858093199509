import { createAction } from '@reduxjs/toolkit'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { InterestRateDataResponse, MarketRiskDataResponse } from './reducer'
import { TokenBalancesWithLoadingIndicator } from '../wallet/hooks'
import { ChainId, ChainIdMap } from '../../constants/chainId'
import { Token } from '@dolomite-exchange/v2-sdk'

export const updateFiatPricesWithLoadingIndicator = createAction<{
  chainId: ChainId
  mapData:
    | {
        fiatPriceMap: Record<string, Fraction | undefined>
        isLoading: boolean
      }
    | undefined
}>('data/updateFiatPricesWithLoadingIndicator')

export const updateMarketRiskData = createAction<MarketRiskDataResponse>('data/updateMarketRiskData')

export const updateInterestRateData = createAction<{
  chainId: ChainId
  interestData: InterestRateDataResponse | undefined
}>('data/interestRateData')

export const updateDolomiteBalancesWithLoadingIndicator = createAction<TokenBalancesWithLoadingIndicator>(
  'data/updateDolomiteBalancesWithLoadingIndicator',
)

export const updateAllTokenData = createAction<ChainIdMap<Record<string, Token | undefined>>>('data/updateAllTokenData')

/*
export const updateBlockTimestamp = createAction<{ chainId: ChainId; blockTimestamp: number }>(
  'chain/updateBlockTimestamp',
)

export const updateSubgraphBlockNumber = createAction<{ chainId: ChainId; subgraphBlockNumber: number }>(
  'chain/updateSubgraphBlockNumber',
)

export const updateIsSettingChainId = createAction<{ isSettingChainId: boolean }>('chain/updateIsSettingChainId')

export const updateChainId = createAction<{ chainId: ChainId }>('chain/updateChainId')*/
