import { createReducer } from '@reduxjs/toolkit'
import {
  updateBlockNumber,
  updateBlockTimestamp,
  updateChainId,
  updateIsSettingChainId,
  updateSubgraphBlockNumber,
} from './actions'
import { ChainId } from '../../constants'
import {
  CHAIN_ID,
  ChainIdMap,
  initializeBlockNumberChainIdMap,
  initializeNumberChainIdMap,
  KEY_CHAIN_ID,
  KEY_CHAIN_ID_TO_BLOCK_NUMBER_MAP,
  KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP,
  KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP,
} from '../../constants/chainId'

export interface ChainState {
  readonly blockNumberMap: Readonly<ChainIdMap<number>>
  readonly blockTimestampMap: Readonly<ChainIdMap<number>>
  readonly subgraphBlockNumberMap: Readonly<ChainIdMap<number>>
  readonly chainId: ChainId
  readonly isSettingChainId: boolean
}

const initialState: ChainState = {
  blockNumberMap: getCachedValuesOrDefault(KEY_CHAIN_ID_TO_BLOCK_NUMBER_MAP, false),
  blockTimestampMap: getCachedValuesOrDefault(KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP, false),
  subgraphBlockNumberMap: getCachedValuesOrDefault(KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP, true),
  chainId: CHAIN_ID,
  isSettingChainId: false,
}

function getCachedValuesOrDefault(key: string, isSubgraph: boolean): ChainIdMap<number> {
  const defaultValue = isSubgraph ? initializeBlockNumberChainIdMap() : initializeNumberChainIdMap()
  const cachedValue = JSON.parse(window.localStorage.getItem(key) ?? JSON.stringify(defaultValue))
  Object.values(ChainId)
    .filter((c): c is ChainId => !Number.isNaN(parseInt(c.toString())))
    .forEach(chainId => {
      if (cachedValue[chainId] === undefined) {
        console.log(`Adding default value in for ${key} at chain ID ${chainId}...`)
        cachedValue[chainId] = defaultValue[chainId]
      }
    })
  window.localStorage.setItem(key, JSON.stringify(cachedValue))
  return cachedValue
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      state.blockNumberMap[chainId] = Math.max(blockNumber, state.blockNumberMap[chainId])
      window.localStorage.setItem(KEY_CHAIN_ID_TO_BLOCK_NUMBER_MAP, JSON.stringify(state.blockNumberMap))
    })
    .addCase(updateBlockTimestamp, (state, action) => {
      const { chainId, blockTimestamp } = action.payload
      state.blockTimestampMap[chainId] = Math.max(blockTimestamp, state.blockTimestampMap[chainId])
      window.localStorage.setItem(KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP, JSON.stringify(state.blockTimestampMap))
    })
    .addCase(updateSubgraphBlockNumber, (state, action) => {
      const { chainId, subgraphBlockNumber } = action.payload
      state.subgraphBlockNumberMap[chainId] = Math.max(subgraphBlockNumber, state.subgraphBlockNumberMap[chainId])
      window.localStorage.setItem(
        KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP,
        JSON.stringify(state.subgraphBlockNumberMap),
      )
    })
    .addCase(updateChainId, (state, action) => {
      const { chainId } = action.payload
      window.localStorage.setItem(KEY_CHAIN_ID, chainId.toString())
      state.chainId = chainId
    })
    .addCase(updateIsSettingChainId, (state, { payload: { isSettingChainId } }) => {
      state.isSettingChainId = isSettingChainId
    }),
)
