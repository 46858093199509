import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import GLPTotalRewards from './GLPTotalRewards'
import VestEsGmx from './VestEsGmx'
import StakeEsGmx from './StakeGmx'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import GLPTransferAccount from './GLPTransferAccount'
import useGmxPendingRewards from '../../../../hooks/gmx/useGmxRewards'
import ErrorIcon from '@mui/icons-material/Error'
// Removing this breaks the other styled components and the styles no longer apply. Couldn't figure out why, so I'm just leaving this in here
// const TestDiv = styled.div`
//   font-size: 20px;
// `

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RewardsButtonsWrapper = styled.div`
  @media screen and (max-width: 625px) {
    text-align: center;
    max-width: 290px;
    margin: 0 auto;
  }
`

export const RewardsButton = styled.div<{ supplemental?: boolean }>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 5px;
  background: ${({ theme, supplemental }) => (supplemental ? theme.bg4 : theme.blue1)};
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 12px;
  ${({ supplemental }) => supplemental && 'margin-right: 8px;'}
  display: inline-block;
  position: relative;

  :hover {
    background: ${({ theme, supplemental }) => (supplemental ? theme.bg5 : theme.blue2)};
  }

  svg {
    font-size: 15px;
    margin-bottom: -3px;
    margin-left: 2px;
  }

  @media screen and (max-width: 625px) {
    margin: 4px;
  }
`

export const ClaimRewardsIcon = styled(ErrorIcon)`
  position: absolute;
  font-size: 20px !important;
  top: -8px;
  right: -8px;
`

export default function GLPBottomSection({
  setPopoverComponent,
  setCustomPopoverWidth,
}: {
  setPopoverComponent?: (component: JSX.Element) => void
  setCustomPopoverWidth?: (width: number) => void
}) {
  const [gmxRewards] = useGmxPendingRewards()
  const totalGmxRewards = useMemo(
    () =>
      gmxRewards?.totalNativeTokenRewards.add(gmxRewards?.totalVesterRewards).add(gmxRewards?.extendedGmxTokenRewards),
    [gmxRewards],
  )
  return (
    <RewardsButtonsWrapper>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setCustomPopoverWidth?.(440)
          setPopoverComponent?.(<GLPTransferAccount />)
        }}
        supplemental
      >
        Transfer Account
      </RewardsButton>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<StakeEsGmx />)
        }}
        supplemental
      >
        Staking
      </RewardsButton>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<VestEsGmx />)
        }}
        supplemental
      >
        Vesting
      </RewardsButton>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<GLPTotalRewards />)
        }}
      >
        Asset Rewards <AutoAwesomeIcon />
        {totalGmxRewards?.greaterThan(0) && <ClaimRewardsIcon />}
      </RewardsButton>
    </RewardsButtonsWrapper>
  )
}
