import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'tBTC'
const name = 'Threshold BTC'

function tbtc(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const TBTC: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: tbtc(ChainId.ARBITRUM_ONE, '0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
