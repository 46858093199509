import { createFraction, MarketRiskInfo as MarketRiskInfoGql } from './gqlTypeHelpers'
import { CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { parseUnits } from '@ethersproject/units'
import { useMemo } from 'react'
import { ChainId, ONE_ETH_IN_WEI } from '../constants'
import JSBI from 'jsbi'
import { gql } from '@apollo/client'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useAllTokens, useAllTokensAllChains } from '../hooks/Tokens'
import { reqParseAmount, tryParseAmount } from '../state/trade/hooks'
import { NO_VARIABLES, useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType, Web3CallType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { useStoredMarketRiskData } from '../state/data/hooks'
import { CHAIN_ID_MAP, ChainIdMap, initializeObjectChainIdMap } from '../constants/chainId'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { useActiveWeb3React } from '../hooks'

const MARKET_RISK_INFOS_DATA_GQL = gql`
  query allMarketInfos($blockNumber: Int!) {
    marketRiskInfos(block: { number_gte: $blockNumber }) {
      id
      token {
        id
        marketId
      }
      isBorrowingDisabled
      marginPremium
      liquidationRewardPremium
      oracle
      supplyMaxWei
      borrowMaxWei
    }
  }
`

interface MarketRiskInfoResponse {
  marketRiskInfos: MarketRiskInfoGql[]
}

export interface MarketRiskInfo {
  marketId: JSBI
  tokenAddress: string
  isBorrowingDisabled: boolean
  marginPremium: Fraction
  liquidationRewardPremium: Fraction
  oracleAddress: string
  supplyMaxWei: CurrencyAmount<Token> | undefined
  borrowMaxWei: CurrencyAmount<Token> | undefined
}

const toFraction = (field: string): Fraction => {
  return new Fraction(parseUnits(field, 18).toString(), ONE_ETH_IN_WEI)
}

export function useLoadMarketRiskInfoData(): ChainIdMap<{
  data: Record<string, MarketRiskInfo | undefined>
  loading: boolean
  error: boolean
}> {
  const chainIds = useMemo(() => Object.keys(CHAIN_ID_MAP).filter(c => c !== ChainId.MAINNET.toString()), [])
  const calls = useMemo(() => {
    return chainIds.map<GraphqlCall>(chainId => {
      return {
        chainId: parseInt(chainId) as ChainId,
        clientType: GraphqlClientType.Dolomite,
        query: MARKET_RISK_INFOS_DATA_GQL.loc!.source.body,
        variables: '{}',
      }
    })
  }, [chainIds])
  const marketRiskInfoStates = useGraphqlResultList<MarketRiskInfoResponse>(calls, RefreshFrequency.Slow)

  const tokenMap = useAllTokensAllChains()

  return useMemo(() => {
    let anyError = false
    let anyLoading = false
    return chainIds.reduce((memo, chainId, i) => {
      const state = marketRiskInfoStates?.[i]
      if (!state) {
        memo[parseInt(chainId) as ChainId] = {
          data: {},
          loading: false,
          error: true,
        }
        return memo
      }

      anyError = anyError || state.error
      anyLoading = anyLoading || state.loading

      if (state.error) {
        memo[parseInt(chainId) as ChainId] = {
          data: {},
          loading: false,
          error: true,
        }
        return memo
      }
      if (!state.result && state.loading) {
        memo[parseInt(chainId) as ChainId] = {
          data: {},
          loading: true,
          error: false,
        }
        return memo
      }
      if (state.result) {
        memo[parseInt(chainId) as ChainId] = {
          data: (state.result?.marketRiskInfos ?? []).reduce<Record<string, MarketRiskInfo>>((innerMemo, gql) => {
            const tokenAddress = toChecksumAddress(gql.token.id)
            const token = tokenMap[parseInt(chainId) as ChainId][tokenAddress]
            if (token) {
              innerMemo[tokenAddress] = {
                marketId: JSBI.BigInt(gql.token.marketId),
                tokenAddress: tokenAddress,
                isBorrowingDisabled: gql.isBorrowingDisabled,
                marginPremium: toFraction(gql.marginPremium),
                liquidationRewardPremium: toFraction(gql.liquidationRewardPremium),
                oracleAddress: toChecksumAddress(gql.oracle),
                supplyMaxWei: tryParseAmount(gql.supplyMaxWei, token),
                borrowMaxWei: tryParseAmount(gql.borrowMaxWei, token),
              }
            }
            return innerMemo
          }, {}),
          loading: state.loading,
          error: state.error,
        }
      }
      return memo
    }, initializeObjectChainIdMap() as ChainIdMap<{ data: Record<string, MarketRiskInfo | undefined>; loading: boolean; error: boolean }>)
  }, [chainIds, marketRiskInfoStates, tokenMap])
}

export function useMarketRiskInfoData(
  suppliedChainId?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Record<address, MarketRiskInfo | undefined>
} {
  const { chainId } = useActiveWeb3React()
  return (
    useStoredMarketRiskData()[suppliedChainId ?? chainId] ?? {
      loading: true,
      error: false,
      data: {},
    }
  )
}
