import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Checkmark from '@material-ui/icons/Check'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { GalxeAddressData, useGalxeAddressData } from '../../types/galxeAddressData'
import { CampaignType, Credential, GalxeCampaignData, useGalxeCampaignData } from '../../types/galxeCampaignData'
import { useActiveWeb3React } from '../../hooks'
import { TableLoader } from '../../components/Loader'
import { ExternalLink } from '../../theme'
import { darken, lighten } from 'polished'
import Tabs from '@material-ui/core/Tabs'
import ReactMarkdown from 'react-markdown'
import Sparkle from 'react-sparkle'
import { useMineralDataOnAllNetworks, useUserLifetimeDataOnAllNetworks } from '../../types/userLifetimeData'
import { Fraction } from '@dolomite-exchange/v2-sdk'
import PageTitle from '../../components/common/PageTitle'
import { useHistory, useParams } from 'react-router-dom'
import { useBlockTimestamp } from '../../state/chain/hooks'
import { ChainId } from '../../constants'

const AchievementsWrapper = styled.div`
  margin: 25px auto 35px;
  width: calc(100% - 80px);
  max-width: 940px;
  padding-bottom: 200px;

  @media screen and (max-width: 900px) {
    margin-top: 15px;
    width: 100%;
    max-width: 600px;
  }

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 215px);
  }

  @media screen and (min-width: 5000px) {
    text-align: center;
  }
`

const LeftSide = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 30px);
  max-width: 300px;
  margin-top: 35px;
  margin-right: 20px;

  @media screen and (max-width: 1020px) {
    max-width: 280px;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
    max-width: none;
  }

  @media screen and (min-width: 5000px) {
    text-align: left;
  }
`

const RightSide = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 30px);
  max-width: 600px;
  margin-left: 20px;

  @media screen and (max-width: 1020px) {
    max-width: calc(100% - 320px);
  }

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%;
    max-width: none;
    margin-bottom: 80px;
  }

  @media screen and (min-width: 5000px) {
    text-align: left;
  }
`

const LevelSection = styled.div`
  font-size: 20px;
  text-align: left;
  color: ${({ theme }) => theme.text3};
`

const Level = styled.div`
  text-align: left;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
`

const LevelNumber = styled.div`
  font-size: 100px;
  font-weight: 600;
  margin-top: -20px;
  color: ${({ theme }) => theme.text1};
`

const ProgressCircleWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-left: 30px;
  margin-top: 5px;
`

const ProgressCircle = styled.div<{ degrees: number }>`
  background: conic-gradient(
    ${({ theme }) => theme.blue1} ${({ degrees }) => degrees}deg,
    ${({ theme }) => theme.bg1} 0deg
  );
  background: ${({ theme, degrees }) => degrees === 0 && `${theme.bg1} !important`};
  border-radius: 50%;
  height: 130px;
  width: 130px;
  padding: 5px;
  margin: 0 auto;
`

const ProgressInner = styled.div<{ large: boolean }>`
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #1a1a25;
  text-align: center;
  padding: ${({ large }) => (large ? 43 : 45)}px 0px;
  font-size: ${({ large }) => (large ? 22 : 20)}px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`

const ProgressTextWrapper = styled.div`
  margin-top: -100px;
`

const ProgressText = styled.div`
  font-size: 14px;
  font-weight: 300;

  :nth-of-type(2) {
    margin-top: 27px;
  }
`

const TitleTextWrapper = styled.div`
  text-align: left;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
`

const TitleText = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`

const SubtitleText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};

  a {
    text-decoration: none;
    font-weight: 600;
    color: inherit;

    :hover {
      color: ${({ theme }) => theme.text3};
    }
  }
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: space-around !important;
  min-height: 0 !important;

  > div > div {
    justify-content: space-around !important;
  }

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 5000px) {
    display: none !important;
  }
`

const UltraWideStyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: space-around !important;
  min-height: 0 !important;
  display: none !important;

  > div > div {
    justify-content: space-around !important;
  }

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 5000px) {
    display: block !important;
  }
`

const TabButton = styled.div<{ active: boolean }>`
  font-size: 32px;
  color: ${({ theme, active }) => (active ? theme.text1 : theme.text3)};
  cursor: pointer;
  margin: 5px 0;
  font-weight: 500;
  width: fit-content;

  :hover {
    color: ${({ theme, active }) => (active ? theme.text1 : theme.text2)};
  }

  @media screen and (max-width: 600px) {
    font-size: 28px;
  }

  @media screen and (max-width: 500px) {
    font-size: 24px;
  }

  @media screen and (max-width: 400px) {
    font-size: 22px;
  }
`

const NewFlair = styled.div`
  color: ${({ theme }) => theme.blue1};
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-right: -16px;
`

const SectionDescription = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin: 20px 0 30px;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const Dash = styled.span`
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
  margin-left: 2px;
  margin-right: 2px;
`

const Xp = styled.span<{ large: boolean }>`
  font-size: ${({ large }) => (large ? 13 : 10)}px;
  margin-left: 1px;
`

const CampaignsTitle = styled.div`
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: -5px;
  font-weight: 300;

  :nth-of-type(1) {
    margin-top: 0;
  }
`

export const CampaignWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  margin: 25px 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 20px 25px 18px;
`

const CampaignTitles = styled.div`
  width: 100%;
`

const CampaignTitle = styled(ExternalLink)<{ completed: boolean }>`
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    color: ${({ theme, completed }) => completed && theme.green1};
    text-decoration: none !important;

    :hover {
            /*color: ${({ theme, completed }) =>
              completed ? lighten(0.15, theme.green1) : darken(0.15, theme.text1)};*/
        opacity: 0.65;
    }
`

const XPAmount = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: inherit;
`

const Shift = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: -2px;
`

const Small = styled.span`
  font-size: 12px;
  margin-left: -2px;
`

const CampaignDescription = styled.div<{ expanded: boolean }>`
    font-size: 15px;
    font-weight: 400;
    position: relative;
    color: ${({ theme }) => theme.text2};
    max-height: ${({ expanded }) => (expanded ? 'none' : '60px')};
    overflow: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};

    a {
        color: ${({ theme }) => theme.text2};
    }

    ::before {
        content: '';
        width: 100%;
        height: 60px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(transparent 30px, ${({ theme }) => theme.bg1});
            /*display: ${({ expanded }) => (expanded ? 'none' : 'block')};*/
        opacity: ${({ expanded }) => (expanded ? '0' : '1')};
        transition: ${({ expanded }) => (expanded ? 'none' : 'opacity 0.2s ease 0.3s')};
    }
`

const CampaignStatusWrapper = styled.div<{ completed: boolean }>`
  float: right;
  display: inline-block;
  text-align: right;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 580px) {
    width: ${({ completed }) => (completed ? 'fit-content' : '100%')};
    margin-top: 0;
    margin-bottom: 8px;
    text-align: left;
  }
`

const View = styled.div`
    cursor: pointer;
        /*background-color: ${({ theme }) => theme.bg4};*/
    color: ${({ theme }) => theme.white};
    margin-top: 1px;
    width: fit-content;
    /*padding: 6px 12px;
    border-radius: 5px;*/
    font-weight: 600;
    font-size: 16px;
    display: block;
    color: ${({ theme }) => theme.blue2};

    svg {
        height: 16px;
        margin-left: 0;
        margin-bottom: -2px;
    }

    :hover {
        opacity: 0.65;
            /*background-color: ${({ theme }) => theme.bg5};*/
    }
`

const Uncompleted = styled.div`
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
`

const Completed = styled.div`
  color: ${({ theme }) => theme.green1};
`

const ArrowWrapper = styled.div<{ visible: boolean; off?: boolean }>`
  margin: 2px auto -10px;
  text-align: center;
  cursor: ${({ off }) => !off && 'pointer'};
  transition: transform 0.2s ease-in-out;
  display: ${({ visible }) => !visible && 'none'};

  > svg {
    color: ${({ off, theme }) => off && theme.text3};
  }

  :hover {
    transform: translateY(3px);
  }
`

const Credentials = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? '450px' : '0')};
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;
  margin-left: 20px;
  margin-top: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};

  @media screen and (max-width: 600px) {
    margin-left: 10px;
  }
`

const CredText = styled.div`
  vertical-align: top;
  width: 100%;
  font-weight: 600;
  margin-top: 5px;

  svg {
    font-size: 14px;
    margin-bottom: -2px;
    margin-right: 2px;
  }
`

const Faded = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  padding-left: 5px;

  p {
    margin: 0;
  }
`

const EmptyCampaigns = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: ${({ theme }) => theme.text3};
`

const LoaderWrapper = styled.div`
  width: 100%;
`

const LoaderRow = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  margin-top: 5px;
`

enum CampaignStatus {
  ACTIVE = 0,
  COMPLETE = 1,
  INCOMPLETE = 2,
}

interface ParticipatedCampaign {
  id: string
  name: string
  description: string
  xp: number
  isPrivate: boolean
  type: CampaignType
  completed: boolean
  active: boolean
  credentials: Credential[]
}

const CampaignLoader = () => {
  return (
    <LoaderWrapper>
      {[...new Array(3)].map((_, i) => {
        return (
          <CampaignWrapper key={`campaign-loader-${i}`}>
            <LoaderRow height={23}>
              <TableLoader
                rows={1}
                height={18}
                spacing={23}
                widths={{
                  widths: [60, 10],
                  starts: [0, 90],
                }}
              />
            </LoaderRow>
            <LoaderRow height={45}>
              <TableLoader
                rows={2}
                height={15}
                spacing={20}
                widths={{
                  widths: [100],
                  starts: [0],
                }}
              />
            </LoaderRow>
            <ArrowWrapper visible={true} off={true}>
              <KeyboardArrowDownIcon />
            </ArrowWrapper>
          </CampaignWrapper>
        )
      })}
    </LoaderWrapper>
  )
}

const Campaign = ({ data }: { data: ParticipatedCampaign }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  return (
    <CampaignWrapper>
      <CampaignTitles>
        <CampaignTitle completed={data.completed} href={`https://app.galxe.com/quest/dolomite/${data.id}`}>
          {data.name}
          <XPAmount>
            {' | '}
            <Shift>
              {data.xp} <Small>XP</Small>
            </Shift>
          </XPAmount>
        </CampaignTitle>
        <CampaignStatusWrapper completed={data.completed}>
          {data.completed ? (
            <Completed>
              <Checkmark />
            </Completed>
          ) : !!data.credentials.length && data.active ? (
            <a href={`https://app.galxe.com/quest/dolomite/${data.id}`} target={'_blank'} rel={'noreferrer'}>
              <View onClick={e => e.stopPropagation()}>
                {t('viewOnGalxe')}
                <OpenInNew />
              </View>
            </a>
          ) : (
            <Uncompleted>{t('incomplete')}</Uncompleted>
          )}
        </CampaignStatusWrapper>
        <CampaignDescription
          expanded={expanded}
          dangerouslySetInnerHTML={{
            __html: data.description
              .replace('#dologang', '')
              .replace(
                'https://docs.dolomite.io/community-xp',
                '<a href="https://docs.dolomite.io/community-xp" target="_blank">https://docs.dolomite.io/community-xp</a>',
              ),
          }}
        ></CampaignDescription>
      </CampaignTitles>
      <Credentials expanded={expanded}>
        {data.credentials.map((cred: Credential, index: number) => {
          return (
            <CredText key={`campaign-${data.id}-cred-${index}`}>
              {/*<ChevronRightIcon />*/}
              {cred.name}
              <Faded>
                <ReactMarkdown>{cred.description.replaceAll('\n', '   \n')}</ReactMarkdown>
              </Faded>
            </CredText>
          )
        })}
      </Credentials>
      <ArrowWrapper onClick={() => setExpanded(!expanded)} visible={!!data.credentials.length}>
        {expanded ? <KeyboardArrowUpIcon style={{ marginBottom: '-1px' }} /> : <KeyboardArrowDownIcon />}
      </ArrowWrapper>
    </CampaignWrapper>
  )
}

const MilestoneWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 20px 25px 18px;
`

const MilestoneInfoWrapper = styled.div`
  width: 60%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 22px;
`

const ProgressWrapper = styled.div`
  width: 40%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  margin-bottom: 22px;
`

const CurrentLevel = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.blue1};
`

const AmountToNextLevel = styled.div`
  font-size: 11px;
  margin-top: -5px;
  color: ${({ theme }) => theme.text3};
`

const MilestoneTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.text1};
`

const MilestoneDescription = styled.div`
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  font-size: 12px;
`

const MilestoneMarkerWrapper = styled.div`
  width: 95%;
  margin-left: 2.5%;
`

const MilestoneXPRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 4px;

  a {
    text-decoration: none;
  }
`

const MilestoneXP = styled.div<{ claimable: boolean; hasBeenClaimed: boolean; showClaimable: boolean }>`
  color: ${({ theme, claimable }) => (claimable ? theme.green1 : theme.text2)};
  font-size: 10px;
  font-weight: 600;
  width: 35px;
  text-align: center;
  position: relative;
  ${({ showClaimable }) => showClaimable && 'cursor: pointer;'}
  ${({ showClaimable }) => showClaimable && 'margin-top: -14px;'}
`

const XPValue = styled.span`
  font-size: 12px;
  font-weight: 400;
`

const ClaimButton = styled.div`
  color: ${({ theme }) => theme.text2};
  text-transform: uppercase;
`

const MilestoneLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.text1};
`

const ProgressLine = styled.div<{ width: number }>`
  height: 3px;
  margin-top: -2px;
  background-color: ${({ theme }) => theme.blue1};
  width: ${({ width }) => width}%;
`

const MilestoneMarkRow = styled.div`
  width: calc(100% + 40px);
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  margin-left: -20px;
`

const MilestoneMark = styled.div<{ isLast?: boolean }>`
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.text2};
        /*${({ isLast }) => isLast && `transform: translateX(50%);`}*/
    position: relative;
    width: 40px;
    text-align: center;

    /*:nth-of-type(1) {
      transform: translateX(-50%);
    }*/
`

const MilestoneMarker = styled.div`
  width: 1px;
  height: 6px;
  background-color: ${({ theme }) => theme.text1};
  position: absolute;
  top: -6px;
  left: 50%;
`

const MILESTONES = 0
const SPECIAL = 1
const SOCIAL = 2

interface Milestone {
  amount: number // The quantity needed to reach the milestone
  claimLink: string // The link to where the user can claim the XP
  campaignID: string // The Galxe ID of the campaign
  xp: number
}

interface MilestoneTrack {
  id: number // The ID of the milestone track
  title: string // The title of the track
  description: string // The description of the track
  milestones: Milestone[] // The milestones in the track
  amounts: number[] // The amounts needed to reach the milestones
}

const milestones: MilestoneTrack[] = [
  /*{
    id: 0,
    title: 'Trades',
    description: 'The number of trades performed on Dolomite',
    amounts: [0, 10, 50, 100, 500, 1000, 10000],
    milestones: [
      {
        amount: 10,
        isClaimable: true,
        hasBeenClaimed: true,
        claimLink: 'https://www.google.com',
        xp: 10,
      },
      {
        amount: 100,
        isClaimable: true,
        hasBeenClaimed: false,
        claimLink: 'https://www.google.com',
        xp: 20,
      },
      {
        amount: 1000,
        isClaimable: false,
        hasBeenClaimed: false,
        claimLink: 'https://www.google.com',
        xp: 30,
      },
    ],
  },*/
  {
    id: 0,
    title: 'Zaps',
    description: 'The number of zap trades performed on Dolomite',
    amounts: [0, 10, 50, 100, 500, 1000, 2500],
    milestones: [
      {
        amount: 10,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCsHPUFnJX',
        campaignID: 'GCsHPUFnJX',
        xp: 10,
      },
      {
        amount: 100,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCFUPUF3aN',
        campaignID: 'GCFUPUF3aN',
        xp: 20,
      },
      {
        amount: 1000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GC2pPUFGzm',
        campaignID: 'GC2pPUFGzm',
        xp: 30,
      },
    ],
  },
  {
    id: 1,
    title: 'Borrow Time',
    description: 'The number of hours borrowing on Dolomite',
    amounts: [0, 150, 400, 1250, 4000, 10000, 25000],
    milestones: [
      {
        amount: 150,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCnPkUA6C8',
        campaignID: 'GCnPkUA6C8',
        xp: 10,
      },
      {
        amount: 1250,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCFHkUAPFY',
        campaignID: 'GCFHkUAPFY',
        xp: 20,
      },
      {
        amount: 10000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCDuvUAvKc',
        campaignID: 'GCDuvUAvKc',
        xp: 30,
      },
    ],
  },
  {
    id: 2,
    title: 'Borrow Positions',
    description: 'The number of borrow positions opened on Dolomite',
    amounts: [0, 5, 20, 50, 125, 250, 750],
    milestones: [
      {
        amount: 5,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCJhdUA8qc',
        campaignID: 'GCJhdUA8qc',
        xp: 10,
      },
      {
        amount: 50,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCs2dUAaZA',
        campaignID: 'GCs2dUAaZA',
        xp: 20,
      },
      {
        amount: 250,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCjFgUAg1L',
        campaignID: 'GCjFgUAg1L',
        xp: 30,
      },
    ],
  },
  {
    id: 3,
    title: 'Minerals',
    description: 'The number of Minerals collected on Dolomite',
    amounts: [0, 1000, 40000, 100000, 2000000, 5000000, 20000000, 50000000, 200000000],
    milestones: [
      {
        amount: 1000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCqQsthEg2',
        campaignID: 'GCqQsthEg2',
        xp: 10,
      },
      {
        amount: 100000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GC2isth7xc',
        campaignID: 'GC2isth7xc',
        xp: 20,
      },
      {
        amount: 5000000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GC2qsth57H',
        campaignID: 'GC2qsth57H',
        xp: 30,
      },
      {
        amount: 50000000,
        claimLink: 'https://app.galxe.com/quest/dolomite/GCFzsthDSo',
        campaignID: 'GCFzsthDSo',
        xp: 50,
      },
    ],
  },
]

interface MilestoneRowProps {
  trackData: MilestoneTrack
  milestoneProgress: number[]
  addressData: GalxeAddressData[] | undefined
  isLoadingUserData: boolean
}

const addCommas = (nStr: string) => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

function MilestoneRowComparator(prevProps: MilestoneRowProps, nextProps: MilestoneRowProps): boolean {
  return (
    prevProps &&
    nextProps &&
    JSON.stringify(prevProps.milestoneProgress) === JSON.stringify(nextProps.milestoneProgress) &&
    JSON.stringify(prevProps.addressData) === JSON.stringify(nextProps.addressData) &&
    prevProps.trackData === nextProps.trackData &&
    prevProps.isLoadingUserData === nextProps.isLoadingUserData
  )
}

const MilestoneRow = React.memo(function MilestoneRowComponent({
  trackData,
  milestoneProgress,
  addressData,
}: MilestoneRowProps) {
  const { t } = useTranslation()
  let progressBar = 0
  const currentMilestoneProgress = milestoneProgress[trackData.id]
  let i = 1
  while (currentMilestoneProgress >= trackData.amounts[i]) {
    progressBar += 100 / (trackData.amounts.length - 1)
    i++
  }
  if (i < trackData.amounts.length) {
    progressBar +=
      ((currentMilestoneProgress - trackData.amounts[i - 1]) / (trackData.amounts[i] - trackData.amounts[i - 1])) *
      (100 / (trackData.amounts.length - 1))
  }

  return (
    <MilestoneWrapper>
      <MilestoneInfoWrapper>
        <MilestoneTitle>
          {trackData.title}
          {/*<span style={{ fontWeight: 300, color: '#606375' }}> | {currentMilestoneProgress}</span>*/}
        </MilestoneTitle>
        <MilestoneDescription>{trackData.description}</MilestoneDescription>
      </MilestoneInfoWrapper>
      <ProgressWrapper>
        <CurrentLevel>LVL {i - 1}</CurrentLevel>
        <AmountToNextLevel>
          {addCommas('' + (trackData.amounts[i] - currentMilestoneProgress).toFixed(2))} to next level
        </AmountToNextLevel>
      </ProgressWrapper>
      <MilestoneMarkerWrapper>
        <MilestoneXPRow>
          {trackData.milestones.map(milestone => {
            const isClaimable = currentMilestoneProgress >= milestone.amount
            const hasBeenClaimed = addressData?.some(campaign => campaign.campaignID === milestone.campaignID) ?? true
            const showClaimable = !!addressData && isClaimable && !hasBeenClaimed
            return showClaimable ? (
              <a key={milestone.campaignID} href={milestone.claimLink} target={'_blank'} rel={'noreferrer'}>
                <MilestoneXP
                  claimable={isClaimable}
                  hasBeenClaimed={hasBeenClaimed}
                  showClaimable={showClaimable}
                  key={milestone.amount}
                >
                  <Sparkle overflowPx={4} count={4} fadeOutSpeed={20} color={'#8fc942'} />
                  <XPValue>{milestone.xp}</XPValue>XP
                  <ClaimButton>{t('claim')}</ClaimButton>
                </MilestoneXP>
              </a>
            ) : (
              <MilestoneXP
                claimable={isClaimable}
                hasBeenClaimed={hasBeenClaimed}
                showClaimable={showClaimable}
                key={milestone.amount}
              >
                <XPValue>{milestone.xp}</XPValue>XP
              </MilestoneXP>
            )
          })}
        </MilestoneXPRow>
        <MilestoneLine />
        <ProgressLine width={progressBar} />
        <MilestoneMarkRow>
          {trackData.amounts.map((amount, index) => (
            <MilestoneMark key={amount} isLast={trackData.milestones.length - 1 === index}>
              <MilestoneMarker />
              {amount >= 1_000_000 ? `${amount / 1_000_000}M` : addCommas('' + amount)}
            </MilestoneMark>
          ))}
        </MilestoneMarkRow>
      </MilestoneMarkerWrapper>
    </MilestoneWrapper>
  )
},
MilestoneRowComparator)

const TAB_OPTIONS = ['Milestones', 'Special', 'Social']

function getCampaignStatus(campaign: ParticipatedCampaign): CampaignStatus {
  return !campaign.completed && campaign.active
    ? CampaignStatus.ACTIVE
    : campaign.completed
    ? CampaignStatus.COMPLETE
    : CampaignStatus.INCOMPLETE
}

function partitionCampaigns(array: ParticipatedCampaign[]) {
  const active: ParticipatedCampaign[] = []
  const completed: ParticipatedCampaign[] = []
  const past: ParticipatedCampaign[] = []
  array.forEach(campaign =>
    (getCampaignStatus(campaign) === CampaignStatus.ACTIVE
      ? active
      : getCampaignStatus(campaign) === CampaignStatus.COMPLETE
      ? completed
      : past
    ).push(campaign),
  )
  return [active, completed, past]
}

function filterCampaigns(unsortedCampaigns: ParticipatedCampaign[][], selectedTab: CampaignType) {
  const active = unsortedCampaigns[0]
  const completed = unsortedCampaigns[1]
  const past = unsortedCampaigns[2]
  return [
    active.filter(campaign => campaign.type === selectedTab),
    completed.filter(campaign => campaign.type === selectedTab),
    past.filter(campaign => campaign.type === selectedTab),
  ]
}

const HIDDEN_IDS = ['GCACdtoV7j']

export default function Achievements() {
  const { account, chainId } = useActiveWeb3React()
  const { data: addressData, levelData } = useGalxeAddressData(account)
  const { data: campaignData, loading: campaignLoading } = useGalxeCampaignData()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState(MILESTONES)
  const { data: userData, loading: isLoadingUserData } = useUserLifetimeDataOnAllNetworks(account)
  const { data: totalMinerals, loading: isLoadingMinerals } = useMineralDataOnAllNetworks(account)
  const numZaps = userData?.totalZapCount ?? 0
  const borrowTime = userData?.totalBorrowDurationSeconds.divide(new Fraction(60)).divide(new Fraction(60)) ?? 0
  const numBorrowPositions = userData?.totalBorrowPositionCount ?? 0
  const numZapsParsed = parseFloat(numZaps.toFixed(2))
  const borrowTimeParsed = parseFloat(borrowTime.toFixed(2))
  const numBorrowPositionsParsed = parseFloat(numBorrowPositions.toFixed(2))
  const totalMineralsCollected = parseFloat(totalMinerals?.toFixed(2) ?? '0')
  const milestoneProgress = [numZapsParsed, borrowTimeParsed, numBorrowPositionsParsed, totalMineralsCollected]
  const { page } = useParams<{ page: string }>()
  const history = useHistory()

  useEffect(() => {
    TAB_OPTIONS.map((option, index) => {
      if (option.toLowerCase() === page) setSelectedTab(index)
    })
  }, [page])

  useEffect(() => {
    isLoading && !campaignLoading && setIsLoading(false)
  }, [campaignLoading, isLoading])

  const timestamp = useBlockTimestamp()
  const campaignIDs: string[] = useMemo(() => {
    return addressData?.map((campaignNFT: GalxeAddressData) => campaignNFT.campaignID ?? '') ?? []
  }, [addressData])
  const [activeCampaigns, completedCampaigns, pastCampaigns] = useMemo(() => {
    const campaigns = (campaignData ?? [])
      .sort((a: GalxeCampaignData, b: GalxeCampaignData) => b.endTime - a.endTime)
      .map((campaign: GalxeCampaignData) => {
        return {
          id: campaign.id,
          name: campaign.name,
          description: campaign.description,
          xp: parseFloat(campaign?.xp ? campaign.xp[0]?.value ?? '0' : '0'),
          isPrivate: campaign.isPrivate,
          type: campaign.type,
          completed: campaignIDs.includes(campaign.id),
          active: campaign.endTime > timestamp.toNumber() || !campaign.endTime,
          credentials: campaign.credentials ?? [],
        }
      })
    const unfilteredCampaigns = partitionCampaigns(campaigns)
    const filteredCampaigns = filterCampaigns(unfilteredCampaigns, selectedTab)
    return [
      filteredCampaigns[0].filter(campaign => !HIDDEN_IDS.includes(campaign.id)),
      filteredCampaigns[1],
      filteredCampaigns[2],
    ]
  }, [campaignData, selectedTab, campaignIDs, timestamp])

  const { level, start: startXP, end: nextXP } = levelData

  return (
    <AchievementsWrapper>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | Achievements' : 'Dolomite | Achievements'} />
      <LeftSide>
        <LevelSection>
          <Level>
            Level
            <LevelNumber>{level}</LevelNumber>
          </Level>
          <ProgressCircleWrapper>
            <ProgressCircle degrees={(startXP / nextXP) * 360}>
              <ProgressInner large={nextXP < 100}>
                <div>
                  {startXP}
                  <Dash>/</Dash>
                  {nextXP}
                  <Xp large={nextXP < 100}>XP</Xp>
                </div>
              </ProgressInner>
            </ProgressCircle>
            <ProgressTextWrapper>
              <ProgressText>Progress</ProgressText>
              <ProgressText>to Level {level + 1}</ProgressText>
            </ProgressTextWrapper>
          </ProgressCircleWrapper>
        </LevelSection>
        <TitleTextWrapper>
          <TitleText>Community XP</TitleText>
          <SubtitleText>
            Welcome to Dolomite Community XP! Dolomite XP is our way of recognizing active members of the community and
            users to get involved and try out the various features of Dolomite. We’ve partnered with Galxe, a web3
            community-building platform very well-suited to this initiative.
            <br />
            <br />
            Earning levels is very straightforward. Periodically there will be new campaigns you can complete. By
            completing active campaigns you earn experience points (XP). Earn enough XP and you will advance to the next
            level. That level is shown on the navbar and provides you with a role on our community Discord that reflects
            your level, as well as access to level-exclusive Discord channels.
            <br />
            <br />
            <a href={'https://docs.dolomite.io/community-xp'} target={'_blank'} rel={'noreferrer'}>
              Learn more
            </a>
          </SubtitleText>
        </TitleTextWrapper>
      </LeftSide>
      <RightSide>
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => setSelectedTab(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(TAB_OPTIONS ?? []).map((option: string, index: number) => (
            <TabButton
              key={`achievementsHeader-${index}`}
              active={selectedTab === index}
              onClick={() => history.push(`/achievements/${option.toLowerCase()}`)}
            >
              {option}
            </TabButton>
          ))}
        </StyledTabs>
        <UltraWideStyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => setSelectedTab(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(TAB_OPTIONS ?? []).map((option: string, index: number) => (
            <TabButton
              key={`achievementsHeader-${index}`}
              active={selectedTab === index}
              onClick={() => {
                setSelectedTab(index)
              }}
            >
              {option}
            </TabButton>
          ))}
        </UltraWideStyledTabs>
        <SectionDescription>
          {selectedTab === SOCIAL
            ? `Social campaigns are campaigns based around community involvement outside of the Dolomite web app. This can take the form of things like attending Dolomite AMAs, Twitter Spaces, real-life events, community competitions, and things such as following partnering projects on social media.`
            : selectedTab === SPECIAL
            ? `Special campaigns are campaigns that are usually very specific to a certain task or integration on Dolomite, and generally launched in conjunction with an event such as an integration of a new asset or the launch of a new feature. These are a great way to discover new features, assets, and strategies on Dolomite.`
            : `Milestone campaigns are campaigns that are based around how much you use the different features of Dolomite, allowing you to receive XP for reaching different milestones such as completing 100 trades on Dolomite. Milestone campaigns allow you to earn increasing amounts of XP as you reach higher and higher milestones for a specific feature of Dolomite.`}
        </SectionDescription>
        {selectedTab === MILESTONES && milestones.length > 0 ? (
          milestones.map(milestone => (
            <MilestoneRow
              key={milestone.id}
              trackData={milestone}
              milestoneProgress={milestoneProgress}
              addressData={addressData}
              isLoadingUserData={isLoadingUserData || isLoadingMinerals}
            />
          ))
        ) : (
          <div>
            <CampaignsTitle>Active Campaigns</CampaignsTitle>
            {isLoading && activeCampaigns.length === 0 ? (
              <CampaignLoader />
            ) : activeCampaigns.length === 0 ? (
              <EmptyCampaigns>No Active Campaigns</EmptyCampaigns>
            ) : (
              activeCampaigns.map(campaign => !campaign.isPrivate && <Campaign data={campaign} key={campaign.id} />)
            )}
            <CampaignsTitle>Completed Campaigns</CampaignsTitle>
            {isLoading && completedCampaigns.length === 0 ? (
              <CampaignLoader />
            ) : completedCampaigns.length === 0 ? (
              <EmptyCampaigns>No Completed Campaigns</EmptyCampaigns>
            ) : (
              completedCampaigns.map(campaign => <Campaign data={campaign} key={campaign.id} />)
            )}
            <CampaignsTitle>Past Campaigns</CampaignsTitle>
            {isLoading && pastCampaigns.length === 0 ? (
              <CampaignLoader />
            ) : (
              pastCampaigns.map(campaign => <Campaign data={campaign} key={campaign.id} />)
            )}
          </div>
        )}
      </RightSide>
    </AchievementsWrapper>
  )
}
