import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BalanceCheckFlag, Currency, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import styled from 'styled-components/macro'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '../common/Tooltip'
import Input from '@material-ui/core/Input'
import { useCurrencyBalance, useDolomiteBalancesWithLoadingIndicator, useTokenBalances } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { tryParseAmount } from '../../state/trade/hooks'
import {
  ChainId,
  DOLOMITE_MARGIN_PROTOCOL_ADDRESSES,
  USER_ERROR_CODES,
  WMNT_ISOLATION_MODE_ADDRESSES,
  ZERO_FRACTION,
} from '../../constants'
import { useFiatValuesWithExternalAssetsMap, useFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useTokenAllowances } from '../../data/Allowances'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import { onAttemptToPermit, SignatureDataObject } from '../../hooks/usePermitOrApprove'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useDolomiteMarginContract, useTokenContract } from '../../hooks/useContract'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import {
  useDepositIntoDolomiteMarginProtocol,
  useMarketsTotalWeiData,
  useWithdrawFromDolomiteMarginProtocol,
} from '../../hooks/useDolomiteMarginProtocol'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import Checkmark from '@material-ui/icons/Check'
import { StyledTooltip } from '../common/StyledTooltip'
import { getEtherscanLink } from '../../utils'
import isInputValueCloseToBalance from '../../utils/isInputValueCloseToBalance'
import remapSpenderForAllowance from '../../utils/isolation/remapSpenderForAllowance'
import remapTokenAddressForAllowance from '../../utils/isolation/remapTokenAddressForAllowance'
import {
  useAllActiveTokensArray,
  useAllActiveTokensArrayWithExternalTokens,
  useAllTokens,
  useEther,
  useSerializedCurrencies,
  useSerializedTokens,
} from '../../hooks/Tokens'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { getSpecialAsset, useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import ConfirmUnlockModal, { UnlockStage } from '../ConfirmModal/UnlockModal'
import CurrencyModal from '../CurrencyModal'
import Modal from '../Modal'
import { X } from 'react-feather'

import { formatAmount } from '../../utils/formatAmount'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import {
  isListedTokenForExternalToken,
  mapListedTokenToExternalToken,
  useAddEtherBalanceToWalletBalances,
  useAddExternalTokensToDolomiteData,
} from '../../utils/externalTokens'
import useIsolationModeUserVaultAddressIfCreated from '../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useCreateVaultIsolationModeVault } from '../../hooks/useDolomiteIsolationModeProtoocol'

const DEPOSIT = 0
const WITHDRAW = 1

const DepositWithdrawModalInner = styled.div`
  position: relative;
  width: 100%;
  vertical-align: top;
  background-color: #292938;
  display: inline-block;
  padding: 25px 35px 30px;
  max-width: 900px;
  font-family: 'Open Sans', sans-serif !important;
`

const CheckboxWrapper = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  padding-top: 8px;
  justify-content: end;
`

const CheckboxText = styled.span`
  font-size: 14px;
  margin-right: 4px;
`

export const Checkbox = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`

export const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ theme, isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`

const BeraWarning = styled.div`
  background: ${({ theme }) => theme.bg2};
  border-radius: 5px;
  padding: 10px 16px;
  margin-top: 10px;
  font-size: 10px;
`

const BalanceWrapper = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
`

const MaxButton = styled.div<{ disabled: boolean }>`
  color: #606375;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-block;
  font-size: 14px;
  font-weight: 100;
  vertical-align: top;

  &:hover {
    color: ${({ disabled }) => (disabled ? '#606375' : '#f9f9f9')};
  }
`

const InputWrapper = styled.div`
  position: relative;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:first-child {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div:first-child > div {
    padding-top: 0 !important;
  }
`

const TokenSelector = styled.div<{ isLoading?: boolean }>`
  background-color: #3a3a4f;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  height: 33px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  /*transition: all 0.3s ease-in-out;*/
  width: fit-content;
  z-index: 1;

  ${({ isLoading }) =>
    isLoading &&
    `
    > div {
      opacity: 0.5;
      
      :hover {
        background: none !important;
      }
    }
  `}
`

const TokenSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 25px 5px 10px;
  height: 33px;

  &:hover {
    background-color: #474956;
  }
`

const ErrorText = styled.div`
  color: ${({ theme }) => theme.red1};
  font-size: 12px;
  text-align: right;
  transform: translateY(16px);
  width: 75%;
  margin-left: 25%;
  display: inline-block;
`

const SubmitWrapper = styled.div`
  margin-top: 20px;
  pointer-events: none;
`

const TotalWrapper = styled.div<{ wide: number }>`
  display: inline-block;
  font-size: 13px;
  font-weight: 100;
  margin-right: 8px;
  text-align: right;
  vertical-align: bottom;
  width: calc(100% - 97px);
  pointer-events: auto;

  ${({ wide }) => (wide === 1 ? 'width: calc(100% - 116px);' : wide === 2 ? 'width: calc(100% - 132px);' : '')}
`

const Large = styled.span`
  color: #f9f9f9;
  font-size: 15px;
`

const Muted = styled.span`
  color: #606375;
`

// TODO - decide if it makes more sense to leave pointer events none when disabled because of odd issue where the tooltip doesn't disappear when mouse leaves the button
const SubmitButton = styled(Button)<{ wide?: number; disabled?: boolean; unlocked?: boolean }>`
  display: inline-block;
  margin: 0 !important;
  vertical-align: bottom;
  width: 89px;
  height: 36px;
  color: #d5d6e1 !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: ${({ theme, unlocked }) => (unlocked ? theme.blue1 : theme.green2)} !important;

  ${({ disabled }) =>
    disabled ? `pointer-events: auto; opacity: 0.6;` : `pointer-events: auto !important; cursor: pointer !important;`}
  &:hover {
    ${({ theme, disabled, unlocked }) =>
      !disabled && `background-color: ${unlocked ? theme.blue2 : theme.green1} !important;`}
  }

  > span > div {
    height: 20px !important;
    width: 20px !important;
  }

  svg {
    color: white !important;
  }

  ${({ wide }) => (wide === 1 ? `width: 108px;` : wide === 2 ? `width: 124px;` : '')}
`

const WarningIconWrapper = styled.div`
  display: contents;
  vertical-align: top;
  font-weight: 600;

  svg {
    color: ${({ theme }) => theme.text2};
    height: 20px;
    width: 16px;
    margin-right: -1px;
    margin-bottom: -5px;
    margin-left: -5px;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const RecentTransactionsWrapper = styled.div`
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.text3};
  margin-top: 15px;
  padding-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const RecentTransactionsTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  background: #292938;
  width: fit-content;
  margin-top: -15px;
  padding-right: 5px;
`

const RecentTransactionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`

const TransactionType = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  text-transform: capitalize;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
`

const TransactionAmount = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
`

const TransactionCurrency = styled.div`
  display: inline-block;
  vertical-align: top;
`

const LoadingWrapper = styled.div`
  display: inline-block;
  vertical-align: top;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 15px;
    height: 15px !important;
    width: 15px !important;
  }
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const RecentTransaction = ({
  transaction,
  isLoading,
  chainId,
  hash,
}: {
  transaction: BalanceTransaction
  isLoading: boolean
  chainId: ChainId
  hash?: string
}) => {
  return (
    <RecentTransactionWrapper>
      <div>
        <TransactionType>
          {hash ? (
            <a href={getEtherscanLink(chainId, hash, 'transaction')} target={'_blank'} rel={'noreferrer'}>
              {transaction.type.toLowerCase()}
            </a>
          ) : (
            transaction.type.toLowerCase()
          )}
        </TransactionType>
        {transaction.type !== BalanceTransactionType.UNLOCK && (
          <TransactionAmount>{formatAmount(transaction.amount, undefined, true)}</TransactionAmount>
        )}
        <TransactionCurrency>{cleanCurrencySymbol(transaction.asset, false)}</TransactionCurrency>
      </div>
      <LoadingWrapper>{isLoading ? <CircularProgress /> : <Checkmark />}</LoadingWrapper>
    </RecentTransactionWrapper>
  )
}

enum BalanceTransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  UNLOCK = 'UNLOCK',
  CREATE_VAULT = 'CREATE_VAULT',
}

interface BalanceTransaction {
  asset: Currency
  amount: CurrencyAmount<Currency> | undefined
  type: BalanceTransactionType
  date: number
  isLoaded: boolean
  hash?: string
}

interface DepositWithdrawModalProps {
  open: boolean
  close: () => void
  isUnwrapInitiallySelected?: boolean
  isWithdraw?: boolean
  presetCurrency?: Currency
}

function DepositWithdrawModalComparator(prevProps: DepositWithdrawModalProps, nextProps: DepositWithdrawModalProps) {
  return (
    prevProps.open === nextProps.open &&
    prevProps.close === nextProps.close &&
    prevProps.isUnwrapInitiallySelected === nextProps.isUnwrapInitiallySelected &&
    prevProps.isWithdraw === nextProps.isWithdraw &&
    prevProps.presetCurrency?.symbol === nextProps.presetCurrency?.symbol
  )
}

const DepositWithdrawModal = React.memo(function DepositWithdrawModalComponent({
  open,
  close,
  isUnwrapInitiallySelected,
  isWithdraw,
  presetCurrency,
}: DepositWithdrawModalProps) {
  const web3Context = useActiveWeb3React()
  const { account, chainId } = web3Context
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(DEPOSIT)
  const options = [t('deposit'), t('withdraw')]
  const [isUnwrapSelected, setIsUnwrapSelected] = useState(isUnwrapInitiallySelected ?? false)
  const [inputValue, setInputValue] = useState<string>('')
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isApprovalRequired, setIsApprovalRequired] = useState<boolean>(false)
  const [isAwaitingSignature, setIsAwaitingSignature] = useState<boolean>(false)
  const [unlockModalOpen, setUnlockModalOpen] = useState<boolean>(false)
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [hasSufficientBalance, setHasSufficientBalance] = useState<boolean>(false)
  const [isUnlockModal, setIsUnlockModal] = useState<boolean>(true)
  const [unlockStage, setUnlockStage] = useState<UnlockStage>(UnlockStage.UNLOCK)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [pendingDepositHash, setPendingDepositHash] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [pendingAllowanceCurrency, setPendingAllowanceCurrency] = useState<Currency | undefined>(undefined)
  const [signatureData, setSignatureData] = useState<SignatureDataObject | null>(null)
  const [inputValueAmount, setInputValueAmount] = useState<CurrencyAmount<Currency>>()
  const [isWithdrawAll, setIsWithdrawAll] = useState(false)
  const dolomiteMarginContract = useDolomiteMarginContract()
  const ether = useEther()
  const dolomiteTokens = useAllActiveTokensArray()
  const allTokenListWithExternalTokens = useAllActiveTokensArrayWithExternalTokens()
  const ethBalance = useCurrencyBalance(account, ether)
  const rawWalletBalanceMap = useTokenBalances(account, allTokenListWithExternalTokens)
  const inactiveTokensWithBalance = useMemo(() => {
    return allTokenListWithExternalTokens.filter(
      token => !token.isActive && rawWalletBalanceMap[token.symbol ?? '']?.greaterThan('0'),
    )
  }, [allTokenListWithExternalTokens, rawWalletBalanceMap])
  const walletBalanceMap = useAddEtherBalanceToWalletBalances(ethBalance, rawWalletBalanceMap)

  const { data: rawMarketRiskInfoMap } = useMarketRiskInfoData()
  const marketRiskInfoMap = useAddExternalTokensToDolomiteData(rawMarketRiskInfoMap)
  const [rawMarketTotalWeiMap] = useMarketsTotalWeiData()
  const marketTotalWeiMap = useAddExternalTokensToDolomiteData(rawMarketTotalWeiMap)

  useEffect(() => {
    setIsUnwrapSelected(isUnwrapInitiallySelected ?? false)
  }, [isUnwrapInitiallySelected])

  const currencyList = useSerializedCurrencies(
    useMemo(() => {
      const allAssets = [ether, ...allTokenListWithExternalTokens]
      if (selectedTab === DEPOSIT) {
        return allAssets
      }
      return allAssets.concat(inactiveTokensWithBalance)
    }, [ether, inactiveTokensWithBalance, selectedTab, allTokenListWithExternalTokens]),
  )
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(presetCurrency ?? currencyList[0])
  const specialAsset = useSpecialAsset(selectedCurrency.wrapped)
  const isGeoRestricted = useIsTokenGeoRestricted(selectedCurrency.wrapped.address)
  const rawTokenList = useMemo(
    () =>
      currencyList.reduce<Token[]>((memo, currency) => {
        if (currency.isToken) {
          memo.push(currency.wrapped)
        }
        return memo
      }, []),
    [currencyList],
  )
  const tokenList = useSerializedTokens(rawTokenList)
  const tokenMap = useAllTokens()
  const rawProtocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, tokenList)
  const protocolBalanceInfoMap = useAddExternalTokensToDolomiteData(rawProtocolBalanceInfoMap)

  const walletFiatBalanceMap = useFiatValuesWithExternalAssetsMap(
    rawWalletBalanceMap,
    allTokenListWithExternalTokens,
    ethBalance,
  )
  const [rawDolomiteBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, dolomiteTokens)
  const userDolomiteBalanceMap = useAddExternalTokensToDolomiteData(rawDolomiteBalanceMap)
  const [rawDolomiteFiatBalanceMap, , fiatPrices] = useFiatValuesWithLoadingIndicator(
    rawDolomiteBalanceMap,
    dolomiteTokens,
  )
  const dolomiteFiatBalanceMap = useAddExternalTokensToDolomiteData(rawDolomiteFiatBalanceMap)

  const spenderAddress = useMemo(() => {
    const defaultSpender = DOLOMITE_MARGIN_PROTOCOL_ADDRESSES[chainId]
    if (!account) {
      return defaultSpender
    }
    // Remap the spender based on the selected asset, which could be a special token like dfsGLP
    return remapSpenderForAllowance(selectedCurrency.wrapped, account, defaultSpender)
  }, [account, chainId, selectedCurrency])
  const [allowanceMap] = useTokenAllowances(tokenList, account, spenderAddress)
  const [recentTransactions, setRecentTransactions] = useState<BalanceTransaction[]>([])
  const allowance = useMemo(() => {
    if (selectedCurrency.isToken) {
      return allowanceMap[selectedCurrency.wrapped.address]
    } else {
      return undefined
    }
  }, [selectedCurrency, allowanceMap])
  const tokenContractForApproval = useTokenContract(remapTokenAddressForAllowance(selectedCurrency.wrapped))
  const deadline = useTransactionDeadline()
  const isArgentWallet = useIsArgentWallet()
  const [approvalState, approveCallback] = useApproveCallback(inputValueAmount, spenderAddress)

  const isHashPending = useIsTransactionPending(pendingHash)
  const [ignoreAllowance, setIgnoreAllowance] = useState(false)
  useEffect(() => {
    isWithdraw ? setSelectedTab(WITHDRAW) : setSelectedTab(DEPOSIT)
  }, [isWithdraw])

  useEffect(() => {
    setIgnoreAllowance(
      inputValueAmount?.currency.wrapped.chainId === ChainId.MANTLE &&
        inputValueAmount?.currency.wrapped.address === WMNT_ISOLATION_MODE_ADDRESSES[ChainId.MANTLE],
    )
    setUnlockStage(UnlockStage.UNLOCK)
  }, [inputValueAmount])

  useEffect(() => {
    if (unlockStage === UnlockStage.COMPLETE) {
      setIgnoreAllowance(false)
    }
  }, [unlockStage])

  useEffect(() => {
    if (!isHashPending) {
      if (pendingDepositHash && pendingDepositHash === pendingHash) {
        setPendingDepositHash(undefined)
        setUnlockStage(UnlockStage.COMPLETE)
      }
      setPendingHash(undefined)
      if (unlockStage === UnlockStage.UNLOCK && pendingAllowanceCurrency?.equals(selectedCurrency)) {
        setIgnoreAllowance(true)
        setPendingAllowanceCurrency(undefined)
        setUnlockStage(UnlockStage.DEPOSIT)
      }
    }
    setIsLoading(isHashPending)
  }, [isHashPending, pendingAllowanceCurrency, pendingDepositHash, pendingHash, selectedCurrency, unlockStage])

  useEffect(() => {
    setSelectedCurrency(currencyList[0])
  }, [chainId, currencyList])
  useEffect(() => {
    if (presetCurrency) {
      setSelectedCurrency(presetCurrency)
    }
  }, [presetCurrency])
  const inputFiatValueAmount = useMemo(() => {
    if (!inputValueAmount) {
      return undefined
    }
    const fiatPrice = fiatPrices[inputValueAmount.currency.wrapped.address]
    if (!fiatPrice) {
      return undefined
    }
    return inputValueAmount.asFraction.multiply(fiatPrice)
  }, [inputValueAmount, fiatPrices])

  const isolationModeVaultAddress = useIsolationModeUserVaultAddressIfCreated(selectedCurrency.wrapped)
  const { callback: createVaultCallback } = useCreateVaultIsolationModeVault(selectedCurrency.wrapped)
  const needsIsolationModeVault = useMemo(() => {
    return (
      selectedCurrency.wrapped.chainId === ChainId.MANTLE &&
      selectedCurrency.wrapped.address === WMNT_ISOLATION_MODE_ADDRESSES[ChainId.MANTLE] &&
      !isolationModeVaultAddress
    )
  }, [isolationModeVaultAddress, selectedCurrency])

  const { callback: depositCallback, error: depositError } = useDepositIntoDolomiteMarginProtocol(
    ignoreAllowance ? ApprovalState.APPROVED : approvalState,
    signatureData,
    selectedCurrency,
    inputValueAmount,
  )
  const { callback: withdrawCallback, error: withdrawError } = useWithdrawFromDolomiteMarginProtocol(
    selectedCurrency,
    inputValueAmount,
    isWithdrawAll,
    BalanceCheckFlag.FromAccount,
    isUnwrapSelected,
  )

  useEffect(() => {
    const inputValueAmount = tryParseAmount(inputValue, selectedCurrency)
    setInputValueAmount(inputValueAmount)
  }, [currencyList, selectedCurrency, inputValue])

  const selectToken = (token: Currency) => {
    setInputValueAmount(undefined)
    setInputValue('')
    setSelectedCurrency(token)
    setTokenSelectOpen(false)
    setIgnoreAllowance(false)
  }

  const openUnlockModal = (isUnlockModal: boolean) => {
    setErrorMessage(undefined)
    setUnlockModalOpen(true)
    setIsUnlockModal(isUnlockModal)
    if (unlockStage === UnlockStage.COMPLETE) {
      setUnlockStage(UnlockStage.UNLOCK)
    }
  }

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  const selectedCurrencyKey = useMemo(
    () => (selectedCurrency.isNative ? selectedCurrency.symbol : selectedCurrency.wrapped.address) ?? '',
    [selectedCurrency],
  )

  const updateMax = useCallback(() => {
    if (selectedTab === DEPOSIT) {
      const currency = walletBalanceMap[selectedCurrencyKey ?? '']?.currency
      let balance = walletBalanceMap[selectedCurrencyKey ?? '']?.asFraction
      if (selectedCurrency.isNative) {
        let min: Fraction
        if (chainId === ChainId.X_LAYER) {
          min = CurrencyAmount.fromRawAmount(ether, '100000000000000000').asFraction
        } else if (chainId === ChainId.MANTLE) {
          min = CurrencyAmount.fromRawAmount(ether, '5000000000000000000').asFraction
        } else {
          min = CurrencyAmount.fromRawAmount(ether, '1250000000000000').asFraction
        }
        if (balance?.greaterThan(min)) {
          balance = balance.subtract(min)
        }
      } else {
        const totalSupplyWei = marketTotalWeiMap[selectedCurrency.wrapped.address]?.supplyWei?.asFraction
        const maxSupplyWei = marketRiskInfoMap[selectedCurrency.wrapped.address]?.supplyMaxWei?.asFraction
        if (totalSupplyWei && maxSupplyWei && balance && maxSupplyWei.greaterThan(ZERO_FRACTION)) {
          if (totalSupplyWei.greaterThan(maxSupplyWei)) {
            balance = CurrencyAmount.fromRawAmount(selectedCurrency, 0)
          } else if (totalSupplyWei.add(balance).greaterThan(maxSupplyWei)) {
            balance = maxSupplyWei.subtract(totalSupplyWei)
          }
        }
      }
      setInputValue(balance?.toFixed(currency?.decimals ?? 18) ?? '')
    } else {
      const tokenAddress = selectedCurrency.wrapped.address ?? ''
      const isIsolationMode =
        selectedCurrency instanceof Token ? getSpecialAsset(chainId, selectedCurrency)?.isIsolationMode : false
      const protocolBalance = protocolBalanceInfoMap[tokenAddress]
      const userBalance = userDolomiteBalanceMap[tokenAddress]
      if (protocolBalance && userBalance?.greaterThan(protocolBalance) && !isIsolationMode) {
        const balance = protocolBalance
        setInputValue(balance?.toFixed(balance.currency.decimals ?? 18) ?? '')
      } else {
        const balance = userDolomiteBalanceMap[selectedCurrency.wrapped.address]
        setInputValue(balance?.toFixed(balance.currency.decimals ?? 18) ?? '')
      }
    }
  }, [
    ether,
    selectedTab,
    walletBalanceMap,
    selectedCurrencyKey,
    selectedCurrency,
    chainId,
    marketTotalWeiMap,
    marketRiskInfoMap,
    userDolomiteBalanceMap,
    protocolBalanceInfoMap,
  ])
  const updateSelectedTab = (selected: number) => {
    setSelectedTab(selected)
    setInputValue('')
  }

  useEffect(() => {
    if (selectedCurrency && allowance) {
      const isInsufficientAllowance = inputValueAmount ? allowance.lessThan(inputValueAmount) : true
      const allowanceGreaterThanZero = allowance.greaterThan(ZERO_FRACTION)
      if (!inputValueAmount && allowanceGreaterThanZero) {
        setIsApprovalRequired(false)
      } else {
        setIsApprovalRequired(
          selectedTab === DEPOSIT && !selectedCurrency.isNative && !ignoreAllowance && isInsufficientAllowance,
        )
      }
    } else {
      setIsApprovalRequired(selectedTab === DEPOSIT && !selectedCurrency.isNative)
    }
    if (!inputValueAmount) {
      setHasSufficientBalance(false)
      return
    }

    const tokenAddress = selectedCurrency.wrapped.address ?? ''
    const balance =
      selectedTab === DEPOSIT ? walletBalanceMap[selectedCurrencyKey] : userDolomiteBalanceMap[tokenAddress]
    setHasSufficientBalance(!inputValueAmount.greaterThan(balance ?? '0'))

    // Allow for 10 bips of estimation. Assume the user wants to withdraw all, if that's the case
    const isCloseToBalance = !!balance && isInputValueCloseToBalance(inputValueAmount, balance)
    setIsWithdrawAll(selectedTab === WITHDRAW && isCloseToBalance)
  }, [
    inputValueAmount,
    selectedCurrency,
    walletBalanceMap,
    userDolomiteBalanceMap,
    selectedTab,
    allowance,
    ignoreAllowance,
    selectedCurrencyKey,
  ])

  const tokenBalance = useMemo(() => {
    return selectedTab === DEPOSIT
      ? formatAmount(walletBalanceMap[selectedCurrencyKey], 4, true, '0.00')
      : formatAmount(userDolomiteBalanceMap[selectedCurrency.wrapped.address ?? ''], 4, true, '0.00')
  }, [walletBalanceMap, userDolomiteBalanceMap, selectedCurrency, selectedCurrencyKey, selectedTab])

  useEffect(() => {
    const localStorageContents = window.localStorage.getItem('recent_balance_transactions')
    if (localStorageContents) {
      const loadedLocalStorageData = JSON.parse(localStorageContents)
      if (loadedLocalStorageData.account && loadedLocalStorageData.account === account) {
        const loadedRecentTransactions = loadedLocalStorageData.data
        const filteredTransactions = loadedRecentTransactions.filter(
          (transaction: BalanceTransaction) => transaction.date > Date.now() - 1000 * 60 * 15,
        )
        if (filteredTransactions && filteredTransactions.length > 0) {
          const assembledTransactions = filteredTransactions.map((transaction: any) => {
            return {
              asset: tokenMap[transaction.asset?.address ?? ''] ?? Ether.onChain(chainId),
              amount:
                transaction.type === BalanceTransactionType.UNLOCK ||
                transaction.type === BalanceTransactionType.CREATE_VAULT
                  ? undefined
                  : CurrencyAmount.fromFractionalAmount(
                      transaction.asset,
                      transaction.numerator,
                      transaction.denominator,
                    ),
              type: transaction.type,
              date: transaction.date,
              isLoaded: true,
              hash: transaction.hash,
            }
          })
          setRecentTransactions(assembledTransactions)
        }
      } else {
        setRecentTransactions([])
      }
    }
  }, [account, chainId, tokenMap])

  const submitTokenUnlock = useCallback(() => {
    if (!inputValueAmount?.currency.isToken || !tokenContractForApproval || !deadline || !createVaultCallback) {
      return
    }
    setUnlockModalOpen(true)
    setIsLoading(true)
    setIsAwaitingSignature(true)
    setIsAttemptingTx(true)
    setErrorMessage(undefined)

    const isCreatingVault =
      chainId === ChainId.MANTLE && selectedCurrency.wrapped.address === WMNT_ISOLATION_MODE_ADDRESSES[ChainId.MANTLE]

    const newTransaction = {
      asset: inputValueAmount.currency,
      amount: undefined,
      type: isCreatingVault ? BalanceTransactionType.CREATE_VAULT : BalanceTransactionType.UNLOCK,
      date: Date.now(),
      isLoaded: false,
      chainId: chainId,
    }
    const activeTransaction = recentTransactions.length
    const preparedTransactions = recentTransactions.concat(newTransaction).map(transaction => {
      return {
        ...transaction,
        numerator: transaction.amount?.numerator.toString(),
        denominator: transaction.amount?.denominator.toString(),
      }
    })
    window.localStorage.setItem(
      'recent_balance_transactions',
      JSON.stringify({
        account: account?.toString(),
        data: preparedTransactions,
      }),
    )
    setRecentTransactions(recentTransactions.concat(newTransaction))

    const callbackPromise = isCreatingVault
      ? createVaultCallback()
      : onAttemptToPermit(
          CurrencyAmount.fromFractionalAmount(
            inputValueAmount.currency.wrapped,
            inputValueAmount.numerator,
            inputValueAmount.denominator,
          ),
          spenderAddress,
          web3Context,
          deadline,
          tokenContractForApproval,
          selectedCurrency.wrapped.name ?? '',
          isArgentWallet,
          approveCallback,
          setSignatureData,
        )

    callbackPromise
      .then((hash: any) => {
        if (typeof hash === 'string') {
          setPendingHash(hash)
          setPendingAllowanceCurrency(selectedCurrency)
        }
        setIsAwaitingSignature(false)
        setIsAttemptingTx(false)
      })
      .catch((error: any) => {
        if (!USER_ERROR_CODES[error?.code ?? '']) {
          // transaction rejected
          console.error(`Could not ${isCreatingVault ? 'create vault' : 'approve'} due to error`, error.message, error)
        }
        setErrorMessage(error.message)
        const newTransactions = recentTransactions.slice().splice(activeTransaction, 1)
        const preparedTransactions = recentTransactions
          .slice()
          .splice(activeTransaction, 1)
          .map(transaction => {
            return {
              ...transaction,
              numerator: transaction.amount?.numerator.toString(),
              denominator: transaction.amount?.denominator.toString(),
            }
          })
        window.localStorage.setItem(
          'recent_balance_transactions',
          JSON.stringify({
            account: account?.toString(),
            data: preparedTransactions,
          }),
        )
        setRecentTransactions(newTransactions)
        setIsLoading(false)
        setIsAwaitingSignature(false)
        setIsAttemptingTx(false)
      })
  }, [
    account,
    createVaultCallback,
    approveCallback,
    chainId,
    deadline,
    inputValueAmount,
    isArgentWallet,
    recentTransactions,
    selectedCurrency,
    spenderAddress,
    tokenContractForApproval,
    web3Context,
  ])
  const submitDepositOrWithdraw = useCallback(
    (isDeposit: boolean) => {
      const callback = isDeposit ? depositCallback : withdrawCallback
      const error = isDeposit ? depositError : withdrawError
      if (!callback) {
        console.error('Callback for deposit or withdraw was undefined to error:', error)
        return
      }

      setIsLoading(true)
      setIsAwaitingSignature(true)
      setErrorMessage(undefined)
      setIsAttemptingTx(true)
      const newTransaction = {
        asset: selectedCurrency,
        amount: inputValueAmount,
        type: isDeposit ? BalanceTransactionType.DEPOSIT : BalanceTransactionType.WITHDRAW,
        date: Date.now(),
        isLoaded: false,
        chainId: chainId,
        hash: undefined,
      }
      const activeTransaction = recentTransactions.length
      const preparedTransactions = recentTransactions.concat(newTransaction).map(transaction => {
        return {
          ...transaction,
          numerator: transaction.amount?.numerator.toString(),
          denominator: transaction.amount?.denominator.toString(),
        }
      })
      window.localStorage.setItem(
        'recent_balance_transactions',
        JSON.stringify({
          account: account?.toString(),
          data: preparedTransactions,
        }),
      )
      setRecentTransactions(recentTransactions.concat(newTransaction))

      callback()
        .then(hash => {
          preparedTransactions[preparedTransactions.length - 1].hash = hash
          setRecentTransactions(preparedTransactions)
          window.localStorage.setItem(
            'recent_balance_transactions',
            JSON.stringify({
              account: account?.toString(),
              data: preparedTransactions,
            }),
          )
          setPendingHash(hash)
          setIsAwaitingSignature(false)
          setIsAttemptingTx(false)
          if (isDeposit) {
            setPendingDepositHash(hash)
          }
        })
        .catch((error: Error) => {
          setErrorMessage('Transaction Rejected')
          const newTransactions = recentTransactions.slice().splice(activeTransaction, 1)
          const preparedTransactions = recentTransactions
            .slice()
            .splice(activeTransaction, 1)
            .map(transaction => {
              return {
                ...transaction,
                numerator: transaction.amount?.numerator.toString(),
                denominator: transaction.amount?.denominator.toString(),
              }
            })
          window.localStorage.setItem(
            'recent_balance_transactions',
            JSON.stringify({
              account: account?.toString(),
              data: preparedTransactions,
            }),
          )
          setRecentTransactions(newTransactions)
          setIsLoading(false)
          setIsAwaitingSignature(false)
          setIsAttemptingTx(false)
          if (error.message) {
            console.error('Could not deposit or withdraw due to error ', error.message)
          }
        })
    },
    [
      account,
      chainId,
      depositCallback,
      depositError,
      inputValueAmount,
      recentTransactions,
      selectedCurrency,
      withdrawCallback,
      withdrawError,
    ],
  )
  const showSupplyCapError = useMemo(() => {
    if (selectedTab === DEPOSIT) {
      const tokenAddress = inputValueAmount?.wrapped.currency.address
      const totalSupplyWei = marketTotalWeiMap[tokenAddress ?? '']?.supplyWei.asFraction
      const supplyMaxWei = marketRiskInfoMap[tokenAddress ?? '']?.supplyMaxWei?.asFraction
      if (!supplyMaxWei || !totalSupplyWei || supplyMaxWei.equalTo(ZERO_FRACTION) || !inputValueAmount) {
        return false
      }
      return totalSupplyWei.asFraction.add(inputValueAmount.asFraction).greaterThan(supplyMaxWei)
    }

    return false
  }, [inputValueAmount, marketRiskInfoMap, marketTotalWeiMap, selectedTab])

  const showUniBTCError = useMemo(() => {
    return (
      selectedTab === DEPOSIT &&
      selectedCurrency.wrapped.symbol === 'uniBTC' &&
      inputValueAmount?.asFraction.multiply(10000).greaterThan(1)
    )
  }, [inputValueAmount, selectedCurrency.wrapped.symbol, selectedTab])

  const showInsufficientLiquidityError = useMemo(() => {
    if (selectedTab === WITHDRAW) {
      const protocolBalance = protocolBalanceInfoMap[selectedCurrency.wrapped.address ?? '']?.asFraction
      if (!inputValueAmount || !protocolBalance || specialAsset?.isIsolationMode) {
        return false
      }
      return protocolBalance.lessThan(inputValueAmount.asFraction)
    }

    return false
  }, [inputValueAmount, protocolBalanceInfoMap, selectedCurrency, selectedTab, specialAsset?.isIsolationMode])

  const closeModal = useCallback(() => {
    setTokenSelectOpen(false)
  }, [setTokenSelectOpen])

  const errorText = useMemo(() => {
    return isGeoRestricted && selectedTab === DEPOSIT
      ? `${cleanCurrencySymbol(selectedCurrency, false)} is not available in your region`
      : showSupplyCapError
      ? `Cannot deposit, ${cleanCurrencySymbol(selectedCurrency, false)} has reached its supply cap on Dolomite`
      : showInsufficientLiquidityError
      ? `Cannot withdraw, ${cleanCurrencySymbol(selectedCurrency, false)} has insufficient liquidity on Dolomite`
      : showUniBTCError
      ? `uniBTC deposits are currently limited to a max of 0.0001`
      : undefined
  }, [
    isGeoRestricted,
    selectedCurrency,
    selectedTab,
    showInsufficientLiquidityError,
    showSupplyCapError,
    showUniBTCError,
  ])

  // TODO - let users collapse the recent deposits. When collapsed, show text that says "recent transactions" but hide that when it's expanded.
  const onConfirm = useCallback(() => {
    if (unlockStage === UnlockStage.UNLOCK) {
      submitTokenUnlock()
    } else if (unlockStage === UnlockStage.DEPOSIT) {
      submitDepositOrWithdraw(true)
    } else {
      setUnlockModalOpen(false)
    }
  }, [submitDepositOrWithdraw, submitTokenUnlock, unlockStage])

  const onClose = useCallback(() => {
    setInputValue('')
    close()
  }, [close, setInputValue])
  const onDismiss = useCallback(() => setUnlockModalOpen(false), [])

  return (
    <DepositWithdrawModalInner>
      {tokenSelectOpen ? (
        <CurrencyModal
          tokens={currencyList}
          balances={selectedTab === DEPOSIT ? walletBalanceMap : userDolomiteBalanceMap}
          fiatBalances={selectedTab === DEPOSIT ? walletFiatBalanceMap : dolomiteFiatBalanceMap}
          isOpen={tokenSelectOpen}
          onDismiss={closeModal}
          currencySelect={(currency: Currency) => selectToken(currency)}
          balanceTitle={selectedTab === DEPOSIT ? t('walletBalance') : t('dolomiteBalance')}
          showWETH={true}
          filterOutExtendedTokens={selectedTab === WITHDRAW}
        />
      ) : null}
      <StyledTabs
        value={selectedTab}
        onChange={(_: any, index: number) => updateSelectedTab(index)}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(options ?? []).map((option: string, index: number) => (
          <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
        ))}
      </StyledTabs>
      <CloseWrapper onClick={() => onClose()}>
        <Close />
      </CloseWrapper>
      <div>
        {chainId === ChainId.BERACHAIN &&
          (selectedCurrency.symbol === 'BERA' || selectedCurrency.symbol === 'WBERA') &&
          selectedTab === DEPOSIT && (
            <BeraWarning>
              We would recommend depositing no more than 1 BERA. Due to the unrealistic economics of a testnet, users
              are often borrowing the maximum available amount without concern for interest payments, and as a result at
              times it can be difficult to withdraw BERA. This will not be an issue when Berachain is live.
            </BeraWarning>
          )}
        {chainId === ChainId.BERACHAIN && selectedCurrency.symbol === 'HONEY' && selectedTab === DEPOSIT && (
          <BeraWarning>
            We would recommend depositing no more than a few HONEY. Due to the unrealistic economics of a testnet, users
            are often borrowing the maximum available amount without concern for interest payments, and as a result at
            times it can be difficult to withdraw HONEY. This will not be an issue when Berachain is live.
          </BeraWarning>
        )}
        <TopRow>
          <BalanceWrapper>
            {selectedTab === DEPOSIT ? t('walletBalance') : t('dolomiteBalance')}: {tokenBalance}
          </BalanceWrapper>
          <MaxButton
            onClick={() => !(selectedTab === DEPOSIT && isGeoRestricted) && updateMax()}
            disabled={selectedTab === DEPOSIT && isGeoRestricted}
          >
            Max
          </MaxButton>
        </TopRow>
        <InputWrapper>
          <InputOverflowFix>
            <StyledInput
              onChange={updateInput}
              multiline={false}
              fullWidth
              spellCheck={false}
              placeholder={'0.00'}
              value={inputValue}
              variant='amountInput'
              disableUnderline={true}
              endAdornment={''}
              disabled={selectedTab === DEPOSIT && isGeoRestricted}
            />
          </InputOverflowFix>
          <TokenSelector isLoading={isLoading}>
            <TokenSelectRow onClick={() => setTokenSelectOpen(true)}>
              {cleanCurrencySymbol(selectedCurrency, false)}
            </TokenSelectRow>
            <ArrowDown />
          </TokenSelector>
        </InputWrapper>
        {errorText && <ErrorText>{errorText}</ErrorText>}
        <CheckboxWrapper hidden={selectedTab === DEPOSIT || !isListedTokenForExternalToken(selectedCurrency.wrapped)}>
          <CheckboxText>
            Unwrap {cleanCurrencySymbol(selectedCurrency)} to{' '}
            {cleanCurrencySymbol(mapListedTokenToExternalToken(selectedCurrency.wrapped))}
          </CheckboxText>
          <Checkbox onClick={() => setIsUnwrapSelected(!isUnwrapSelected)}>
            <Checked isChecked={isUnwrapSelected} />
          </Checkbox>
        </CheckboxWrapper>
        <SubmitWrapper>
          <TotalWrapper wide={selectedTab === WITHDRAW ? 1 : needsIsolationModeVault ? 2 : 0}>
            {/*<Large>{`${inputValueAmount?.toFixed(4, FORMATTER) ?? '0'} ${selectedCurrency?.symbol}`}</Large>*/}
            <Large>
              {formatAmount(inputValueAmount, 4, true, '0.00')} {cleanCurrencySymbol(selectedCurrency, false)}
            </Large>
            <div>
              <Muted>{formatAmount(inputFiatValueAmount, 2, true, '$0.00', true)}</Muted>
            </div>
          </TotalWrapper>
          {isLoading ? (
            <StyledTooltip
              title={`${
                isAwaitingSignature
                  ? t('awaitingSignature')
                  : isApprovalRequired && isHashPending
                  ? t('unlocking')
                  : selectedTab === DEPOSIT
                  ? t('depositing')
                  : t('withdrawing')
              }...`}
              placement={'top'}
            >
              <SubmitButton
                wide={selectedTab === WITHDRAW ? 1 : 0}
                unlocked={isApprovalRequired}
                disabled={selectedTab === DEPOSIT && isGeoRestricted}
              >
                <CircularProgress />
              </SubmitButton>
            </StyledTooltip>
          ) : account ? (
            isApprovalRequired ? (
              <Tooltip title={t('approvalRequired')}>
                <SubmitButton
                  onClick={() => openUnlockModal(true)}
                  unlocked={true}
                  disabled={
                    !inputValueAmount?.currency.isToken || !tokenContractForApproval || !deadline || !!errorText
                  }
                >
                  <span>{t('unlock')}</span>
                </SubmitButton>
              </Tooltip>
            ) : needsIsolationModeVault && selectedTab === DEPOSIT ? (
              <StyledTooltip
                title={!hasSufficientBalance ? t('insufficientBalance') : t('vaultRequired')}
                placement={'top'}
              >
                <SubmitButton
                  onClick={() => openUnlockModal(false)}
                  wide={2}
                  unlocked={true}
                  disabled={
                    !inputValueAmount || inputValueAmount.equalTo(ZERO_FRACTION) || !hasSufficientBalance || !!errorText
                  }
                >
                  <span>Create Vault</span>
                </SubmitButton>
              </StyledTooltip>
            ) : !hasSufficientBalance ? (
              <StyledTooltip title={t('insufficientBalance')} placement={'top'}>
                <SubmitButton wide={selectedTab === WITHDRAW ? 1 : 0} disabled={true}>
                  <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
                </SubmitButton>
              </StyledTooltip>
            ) : inputValueAmount?.greaterThan(ZERO_FRACTION) ? (
              <SubmitButton
                wide={selectedTab === WITHDRAW ? 1 : 0}
                disabled={!inputValueAmount || !!errorText || showUniBTCError}
                onClick={() => submitDepositOrWithdraw(selectedTab === DEPOSIT)}
              >
                <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
              </SubmitButton>
            ) : (
              <SubmitButton wide={selectedTab === WITHDRAW ? 1 : 0} disabled={true}>
                <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
              </SubmitButton>
            )
          ) : (
            <SubmitButton wide={selectedTab === WITHDRAW ? 1 : 0} disabled={true}>
              <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
            </SubmitButton>
          )}
        </SubmitWrapper>
        {recentTransactions.length > 0 && (
          <RecentTransactionsWrapper>
            <RecentTransactionsTitle>Recent actions</RecentTransactionsTitle>
            {recentTransactions
              .slice()
              .reverse()
              .map((transaction, index) => (
                <RecentTransaction
                  key={index}
                  transaction={transaction}
                  isLoading={
                    (isLoading && index === 0) || (transaction.isLoaded && transaction.date > Date.now() - 1000 * 5)
                  }
                  chainId={chainId}
                  hash={transaction.hash}
                />
              ))}
          </RecentTransactionsWrapper>
        )}
      </div>
      <ConfirmUnlockModal
        amount={inputValueAmount}
        fiatAmount={inputFiatValueAmount}
        isOpen={unlockModalOpen}
        onConfirm={onConfirm}
        unlockStage={unlockStage}
        loading={isLoading}
        onDismiss={onDismiss}
        isUnlockModal={isUnlockModal}
        attemptingTxn={isAttemptingTx}
        txHash={pendingHash}
        errorMessage={errorMessage}
      />
    </DepositWithdrawModalInner>
  )
},
DepositWithdrawModalComparator)

function DepositWithdrawModalWrapper({
  open,
  close,
  isUnwrapInitiallySelected,
  isWithdraw,
  presetCurrency,
}: DepositWithdrawModalProps) {
  return (
    <Modal isOpen={open} onDismiss={close} maxHeight={700} maxWidthPx={400}>
      {!open ? (
        <></>
      ) : (
        <DepositWithdrawModal
          open={open}
          close={close}
          isUnwrapInitiallySelected={isUnwrapInitiallySelected}
          isWithdraw={isWithdraw}
          presetCurrency={presetCurrency}
        />
      )}
    </Modal>
  )
}

export default DepositWithdrawModalWrapper
