import {
  TokenAddressMap,
  useCombinedAllActiveLists,
  useCombinedAllActiveListsAllChains,
  useTradingTokenList,
} from '../state/lists/hooks'
import { Currency, Ether, Token } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import { isAddress } from '../utils'

import { useActiveWeb3React } from './index'
import { FS_GLP } from '../constants/tokens/FS_GLP'
import { ChainId, OARB, WBTC, WETH, WMNT } from '../constants'
import { SBF_GMX } from '../constants/tokens/SBF_GMX'
import { ARB } from 'constants/tokens/ARB'
import { MIN } from '../constants/tokens/MIN'
import { BRIDGED_USDC } from '../constants/tokens/USDC'
import { USDM } from '../constants/tokens/USDM'
import { CHAIN_ID_MAP, ChainIdMap, initializeObjectChainIdMap } from '../constants/chainId'
import { useStoredAllTokenData } from '../state/data/hooks'

function useTokensFromMapAllChains(tokenMapByChain: TokenAddressMap): ChainIdMap<Record<string, Token>> {
  const { chainId: currentChainId } = useActiveWeb3React()
  const chainIds = useMemo(() => Object.keys(CHAIN_ID_MAP).filter(c => c !== ChainId.MAINNET.toString()), [])

  return useMemo(() => {
    if (!currentChainId) return initializeObjectChainIdMap() as ChainIdMap<Record<string, Token>>

    // reduce to just tokens
    return chainIds.reduce((memo, chainId, i) => {
      memo[parseInt(chainId) as ChainId] = Object.keys(tokenMapByChain[parseInt(chainId) as ChainId]).reduce<
        Record<string, Token>
      >((newMap, address) => {
        newMap[address] = tokenMapByChain[parseInt(chainId) as ChainId][address].token
        return newMap
      }, {})
      return memo
    }, initializeObjectChainIdMap() as ChainIdMap<Record<string, Token>>)
  }, [chainIds, currentChainId, tokenMapByChain])
}

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(tokenMap: TokenAddressMap): Record<string, Token> {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!chainId) return {}

    // reduce to just tokens
    return Object.keys(tokenMap[chainId]).reduce<Record<string, Token>>((newMap, address) => {
      newMap[address] = tokenMap[chainId][address].token
      return newMap
    }, {})
  }, [chainId, tokenMap])
}

export function useTradingTokens(): Record<string, Token> {
  const tradingTokens = useTradingTokenList()
  return useTokensFromMap(tradingTokens)
}

export function serializeTokens(tokens: Token[]) {
  return JSON.stringify(
    tokens.map(token => {
      return {
        address: token.address,
        chainId: token.chainId,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
        isActive: token.isActive,
      }
    }),
  )
}

export function serializeTokensOpt(tokens: Token[] | undefined) {
  if (!tokens) {
    return undefined
  }

  return JSON.stringify(
    tokens.map(token => {
      return {
        address: token.address,
        chainId: token.chainId,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
        isActive: token.isActive,
      }
    }),
  )
}

export function serializeCurrencies(currencies: Currency[]) {
  return JSON.stringify(
    currencies.map(token => {
      return {
        isToken: token instanceof Token,
        address: token instanceof Token ? token.address : undefined,
        chainId: token.chainId,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
      }
    }),
  )
}

export function serializeCurrenciesOpt(currencies: Currency[] | undefined) {
  if (!currencies) {
    return undefined
  }

  return serializeCurrencies(currencies)
}

export function deserializeTokens(json: string): Token[] {
  return JSON.parse(json).map((token: any) => {
    return jsonToToken(token)
  })
}

export function deserializeTokensOpt(json: string | undefined): Token[] | undefined {
  if (!json) {
    return undefined
  }

  return JSON.parse(json).map((token: any) => {
    return jsonToToken(token)
  })
}

export function deserializeCurrencies(json: string): Currency[] {
  return JSON.parse(json).map((currency: any) => {
    if (currency.isToken) {
      return jsonToToken(currency)
    } else {
      return Ether.onChain(currency.chainId)
    }
  })
}

export function deserializeCurrenciesOpt(json: string | undefined): Currency[] | undefined {
  if (!json) {
    return undefined
  }

  return deserializeCurrencies(json)
}

export function serializeToken(token: Token): string {
  return JSON.stringify(tokenToJson(token))
}

export function deserializeToken(json: string): Token {
  const value = JSON.parse(json)
  return jsonToToken(value)
}

export function useSerializedToken(token: Token): Token {
  const serializedToken = useMemo(() => serializeToken(token), [token])
  return useMemo(() => deserializeToken(serializedToken), [serializedToken])
}

export function useSerializedTokenOpt(token: Token | undefined): Token | undefined {
  const serializedToken = useMemo(() => serializeTokenOpt(token), [token])
  return useMemo(() => deserializeTokenOpt(serializedToken), [serializedToken])
}

export function useSerializedTokens(tokens: Token[]): Token[] {
  const serializedTokens = useMemo(() => serializeTokens(tokens), [tokens])
  return useMemo(() => deserializeTokens(serializedTokens), [serializedTokens])
}

export function useSerializedTokensOpt(tokens: Token[] | undefined): Token[] | undefined {
  const serializedTokens = useMemo(() => serializeTokensOpt(tokens), [tokens])
  return useMemo(() => deserializeTokensOpt(serializedTokens), [serializedTokens])
}

export function useSerializedCurrencies(currencies: Currency[]): Currency[] {
  const serializedCurrencies = useMemo(() => serializeCurrencies(currencies), [currencies])
  return useMemo(() => deserializeCurrencies(serializedCurrencies), [serializedCurrencies])
}

export function useSerializedCurrenciesOpt(currencies: Currency[] | undefined): Currency[] | undefined {
  const serializedCurrencies = useMemo(() => serializeCurrenciesOpt(currencies), [currencies])
  return useMemo(() => deserializeCurrenciesOpt(serializedCurrencies), [serializedCurrencies])
}

export function serializeTokenOpt(token?: Token): string | undefined {
  if (!token) {
    return undefined
  }

  return JSON.stringify({
    address: token.address,
    chainId: token.chainId,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
    isActive: token.isActive,
  })
}

export function deserializeTokenOpt(json?: string): Token | undefined {
  if (!json) {
    return undefined
  }

  const value = JSON.parse(json)
  return jsonToToken(value)
}

function serializeTokenMap(tokenMap: Record<string, Token | undefined>): string {
  return JSON.stringify(Object.values(tokenMap).map(token => tokenToJson(token)))
}

function jsonToToken(json: any): Token {
  return new Token(json.chainId, json.address, json.decimals, json.symbol, json.name, json.isActive)
}

function tokenToJson(token: Token | undefined): Record<string, unknown> {
  return {
    chainId: token?.chainId,
    address: token?.address,
    decimals: token?.decimals,
    symbol: token?.symbol,
    name: token?.name,
    isActive: token?.isActive,
  }
}

function deserializeTokenMap(json: string): Record<string, Token | undefined> {
  const tokens = JSON.parse(json) as any[]
  return tokens.reduce<Record<string, Token | undefined>>((memo, token) => {
    memo[token.address] = jsonToToken(token)
    return memo
  }, {})
}

export function useLoadAllTokensAllChainsData(): ChainIdMap<Record<string, Token | undefined>> {
  const allTokens = useCombinedAllActiveListsAllChains()
  return useTokensFromMapAllChains(allTokens)
}

export function useAllTokensAllChains(): ChainIdMap<Record<string, Token | undefined>> {
  return useStoredAllTokenData()
}

export function useAllTokens(selectedChain?: ChainId): Record<string, Token | undefined> {
  const { chainId } = useActiveWeb3React()
  return useStoredAllTokenData()[selectedChain ?? chainId]
}

export function useAllActiveTokensArray(): Token[] {
  const tokenMap = useAllTokens()
  return useMemo(() => {
    return Object.values(tokenMap).filter(token => !!token && token.isActive) as Token[]
  }, [tokenMap])
}

export function useAllActiveTokensArrayWithExternalTokens(): Token[] {
  const { chainId } = useActiveWeb3React()
  const usdm = useMemo(() => USDM[chainId], [chainId])
  const rawTokenList = useAllActiveTokensArray()
  return useMemo(() => {
    if (usdm) {
      return [...rawTokenList, usdm]
    }

    return rawTokenList
  }, [usdm, rawTokenList])
}

/**
 * Same as useAllActiveTokensArray, but returns `undefined` if the full length list has not loaded in yet
 */
export function useAllActiveTokensArrayStrict(): Token[] | undefined {
  const tokenMap = useAllTokens()
  return useMemo(() => {
    const values = Object.values(tokenMap).filter(token => !!token && token.isActive) as Token[]
    // If the tokens list is greater than 2, then the full list has loaded in
    return values.length > 2 ? values : undefined
  }, [tokenMap])
}

export function useTradingTokensArray(): Token[] {
  const tokenMap = useTradingTokens()
  return useMemo(() => Object.values(tokenMap), [tokenMap])
}

export function useEther(): Currency {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => Ether.onChain(chainId), [chainId])
}

export function useToken(tokenAddress?: string, chainId?: ChainId): Token | undefined {
  const tokenMap = useAllTokens(chainId)
  return useMemo(() => {
    const checksummedAddress = isAddress(tokenAddress)
    if (checksummedAddress) {
      return tokenMap[checksummedAddress]
    }
    return undefined
  }, [tokenMap, tokenAddress])
}

export function useCurrency(currencyId: string | undefined): Currency | undefined {
  const isETH = currencyId?.toUpperCase() === 'ETH'
  const token = useToken(isETH ? undefined : currencyId)
  const { chainId } = useActiveWeb3React()
  return useMemo(() => (isETH ? Ether.onChain(chainId) : token), [isETH, chainId, token])
}

export function useFsGlpToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => FS_GLP[chainId], [chainId])
}

export function useArbToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => ARB[chainId], [chainId])
}

export function useBridgedUsedToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => BRIDGED_USDC[chainId], [chainId])
}

export function useMineralToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => MIN[chainId], [chainId])
}

export function useOToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => OARB[chainId], [chainId])
}

export function useSbfGmxToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => SBF_GMX[chainId], [chainId])
}

export function useWbtcToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => WBTC[chainId], [chainId])
}

export function useWethToken(): Token {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => WETH[chainId], [chainId])
}

export function useWmntToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => WMNT[chainId], [chainId])
}
