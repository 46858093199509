import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../index'
import { BigNumber } from 'ethers'
import { ChainId } from '../../constants'
import { useActiveWeb3React } from '../../hooks'

/**
 * Measured in seconds
 */
export enum RefreshFrequency {
  /**
   * Use a large number so it can be indexed
   */
  Never = 999999999,
  /**
   * 5 minutes
   */
  Slowest = 300,
  /**
   * 1 minute
   */
  Slow = 60,
  /**
   * 15 seconds
   */
  Medium = 15,
  /**
   * 5 seconds
   */
  Fast = 5,
}

export function useChainId(): ChainId {
  return useSelector((state: AppState) => state.chain.chainId)
}

export function useIsSettingChainId(): boolean {
  return useSelector((state: AppState) => state.chain.isSettingChainId)
}

export function useBlockNumberForSubgraph(): number {
  const { chainId } = useActiveWeb3React()
  return useSelector<AppState, number>(state => state.chain.subgraphBlockNumberMap[chainId])
}

export function useBlockNumber(chainId: ChainId): number {
  return useSelector((state: AppState) => state.chain.blockNumberMap[chainId])
}

export function useBlockTimestamp(urlChain?: ChainId): BigNumber {
  const { chainId } = useActiveWeb3React()
  const chain: ChainId = urlChain ?? chainId

  const blockTimestamp = useSelector((state: AppState) => state.chain.blockTimestampMap[chain])

  return useMemo(() => {
    return blockTimestamp ? BigNumber.from(blockTimestamp) : BigNumber.from('0')
  }, [blockTimestamp])
}
