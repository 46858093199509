import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const FirstVisitWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const FirstVisitInner = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: ${({ theme }) => theme.bg2};
  margin: calc(50vh - 300px) auto 0;
  overflow: hidden;
  padding: 20px 30px;

  @media screen and (max-height: 700px) {
    margin: calc(50vh - 280px) auto 0;
  }

  @media screen and (max-width: 850px) {
    margin-top: 8vh;
  }

  @media screen and (max-width: 420px) {
    margin-top: 5vh;
  }
`

const FirstVisitInnerMobile = styled(FirstVisitInner)`
  width: 80%;
  max-width: 400px;
  min-width: 280px;
  padding: 15px 20px;

  > div > button {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 49px;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 28px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1} !important;
    color: ${({ theme }) => theme.text1} !important;
    margin: 0 auto 10px !important;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
    max-height: 100%;
    overflow-y: auto;
  }
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
`

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 15px;
  line-height: 21px;

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }

  @media screen and (max-height: 700px) {
    font-size: 13px;
  }

  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 18px;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`

const StartButtonsWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};

  button {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 34px;
    font-size: 15px;
    line-height: 22px;
  }

  button:nth-of-type(1) {
    color: ${({ theme }) => theme.text3};
    background: none;
    margin-right: 8px;
  }

  button:nth-of-type(2) {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
  }

  @media screen and (max-width: 420px) {
    margin-bottom: 0;
    margin-top: 15px;
  }
`

const TOS = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: block;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const TOSText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 700;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const Cookies = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  margin-top: 10px;

  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 18px;
  }
`

export default function FirstVisitPopover({
  width,
  approvedToS,
  setHasVisited,
  setApprovedToS,
}: {
  width: number
  height: number
  approvedToS: boolean
  setHasVisited: () => void
  setApprovedToS: () => void
}) {
  // get all popups
  const [currentStep, setCurrentStep] = useState(0)
  const [hasApprovedToS, setHasApprovedToS] = useState(approvedToS)

  useEffect(() => {
    if (currentStep === 12) setHasVisited()
    if (currentStep === 12) setApprovedToS()
  }, [currentStep, setHasVisited, setApprovedToS])

  if (width <= 850 && currentStep < 12) {
    return (
      <FirstVisitWrapper>
        <FirstVisitInnerMobile>
          <Title>Welcome to Dolomite!</Title>
          <Subtitle>
            This is the balances page, where you can deposit assets to and withdraw assets from Dolomite, as well as see
            assets you currently have deposited to Dolomite.
          </Subtitle>
          <Subtitle>
            {`Dolomite runs on the `}
            <a href={'https://arbitrum.io/'} target={'_blank'} rel={'noreferrer'}>
              Arbitrum Layer 2
            </a>
            {` so to deposit to Dolomite you first have to
          bridge your assets over to Arbitrum from mainnet Ethereum. This is a quick and straightforward process, you can click the "Bridge
          to Arbitrum" button on the balance page, or for a complete guide on bridging and depositing to Dolomite, go `}
            <a href={'https://arbitrum.io/bridge-tutorial/'} target={'_blank'} rel={'noreferrer'}>
              here.
            </a>
          </Subtitle>
          <TOS hidden={hasApprovedToS}>
            <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
              <Checked isChecked={hasApprovedToS} />
            </Checkbox>
            <TOSText>
              I agree to the Dolomite{' '}
              <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
                Terms of Service
              </a>
            </TOSText>
          </TOS>
          <Cookies>
            By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to
            run properly.
          </Cookies>
          <StartButtonsWrapper disabled={!hasApprovedToS} style={{ textAlign: 'center' }}>
            <button onClick={() => hasApprovedToS && setCurrentStep(12)}>Jump in</button>
          </StartButtonsWrapper>
        </FirstVisitInnerMobile>
      </FirstVisitWrapper>
    )
  }

  return (
    <FirstVisitWrapper>
      <FirstVisitInner>
        <Title>Welcome to Dolomite!</Title>
        <Subtitle>
          This is the balances page, where you can deposit assets to, and withdraw assets from Dolomite, as well as see
          assets you currently have deposited to Dolomite.
        </Subtitle>
        <Subtitle>
          {`Dolomite runs on the `}
          <a href={'https://arbitrum.io/'} target={'_blank'} rel={'noreferrer'}>
            Arbitrum Layer 2
          </a>
          {` so to deposit to Dolomite you first have to
          bridge your assets over to Arbitrum from mainnet Ethereum. This is a quick and straightforward process, you can click the "Bridge
          to Arbitrum" button on the balance page, or for a complete guide on bridging and depositing to Dolomite, go `}
          <a href={'https://arbitrum.io/bridge-tutorial/'} target={'_blank'} rel={'noreferrer'}>
            here.
          </a>
        </Subtitle>
        <TOS hidden={approvedToS}>
          <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
            <Checked isChecked={hasApprovedToS} />
          </Checkbox>
          <TOSText>
            I agree to the Dolomite{' '}
            <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
              Terms of Service
            </a>
          </TOSText>
        </TOS>
        <Cookies>
          By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to run
          properly.
        </Cookies>
        <StartButtonsWrapper disabled={!hasApprovedToS}>
          <button style={{ display: 'none' }} onClick={() => hasApprovedToS && setCurrentStep(12)}></button>
          <button onClick={() => hasApprovedToS && setCurrentStep(12)}>Start</button>
        </StartButtonsWrapper>
      </FirstVisitInner>
    </FirstVisitWrapper>
  )
}
