import { Fraction } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import { createFraction, createFractionUSD, DolomiteMargin as DolomiteMarginGql } from './gqlTypeHelpers'
import { gql } from '@apollo/client'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'

import { RefreshFrequency } from '../state/chain/hooks'
import { ChainId } from '../constants'

const DOLOMITE_MARGIN_DATA = gql`
  query dolomiteMargin($blockNumber: Int!) {
    dolomiteMargins(first: 1, block: { number_gte: $blockNumber }) {
      id
      liquidationRatio
      liquidationReward
      earningsRate
      minBorrowedValue
      supplyLiquidityUSD
      borrowLiquidityUSD
      totalBorrowVolumeUSD
      totalLiquidationVolumeUSD
      totalSupplyVolumeUSD
      totalTradeVolumeUSD
      totalVaporizationVolumeUSD
      actionCount
      liquidationCount
      tradeCount
      transactionCount
      vaporizationCount
    }
  }
`

interface DolomiteMarginResponse {
  dolomiteMargins: DolomiteMarginGql[]
}

export interface DolomiteMarginData {
  minCollateralization: Fraction
  liquidationReward: Fraction
  earningsRate: Fraction
  minBorrowedValue: Fraction
  supplyLiquidityUSD: Fraction
  borrowLiquidityUSD: Fraction
  totalBorrowVolumeUSD: Fraction
  totalLiquidationVolumeUSD: Fraction
  totalSupplyVolumeUSD: Fraction
  totalTradeVolumeUSD: Fraction
  totalVaporizationVolumeUSD: Fraction
  actionCount: Fraction
  liquidationCount: Fraction
  tradeCount: Fraction
  transactionCount: Fraction
  vaporizationCount: Fraction
}

export function useDolomiteMarginData(
  urlChain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: DolomiteMarginData | undefined
} {
  const queryState = useGraphqlResult<DolomiteMarginResponse>(
    GraphqlClientType.Dolomite,
    DOLOMITE_MARGIN_DATA.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slow,
    urlChain,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const dolomiteMargins = (result?.dolomiteMargins ?? []).map<DolomiteMarginData>(gql => {
      return {
        minCollateralization: createFraction(gql.liquidationRatio),
        liquidationReward: createFraction(gql.liquidationReward),
        earningsRate: createFraction(gql.earningsRate),
        minBorrowedValue: createFraction(gql.minBorrowedValue),
        supplyLiquidityUSD: createFractionUSD(gql.supplyLiquidityUSD),
        borrowLiquidityUSD: createFractionUSD(gql.borrowLiquidityUSD),
        totalBorrowVolumeUSD: createFractionUSD(gql.totalBorrowVolumeUSD),
        totalLiquidationVolumeUSD: createFractionUSD(gql.totalLiquidationVolumeUSD),
        totalSupplyVolumeUSD: createFractionUSD(gql.totalSupplyVolumeUSD),
        totalTradeVolumeUSD: createFractionUSD(gql.totalTradeVolumeUSD),
        totalVaporizationVolumeUSD: createFractionUSD(gql.totalVaporizationVolumeUSD),
        actionCount: createFraction(gql.actionCount),
        liquidationCount: createFraction(gql.liquidationCount),
        tradeCount: createFraction(gql.tradeCount),
        transactionCount: createFraction(gql.transactionCount),
        vaporizationCount: createFraction(gql.vaporizationCount),
      }
    })

    return {
      loading: anyLoading,
      error: anyError,
      data: dolomiteMargins[0],
    }
  }, [queryState])
}
