import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { Transaction } from './transaction'
import {
  createCurrencyAmount,
  createFractionUSD,
  createTransaction,
  EntityType,
  Liquidation as LiquidationGql,
} from './gqlTypeHelpers'
import JSBI from 'jsbi'
import { Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { useAllTokens } from '../hooks/Tokens'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { liquidationGql } from './queryObjects'
import { createMarginAccount, MarginAccount } from './marginAccount'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { ChainId } from '../constants'

const MAX_PAGE_SIZE = 100

const LIQUIDATIONS_BY_MARGIN_ACCOUNT = gql`
    query liquidationsByWallet($blockNumber: Int!, $marginAccount: String!) {
        liquidations(
            block: { number_gte: $blockNumber },
            where: { or: [{ solidMarginAccount: $marginAccount }, { liquidMarginAccount: $marginAccount }] },
            orderBy: serialId,
            orderDirection: desc,
            first: ${MAX_PAGE_SIZE},
        ) {
            ${liquidationGql()}
        }
    }
`

const LIQUIDATIONS_BY_TOKEN = gql`
    query liquidationsByToken($blockNumber: Int!, $tokenAddress: String!) {
        liquidations(
            block: { number_gte: $blockNumber },
            where: { or: [{ heldToken: $tokenAddress }, { borrowedToken: $tokenAddress }] },
            orderBy: serialId,
            orderDirection: desc,
            first: ${MAX_PAGE_SIZE},
        ) {
            ${liquidationGql()}
        }
    }
`

const LIQUIDATIONS_BY_TIME = gql`
    query allLiquidations($blockNumber: Int!) {
        liquidations(
            block: { number_gte: $blockNumber },
            orderBy: serialId,
            orderDirection: desc,
            first: ${MAX_PAGE_SIZE},
        ) {
            ${liquidationGql()}
        }
    }
`

export interface Liquidation {
  id: string
  type: EntityType
  serialId: JSBI
  transaction: Transaction
  logIndex: JSBI
  solidAccount: MarginAccount
  liquidAccount: MarginAccount
  heldToken: Token
  borrowedToken: Token
  borrowedTokenAmountDeltaWei: CurrencyAmount<Token>
  heldTokenAmountDeltaWei: CurrencyAmount<Token>
  heldTokenLiquidationRewardWei: string
  borrowedTokenAmountUSD: Fraction
  heldTokenAmountUSD: Fraction
}

interface LiquidationResponse {
  liquidations: LiquidationGql[]
}

export function useLiquidationDataByMarginAccount(
  marginAccount?: MarginAccount,
): {
  loading: boolean
  error: boolean
  data: Liquidation[]
} {
  return useLiquidations(LIQUIDATIONS_BY_MARGIN_ACCOUNT.loc!.source.body, marginAccount)
}

export function useLiquidationDataByToken(
  tokenAddress?: string,
  urlChain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Liquidation[]
} {
  return useLiquidations(LIQUIDATIONS_BY_TOKEN.loc!.source.body, undefined, tokenAddress, urlChain)
}

export function useLiquidationDataByTime(): {
  loading: boolean
  error: boolean
  data: Liquidation[]
} {
  return useLiquidations(LIQUIDATIONS_BY_TIME.loc!.source.body, 'filler', undefined)
}

function useLiquidations(
  query: string,
  marginAccount: MarginAccount | 'filler' | undefined,
  address?: string,
  urlChain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Liquidation[]
} {
  const tokenMap = useAllTokens()

  const variables = useMemo(() => {
    if (!marginAccount) {
      if (address) {
        return { tokenAddress: address.toLowerCase() }
      }
      return undefined
    }
    return {
      marginAccount: marginAccount.toString().toLowerCase(),
    }
  }, [marginAccount, address])
  const queryState = useGraphqlResult<LiquidationResponse>(
    GraphqlClientType.Dolomite,
    query,
    variables,
    marginAccount ? RefreshFrequency.Fast : RefreshFrequency.Slow,
    urlChain,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const liquidations = (result?.liquidations ?? [])
      .map<Liquidation | undefined>(liquidation => {
        const heldToken: Token | undefined = tokenMap[toChecksumAddress(liquidation.heldToken.id)]
        const borrowedToken: Token | undefined = tokenMap[toChecksumAddress(liquidation.borrowedToken.id)]
        if (!heldToken || !borrowedToken) {
          return undefined
        }
        return {
          id: liquidation.id,
          type: EntityType.Liquidation,
          serialId: JSBI.BigInt(liquidation.serialId),
          transaction: createTransaction(liquidation.transaction),
          logIndex: JSBI.BigInt(liquidation.logIndex),
          solidAccount: createMarginAccount(liquidation.solidMarginAccount),
          liquidAccount: createMarginAccount(liquidation.liquidMarginAccount),
          heldToken: heldToken,
          borrowedToken: borrowedToken,
          borrowedTokenAmountDeltaWei: createCurrencyAmount(borrowedToken, liquidation.borrowedTokenAmountDeltaWei),
          heldTokenAmountDeltaWei: createCurrencyAmount(heldToken, liquidation.heldTokenAmountDeltaWei),
          heldTokenLiquidationRewardWei: liquidation.heldTokenLiquidationRewardWei,
          borrowedTokenAmountUSD: createFractionUSD(liquidation.borrowedTokenAmountUSD),
          heldTokenAmountUSD: createFractionUSD(liquidation.heldTokenAmountUSD),
        }
      })
      .filter(liquidation => !!liquidation)
      .map<Liquidation>(liquidation => liquidation!)

    return {
      loading: anyLoading,
      error: anyError,
      data: liquidations,
    }
  }, [queryState, tokenMap])
}
