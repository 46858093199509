import { createReducer } from '@reduxjs/toolkit'
import {
  updateAllTokenData,
  updateDolomiteBalancesWithLoadingIndicator,
  updateFiatPricesWithLoadingIndicator,
  updateInterestRateData,
  updateMarketRiskData,
  /*updateBlockTimestamp,
  updateChainId,
  updateIsSettingChainId,
  updateSubgraphBlockNumber,*/
} from './actions'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { InterestRate } from '../../types/interestRateData'
import { TokenBalancesWithLoadingIndicator } from '../wallet/hooks'
import { ChainId, ChainIdMap, initializeObjectChainIdMap } from '../../constants/chainId'
import { Token } from '@dolomite-exchange/v2-sdk'

export type MarketRiskDataResponse = ChainIdMap<{
  data: Record<string, MarketRiskInfo | undefined>
  loading: boolean
  error: boolean
}>

export interface InterestRateDataResponse {
  loading: boolean
  error: boolean
  data: Record<string, InterestRate | undefined>
}

export interface DataState {
  readonly fiatPricesWithLoadingIndicator: ChainIdMap<
    | {
        fiatPriceMap: Record<string, Fraction | undefined>
        isLoading: boolean
      }
    | undefined
  >
  readonly marketRiskData: ChainIdMap<{
    data: Record<string, MarketRiskInfo | undefined>
    loading: boolean
    error: boolean
  }>
  readonly interestRateData: ChainIdMap<InterestRateDataResponse | undefined>
  readonly dolomiteBalancesWithLoadingIndicator: TokenBalancesWithLoadingIndicator
  readonly allTokenData: ChainIdMap<Record<string, Token | undefined>>
  /*readonly blockTimestampMap: Readonly<ChainIdMap<number>>
  readonly subgraphBlockNumberMap: Readonly<ChainIdMap<number>>
  readonly chainId: ChainId
  readonly isSettingChainId: boolean*/
}

const initialState: DataState = {
  fiatPricesWithLoadingIndicator: {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: {
      fiatPriceMap: {},
      isLoading: true,
    },
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: {
      fiatPriceMap: {},
      isLoading: true,
    },
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
  },
  marketRiskData: {
    [ChainId.MAINNET]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.ARBITRUM_ONE]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.BASE]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.BERACHAIN]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.MANTLE]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.POLYGON_ZKEVM]: {
      data: {},
      loading: true,
      error: false,
    },
    [ChainId.X_LAYER]: {
      data: {},
      loading: true,
      error: false,
    },
  },
  interestRateData: {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: {
      loading: true,
      error: false,
      data: {},
    },
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: {
      loading: true,
      error: false,
      data: {},
    },
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
  },
  dolomiteBalancesWithLoadingIndicator: [{}, true],
  allTokenData: initializeObjectChainIdMap() as ChainIdMap<Record<string, Token | undefined>>, // TODO - switch all to this format if it works, that way it's future proofed for new chains
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateFiatPricesWithLoadingIndicator, (state, { payload: { chainId, mapData } }) => {
      state.fiatPricesWithLoadingIndicator[chainId] = mapData
    })
    .addCase(updateMarketRiskData, (state, { payload }) => {
      state.marketRiskData = payload
    })
    .addCase(updateInterestRateData, (state, { payload: { chainId, interestData } }) => {
      state.interestRateData[chainId] = interestData
    })
    .addCase(
      updateDolomiteBalancesWithLoadingIndicator,
      (state, { payload: dolomiteBalancesWithLoadingIndicatorData }) => {
        state.dolomiteBalancesWithLoadingIndicator = dolomiteBalancesWithLoadingIndicatorData
      },
    )
    .addCase(updateAllTokenData, (state, { payload: tokenData }) => {
      state.allTokenData = tokenData
    }),
)
