import { DocumentNode, gql } from '@apollo/client'
import { Fraction, Token } from '@dolomite-exchange/v2-sdk'
import {
  createCurrencyAmount,
  createFractionUSD,
  createTransaction,
  EntityType,
  Trade as TradeGql,
} from './gqlTypeHelpers'
import { tradeGql } from './queryObjects'
import { useMemo } from 'react'
import { Transaction } from './transaction'
import JSBI from 'jsbi'
import { createMarginAccount, createMarginAccountOpt, MarginAccount } from './marginAccount'
import { useAllTokens } from '../hooks/Tokens'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { Market } from './Market'
import { deriveMarketFromTokensReq } from '../utils/marketUtils'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { ChainId, EXPIRY_TRADER_ADDRESSES } from '../constants'
import { useActiveWeb3React } from '../hooks'
import { RefreshFrequency } from '../state/chain/hooks'
import { Transfer } from './transferData'

const PAGE_SIZE = 100
const HALF_PAGE_SIZE = 50

const TRADES_BY_WALLET_GQL = gql`
    query tradesByWallet($blockNumber: Int!, $walletAddress: String!) {
        trades(
            block: { number_gte: $blockNumber }
            where: { effectiveWalletsConcatenated_contains: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
    }
`

const TRADES_BY_MARGIN_ACCOUNT_GQL = gql`
    query tradesByMarginAccount($blockNumber: Int!, $marginAccount: String!) {
        makerTrades:trades(
            block: { number_gte: $blockNumber }
            where: { makerMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: ${HALF_PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
        takerTrades:trades(
            block: { number_gte: $blockNumber }
            where: { takerMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: ${HALF_PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
    }
`

const TRADES_BY_TOKEN_GQL = gql`
    query tradesByToken($blockNumber: Int!, $token: String!) {
        makerTokenTrades:trades(
            block: { number_gte: $blockNumber }
            where: { makerToken: $token },
            orderBy: serialId,
            orderDirection: desc,
            first: ${HALF_PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
        takerTokenTrades:trades(
            block: { number_gte: $blockNumber }
            where: { takerToken: $token },
            orderBy: serialId,
            orderDirection: desc,
            first: ${HALF_PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
    }
`

const TRADES_BY_TIME_GQL = gql`
    query allTrades($blockNumber: Int!) {
        trades(
            block: { number_gte: $blockNumber }
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${tradeGql()}
        }
    }
`

interface TradeResponse {
  trades: TradeGql[]
}

type TradeWithTwoFieldsResponse = Record<string, TradeGql[]>

export interface Trade {
  id: string
  serialId: JSBI
  type: EntityType
  transaction: Transaction
  logIndex: JSBI
  takerAccount: MarginAccount
  makerAccount: MarginAccount | undefined
  market: Market
  primary: Token
  secondary: Token
  takerDeltaWei: CurrencyAmount<Token>
  makerDeltaWei: CurrencyAmount<Token>
  primaryDeltaWei: CurrencyAmount<Token>
  secondaryDeltaWei: CurrencyAmount<Token>
  primaryPriceWei: Fraction
  takerAmountUSD: Fraction
  makerAmountUSD: Fraction
  traderAddress: address
  isExpiration: boolean
}

export function collapseTrades(trades: Trade[], chainId: number, account?: string): Trade[] {
  return trades.reduce<Trade[]>((memo, trade) => {
    if (memo.length === 0) {
      memo.push(trade)
    } else if (memo[memo.length - 1].transaction.transactionHash === trade.transaction.transactionHash) {
      const nextTrade = memo[memo.length - 1]
      if (
        trade.takerAccount.account === account &&
        nextTrade.takerAccount.account === account &&
        JSBI.equal(trade.takerAccount.accountNumber, nextTrade.takerAccount.accountNumber) &&
        trade.makerDeltaWei.currency.equals(nextTrade.takerDeltaWei.currency) &&
        !trade.takerDeltaWei.currency.equals(nextTrade.makerDeltaWei.currency)
      ) {
        // the trade is connective if the accounts are the same, output of one trade is the input of another and the
        // output of another is not the same as the original's input
        const takerTokenDeltaWei = trade.takerDeltaWei
        const makerTokenDeltaWei = nextTrade.makerDeltaWei
        const market = deriveMarketFromTokensReq(takerTokenDeltaWei.currency, makerTokenDeltaWei.currency)
        const takerToken = takerTokenDeltaWei.currency
        const primaryDeltaWei = takerToken.symbol === market.primary ? takerTokenDeltaWei : makerTokenDeltaWei
        const secondaryDeltaWei = takerToken.symbol === market.secondary ? takerTokenDeltaWei : makerTokenDeltaWei
        memo[memo.length - 1] = {
          id: trade.id,
          serialId: trade.serialId,
          type: trade.type,
          transaction: trade.transaction,
          logIndex: trade.logIndex,
          takerAccount: trade.takerAccount,
          makerAccount: nextTrade.makerAccount,
          market: market,
          primary: primaryDeltaWei.currency,
          secondary: secondaryDeltaWei.currency,
          takerDeltaWei: takerTokenDeltaWei,
          makerDeltaWei: makerTokenDeltaWei,
          primaryDeltaWei: primaryDeltaWei,
          secondaryDeltaWei: secondaryDeltaWei,
          primaryPriceWei: secondaryDeltaWei.asFraction.divide(primaryDeltaWei.asFraction),
          takerAmountUSD: trade.takerAmountUSD,
          makerAmountUSD: trade.makerAmountUSD,
          traderAddress: trade.traderAddress,
          isExpiration: trade.isExpiration,
        }
      } else {
        memo.push(trade)
      }
    } else {
      memo.push(trade)
    }

    return memo
  }, [])
}

const FILLER_VARIABLE = { walletAddress: 'filler' }

export function useTradeDataByTime(): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  return useTradeData(TRADES_BY_TIME_GQL, FILLER_VARIABLE, RefreshFrequency.Slow)
}

export function useTradeDataByWallet(
  walletAddress: string | undefined,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase(),
    }
  }, [walletAddress])
  return useTradeData(TRADES_BY_WALLET_GQL, variables, RefreshFrequency.Fast)
}

/*export function useTradeDataByAccountArray(
  walletAddressArray: [string] | undefined,
): {
  loading: boolean
  error: boolean
  data: Record<string, Trade[]>
} {
  const { chainId } = useActiveWeb3React()
  const calls = useMemo(() => {
    return (walletAddressArray ?? []).map(walletAddress => {
      return {
        chainId: chainId,
        clientType: GraphqlClientType.Dolomite,
        query: TRADES_BY_WALLET_GQL.loc!.source.body,
        variables: JSON.stringify({
          walletAddress: walletAddress.toLowerCase(),
        }),
      }
    })
  }, [chainId, walletAddressArray])
  const accountTransfers = useGraphqlResultList<Trade[]>(calls, RefreshFrequency.Fast)
  return useTradeData(TRADES_BY_WALLET_GQL, variables, RefreshFrequency.Fast)
}*/

export function useTradeDataByToken(
  token: string | undefined,
  chain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const variables = useMemo(() => {
    if (!token) {
      return undefined
    }

    return {
      token: token.toLowerCase(),
    }
  }, [token])

  return useTradeDataWithTwoFields(
    TRADES_BY_TOKEN_GQL,
    variables,
    RefreshFrequency.Slow,
    'makerTokenTrades',
    'takerTokenTrades',
    chain,
  )
}

export function useTradeDataByMarginAccount(
  marginAccount?: MarginAccount,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const variables = useMemo(() => {
    if (!marginAccount) {
      return undefined
    }

    return {
      marginAccount: marginAccount.toString(),
    }
  }, [marginAccount])

  return useTradeDataWithTwoFields(
    TRADES_BY_MARGIN_ACCOUNT_GQL,
    variables,
    RefreshFrequency.Fast,
    'makerTrades',
    'takerTrades',
  )
}

export function useTradeDataByMarginAccountArray(
  marginAccountArray?: MarginAccount[],
): {
  loading: boolean
  error: boolean
  data: Record<string, Trade[]>
} {
  const { chainId } = useActiveWeb3React()
  const calls = useMemo(() => {
    return (marginAccountArray ?? []).map(marginAccount => {
      return {
        chainId: chainId,
        clientType: GraphqlClientType.Dolomite,
        query: TRADES_BY_MARGIN_ACCOUNT_GQL.loc!.source.body,
        variables: JSON.stringify({
          marginAccount: marginAccount.toString(),
        }),
      }
    })
  }, [chainId, marginAccountArray])
  const accountTrades = useGraphqlResultList<TradeWithTwoFieldsResponse>(calls, RefreshFrequency.Fast)

  const tokenMap = useAllTokens()
  return useMemo(() => {
    let anyError = false
    let anyLoading = false
    const data = (marginAccountArray ?? []).reduce((memo, marginAccount, i) => {
      const state = accountTrades?.[i]
      if (!state) {
        return memo
      }

      anyError = anyError || state.error
      anyLoading = anyLoading || state.loading

      if (state.result) {
        const tradesA = mapTrades(state.result?.['makerTrades'] ?? [], tokenMap, chainId)
        const tradesB = mapTrades(state.result?.['takerTrades'] ?? [], tokenMap, chainId)
        memo[marginAccount.toString()] = tradesA
          .concat(tradesB)
          .sort((tradeA, tradeB) => (JSBI.greaterThan(tradeA.serialId, tradeB.serialId) ? -1 : 1))
      }
      return memo
    }, {} as Record<address, Trade[]>)

    return {
      data: data,
      loading: anyLoading,
      error: anyError,
    }
  }, [accountTrades, chainId, marginAccountArray, tokenMap])
}

function useTradeData(
  query: DocumentNode,
  memorizedVariables: Omit<Record<string, any>, 'blockNumber'> | undefined,
  refreshFrequency: RefreshFrequency,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const queryState = useGraphqlResult<TradeResponse>(
    GraphqlClientType.Dolomite,
    query.loc!.source.body,
    memorizedVariables,
    refreshFrequency,
  )

  const tokenMap = useAllTokens()
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const trades = mapTrades(result?.trades ?? [], tokenMap, chainId)
    return {
      loading: anyLoading,
      error: anyError,
      data: trades,
    }
  }, [queryState, tokenMap, chainId])
}

function useTradeDataWithTwoFields(
  query: DocumentNode,
  memorizedVariables: Omit<Record<string, any>, 'blockNumber'> | undefined,
  refreshFrequency: RefreshFrequency,
  fieldA: string,
  fieldB: string,
  chain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const queryState = useGraphqlResult<TradeWithTwoFieldsResponse>(
    GraphqlClientType.Dolomite,
    query.loc!.source.body,
    memorizedVariables,
    refreshFrequency,
    chain,
  )

  const tokenMap = useAllTokens()
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const tradesA = mapTrades(result?.[fieldA] ?? [], tokenMap, chainId)
    const tradesB = mapTrades(result?.[fieldB] ?? [], tokenMap, chainId)
    return {
      loading: anyLoading,
      error: anyError,
      data: tradesA
        .concat(tradesB)
        .sort((tradeA, tradeB) => (JSBI.greaterThan(tradeA.serialId, tradeB.serialId) ? -1 : 1)),
    }
  }, [queryState, fieldA, tokenMap, chainId, fieldB])
}

function mapTrades(trades: TradeGql[], tokenMap: Record<string, Token | undefined>, chainId: ChainId): Trade[] {
  return trades
    .map<Trade | undefined>(trade => {
      const takerToken: Token | undefined = tokenMap[toChecksumAddress(trade.takerToken.id)]
      const makerToken: Token | undefined = tokenMap[toChecksumAddress(trade.makerToken.id)]
      if (!takerToken || !makerToken) {
        return undefined
      }

      const takerTokenDeltaWei = createCurrencyAmount(takerToken, trade.takerTokenDeltaWei)
      const makerTokenDeltaWei = createCurrencyAmount(makerToken, trade.makerTokenDeltaWei)
      const market = deriveMarketFromTokensReq(takerTokenDeltaWei.currency, makerTokenDeltaWei.currency)
      const primaryDeltaWei = takerToken.symbol === market.primary ? takerTokenDeltaWei : makerTokenDeltaWei
      const secondaryDeltaWei = takerToken.symbol === market.secondary ? takerTokenDeltaWei : makerTokenDeltaWei
      return {
        id: trade.id,
        type: EntityType.Trade,
        serialId: JSBI.BigInt(trade.serialId),
        transaction: createTransaction(trade.transaction),
        logIndex: JSBI.BigInt(trade.logIndex),
        takerAccount: createMarginAccount(trade.takerMarginAccount),
        makerAccount: createMarginAccountOpt(trade.makerMarginAccount),
        primary: primaryDeltaWei.currency,
        secondary: secondaryDeltaWei.currency,
        market: market,
        takerDeltaWei: takerTokenDeltaWei,
        makerDeltaWei: makerTokenDeltaWei,
        primaryDeltaWei: primaryDeltaWei,
        secondaryDeltaWei: secondaryDeltaWei,
        primaryPriceWei: secondaryDeltaWei.asFraction.divide(primaryDeltaWei.asFraction),
        takerAmountUSD: createFractionUSD(trade.takerAmountUSD),
        makerAmountUSD: createFractionUSD(trade.makerAmountUSD),
        traderAddress: toChecksumAddress(trade.traderAddress),
        isExpiration: toChecksumAddress(trade.traderAddress) === EXPIRY_TRADER_ADDRESSES[chainId],
      }
    })
    .filter(trade => !!trade)
    .map<Trade>(trade => trade!)
}
