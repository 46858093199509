import { SECONDS_PER_YEAR, ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { Percent } from '@dolomite-exchange/v2-sdk'

// export const BASIS_POINTS_DIVISOR = 10000
export const BASIS_POINTS_DIVISOR = 1

export const PLACEHOLDER_ACCOUNT = '0x0000000000000000000000000000000000000001'

export enum GmxBalanceAndSupplyDataKeys {
  gmx = 'gmx',
  esGmx = 'esGmx',
  glp = 'glp',
  stakedGmxTracker = 'stakedGmxTracker',
}

export interface GmxBalanceAndSupplyData {
  balanceData: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined>
  supplyData: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined>
}

export function getBalanceAndSupplyData(balances: Fraction[]): GmxBalanceAndSupplyData {
  if (balances.length === 0) {
    return {
      balanceData: {
        [GmxBalanceAndSupplyDataKeys.gmx]: undefined,
        [GmxBalanceAndSupplyDataKeys.esGmx]: undefined,
        [GmxBalanceAndSupplyDataKeys.glp]: undefined,
        [GmxBalanceAndSupplyDataKeys.stakedGmxTracker]: undefined,
      },
      supplyData: {
        [GmxBalanceAndSupplyDataKeys.gmx]: undefined,
        [GmxBalanceAndSupplyDataKeys.esGmx]: undefined,
        [GmxBalanceAndSupplyDataKeys.glp]: undefined,
        [GmxBalanceAndSupplyDataKeys.stakedGmxTracker]: undefined,
      },
    }
  }

  const keys: GmxBalanceAndSupplyDataKeys[] = [
    GmxBalanceAndSupplyDataKeys.gmx,
    GmxBalanceAndSupplyDataKeys.esGmx,
    GmxBalanceAndSupplyDataKeys.glp,
    GmxBalanceAndSupplyDataKeys.stakedGmxTracker,
  ]
  const balanceData: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined> = {
    [GmxBalanceAndSupplyDataKeys.gmx]: undefined,
    [GmxBalanceAndSupplyDataKeys.esGmx]: undefined,
    [GmxBalanceAndSupplyDataKeys.glp]: undefined,
    [GmxBalanceAndSupplyDataKeys.stakedGmxTracker]: undefined,
  }
  const supplyData: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined> = {
    [GmxBalanceAndSupplyDataKeys.gmx]: undefined,
    [GmxBalanceAndSupplyDataKeys.esGmx]: undefined,
    [GmxBalanceAndSupplyDataKeys.glp]: undefined,
    [GmxBalanceAndSupplyDataKeys.stakedGmxTracker]: undefined,
  }
  const propsLength = 2

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    balanceData[key] = balances[i * propsLength]
    supplyData[key] = balances[i * propsLength + 1]
  }

  return {
    balanceData,
    supplyData,
  }
}

export enum GmxDepositDataKeys {
  gmxInStakedGmx = 'gmxInStakedGmx',
  esGmxInStakedGmx = 'esGmxInStakedGmx',
  stakedGmxInBonusGmx = 'stakedGmxInBonusGmx',
  stakedBnGmxInFeeGmx = 'stakedBnGmxInFeeGmx',
  bnGmxInFeeGmx = 'bnGmxInFeeGmx',
  glpInStakedGlp = 'glpInStakedGlp',
}

export function getDepositBalanceData(depositBalances: Fraction[]): Record<GmxDepositDataKeys, Fraction | undefined> {
  if (depositBalances.length === 0) {
    return {
      [GmxDepositDataKeys.gmxInStakedGmx]: undefined,
      [GmxDepositDataKeys.esGmxInStakedGmx]: undefined,
      [GmxDepositDataKeys.stakedGmxInBonusGmx]: undefined,
      [GmxDepositDataKeys.stakedBnGmxInFeeGmx]: undefined,
      [GmxDepositDataKeys.bnGmxInFeeGmx]: undefined,
      [GmxDepositDataKeys.glpInStakedGlp]: undefined,
    }
  }

  const keys = [
    GmxDepositDataKeys.gmxInStakedGmx,
    GmxDepositDataKeys.esGmxInStakedGmx,
    GmxDepositDataKeys.stakedGmxInBonusGmx,
    GmxDepositDataKeys.stakedBnGmxInFeeGmx,
    GmxDepositDataKeys.bnGmxInFeeGmx,
    GmxDepositDataKeys.glpInStakedGlp,
  ]
  const data: Record<GmxDepositDataKeys, Fraction | undefined> = {
    [GmxDepositDataKeys.gmxInStakedGmx]: undefined,
    [GmxDepositDataKeys.esGmxInStakedGmx]: undefined,
    [GmxDepositDataKeys.stakedGmxInBonusGmx]: undefined,
    [GmxDepositDataKeys.stakedBnGmxInFeeGmx]: undefined,
    [GmxDepositDataKeys.bnGmxInFeeGmx]: undefined,
    [GmxDepositDataKeys.glpInStakedGlp]: undefined,
  }

  for (let i = 0; i < keys.length; i++) {
    data[keys[i]] = depositBalances[i]
  }

  return data
}

export enum GmxVestingDataKeys {
  gmxVester = 'gmxVester',
  glpVester = 'glpVester',
}

export interface GmxVestingData {
  pairAmount: Fraction
  vestedAmount: Fraction
  escrowedBalance: Fraction
  claimedAmounts: Fraction
  claimable: Fraction
  claimSum: Fraction
  maxVestableAmount: Fraction
  averageStakedAmount: Fraction
}

export function getVestingData(vestingInfo: Fraction[]): Record<GmxVestingDataKeys, GmxVestingData | undefined> {
  if (vestingInfo.length === 0) {
    return {
      [GmxVestingDataKeys.gmxVester]: undefined,
      [GmxVestingDataKeys.glpVester]: undefined,
    }
  }

  const keys: GmxVestingDataKeys[] = [GmxVestingDataKeys.gmxVester, GmxVestingDataKeys.glpVester]
  const data: Record<GmxVestingDataKeys, GmxVestingData | undefined> = {
    [GmxVestingDataKeys.gmxVester]: undefined,
    [GmxVestingDataKeys.glpVester]: undefined,
  }
  const propsLength = 7
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = {
      pairAmount: vestingInfo[i * propsLength],
      vestedAmount: vestingInfo[i * propsLength + 1],
      escrowedBalance: vestingInfo[i * propsLength + 2],
      claimedAmounts: vestingInfo[i * propsLength + 3],
      claimable: vestingInfo[i * propsLength + 4],
      claimSum: vestingInfo[i * propsLength + 3].add(vestingInfo[i * propsLength + 4]),
      maxVestableAmount: vestingInfo[i * propsLength + 5],
      averageStakedAmount: vestingInfo[i * propsLength + 6],
    }
  }

  return data
}

export interface GmxStakingInfoData {
  claimable: Fraction
  tokensPerInterval: Fraction
  averageStakedAmounts: Fraction
  cumulativeRewards: Fraction
  totalSupply: Fraction
}

export enum GmxStakingInfoDataKeys {
  stakedGmxTracker = 'stakedGmxTracker',
  bonusGmxTracker = 'bonusGmxTracker',
  feeGmxTracker = 'feeGmxTracker',
  stakedGlpTracker = 'stakedGlpTracker',
  feeGlpTracker = 'feeGlpTracker',
  extendedGmxFeeTracker = 'extendedGmxFeeTracker',
}

export function getStakingData(
  stakingInfo?: Fraction[],
): Record<GmxStakingInfoDataKeys, GmxStakingInfoData | undefined> {
  if (!stakingInfo || stakingInfo.length === 0) {
    return {
      [GmxStakingInfoDataKeys.stakedGmxTracker]: undefined,
      [GmxStakingInfoDataKeys.bonusGmxTracker]: undefined,
      [GmxStakingInfoDataKeys.feeGmxTracker]: undefined,
      [GmxStakingInfoDataKeys.stakedGlpTracker]: undefined,
      [GmxStakingInfoDataKeys.feeGlpTracker]: undefined,
      [GmxStakingInfoDataKeys.extendedGmxFeeTracker]: undefined,
    }
  }

  const keys: GmxStakingInfoDataKeys[] = [
    GmxStakingInfoDataKeys.stakedGmxTracker,
    GmxStakingInfoDataKeys.bonusGmxTracker,
    GmxStakingInfoDataKeys.feeGmxTracker,
    GmxStakingInfoDataKeys.stakedGlpTracker,
    GmxStakingInfoDataKeys.feeGlpTracker,
    GmxStakingInfoDataKeys.extendedGmxFeeTracker,
  ]
  const data: Record<GmxStakingInfoDataKeys, GmxStakingInfoData | undefined> = {
    [GmxStakingInfoDataKeys.stakedGmxTracker]: undefined,
    [GmxStakingInfoDataKeys.bonusGmxTracker]: undefined,
    [GmxStakingInfoDataKeys.feeGmxTracker]: undefined,
    [GmxStakingInfoDataKeys.stakedGlpTracker]: undefined,
    [GmxStakingInfoDataKeys.feeGlpTracker]: undefined,
    [GmxStakingInfoDataKeys.extendedGmxFeeTracker]: undefined,
  }

  const propsLength = 5
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    }
  }

  return data
}

export interface GmxProcessedData {
  gmxBalance: Fraction
  gmxBalanceUsd: Fraction
  gmxSupply: Fraction
  gmxSupplyUsd: Fraction
  stakedGmxSupply: Fraction
  stakedGmxSupplyUsd: Fraction
  gmxInStakedGmx: Fraction
  gmxInStakedGmxUsd: Fraction
  esGmxBalance: Fraction
  esGmxBalanceUsd: Fraction
  stakedGmxTrackerSupply: Fraction
  stakedGmxTrackerSupplyUsd: Fraction
  stakedEsGmxSupply: Fraction
  stakedEsGmxSupplyUsd: Fraction
  esGmxInStakedGmx: Fraction
  esGmxInStakedGmxUsd: Fraction
  maxUnstakeableGmx: Fraction
  bnGmxInFeeGmx: Fraction
  bonusGmxInFeeGmx: Fraction
  feeGmxSupply: Fraction
  feeGmxSupplyUsd: Fraction
  stakedGmxTrackerRewards: Fraction
  stakedGmxTrackerRewardsUsd: Fraction
  bonusGmxTrackerRewards: Fraction
  feeGmxTrackerRewards: Fraction
  feeGmxTrackerRewardsUsd: Fraction
  boostPercentage: Percent
  stakedGmxTrackerAnnualRewardsUsd: Fraction
  gmxAprForEsGmx: Percent
  feeGmxTrackerAnnualRewardsUsd: Fraction
  gmxAprForNativeToken: Percent
  gmxBoostAprForNativeToken: Percent
  gmxAprTotal: Percent
  gmxAprTotalWithBoost: Percent
  gmxAprForNativeTokenWithBoost: Percent
  totalGmxRewardsUsd: Fraction
  glpSupply: Fraction
  glpPrice: Fraction
  glpSupplyUsd: Fraction
  glpBalance: Fraction
  glpBalanceUsd: Fraction
  stakedGlpTrackerRewards: Fraction
  stakedGlpTrackerRewardsUsd: Fraction
  feeGlpTrackerRewards: Fraction
  feeGlpTrackerRewardsUsd: Fraction
  stakedGlpTrackerAnnualRewardsUsd: Fraction
  glpAprForEsGmx: Percent
  feeGlpTrackerAnnualRewardsUsd: Fraction
  glpAprForNativeToken: Percent
  glpAprTotal: Percent
  totalGlpRewardsUsd: Fraction
  totalEsGmxRewards: Fraction
  totalEsGmxRewardsUsd: Fraction
  gmxVesterRewards: Fraction
  glpVesterRewards: Fraction
  totalVesterRewards: Fraction
  totalVesterRewardsUsd: Fraction
  totalNativeTokenRewards: Fraction
  totalNativeTokenRewardsUsd: Fraction
  extendedGmxTokenRewards: Fraction
  extendedGmxTokenRewardsUsd: Fraction
  totalRewardsUsd: Fraction
}

export function processGmxAndGlpData({
  balanceData,
  supplyData,
  depositBalanceData,
  stakingData,
  vestingData,
  aum,
  nativeTokenPrice,
  stakedGmxSupply,
  gmxPrice,
  gmxSupply,
  sbfGmxBalance,
}: {
  balanceData?: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined>
  supplyData?: Record<GmxBalanceAndSupplyDataKeys, Fraction | undefined>
  depositBalanceData?: Record<GmxDepositDataKeys, Fraction | undefined>
  stakingData?: Record<GmxStakingInfoDataKeys, GmxStakingInfoData | undefined>
  vestingData?: Record<GmxVestingDataKeys, GmxVestingData | undefined>
  aum?: Fraction
  nativeTokenPrice?: Fraction
  stakedGmxSupply?: Fraction
  gmxPrice?: Fraction
  gmxSupply?: Fraction
  sbfGmxBalance?: Fraction
}): GmxProcessedData | undefined {
  if (
    !balanceData?.gmx ||
    !balanceData.esGmx ||
    !balanceData.glp ||
    !balanceData.stakedGmxTracker ||
    !supplyData?.gmx ||
    !supplyData.esGmx ||
    !supplyData.glp ||
    !supplyData.stakedGmxTracker ||
    !depositBalanceData?.gmxInStakedGmx ||
    !depositBalanceData.esGmxInStakedGmx ||
    !depositBalanceData.stakedGmxInBonusGmx ||
    !depositBalanceData.stakedBnGmxInFeeGmx ||
    !depositBalanceData.bnGmxInFeeGmx ||
    !depositBalanceData.glpInStakedGlp ||
    !stakingData?.stakedGmxTracker ||
    !stakingData.bonusGmxTracker ||
    !stakingData.feeGmxTracker ||
    !stakingData.stakedGlpTracker ||
    !stakingData.feeGlpTracker ||
    !stakingData.extendedGmxFeeTracker ||
    !vestingData?.gmxVester ||
    !vestingData.glpVester ||
    !aum ||
    !nativeTokenPrice ||
    !stakedGmxSupply ||
    !gmxPrice ||
    !gmxSupply ||
    !sbfGmxBalance
  ) {
    return undefined
  }

  const gmxBalance = balanceData.gmx
  const gmxBalanceUsd = balanceData.gmx.multiply(gmxPrice)

  const gmxSupplyUsd = gmxSupply.multiply(gmxPrice)

  const stakedGmxSupplyUsd = stakedGmxSupply.multiply(gmxPrice)
  const gmxInStakedGmx = depositBalanceData.gmxInStakedGmx
  const gmxInStakedGmxUsd = depositBalanceData.gmxInStakedGmx.multiply(gmxPrice)

  const esGmxBalance = balanceData.esGmx
  const esGmxBalanceUsd = balanceData.esGmx.multiply(gmxPrice)

  const stakedGmxTrackerSupply = supplyData.stakedGmxTracker
  const stakedGmxTrackerSupplyUsd = supplyData.stakedGmxTracker.multiply(gmxPrice)
  const stakedEsGmxSupply = stakedGmxTrackerSupply.subtract(stakedGmxSupply)
  const stakedEsGmxSupplyUsd = stakedEsGmxSupply.multiply(gmxPrice)

  const esGmxInStakedGmx = depositBalanceData.esGmxInStakedGmx
  const esGmxInStakedGmxUsd = depositBalanceData.esGmxInStakedGmx.multiply(gmxPrice)

  const bnGmxInFeeGmx = depositBalanceData.bnGmxInFeeGmx
  const bonusGmxInFeeGmx = depositBalanceData.stakedBnGmxInFeeGmx
  const feeGmxSupply = stakingData.feeGmxTracker.totalSupply
  const feeGmxSupplyUsd = feeGmxSupply.multiply(gmxPrice)

  const extendedGmxFeeAnnualRewardsUsd = stakingData.extendedGmxFeeTracker.tokensPerInterval
    .multiply(SECONDS_PER_YEAR)
    .multiply(gmxPrice)
  const extendedGmxFeeSupply = stakingData.extendedGmxFeeTracker.totalSupply
  const extendedGmxFeeSupplyUsd = extendedGmxFeeSupply.multiply(gmxPrice)

  const stakedGmxTrackerRewards = stakingData.stakedGmxTracker.claimable
  const stakedGmxTrackerRewardsUsd = stakingData.stakedGmxTracker.claimable.multiply(gmxPrice)

  const bonusGmxTrackerRewards = stakingData.bonusGmxTracker.claimable

  const feeGmxTrackerRewards = stakingData.feeGmxTracker.claimable
  const feeGmxTrackerRewardsUsd = stakingData.feeGmxTracker.claimable.multiply(nativeTokenPrice)

  let boostPercentage = ZERO_PERCENT
  if (bonusGmxInFeeGmx.greaterThan(ZERO_FRACTION)) {
    const boostFraction = bnGmxInFeeGmx.divide(bonusGmxInFeeGmx)
    boostPercentage = new Percent(boostFraction.numerator, boostFraction.denominator)
  }

  const stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
    .multiply(SECONDS_PER_YEAR)
    .multiply(gmxPrice)

  let gmxAprForEsGmx = ZERO_PERCENT
  if (stakedGmxTrackerSupplyUsd.greaterThan(ZERO_FRACTION)) {
    const rawApr = stakedGmxTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(stakedGmxTrackerSupplyUsd)
    gmxAprForEsGmx = new Percent(rawApr.numerator, rawApr.denominator)
  }
  const feeGmxTrackerAnnualRewardsUsd = stakingData.feeGmxTracker.tokensPerInterval
    .multiply(SECONDS_PER_YEAR)
    .multiply(nativeTokenPrice)

  let gmxAprForNativeToken = ZERO_PERCENT
  if (extendedGmxFeeSupplyUsd.greaterThan(ZERO_FRACTION)) {
    const rawApr = extendedGmxFeeAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(extendedGmxFeeSupplyUsd)
    gmxAprForNativeToken = new Percent(rawApr.numerator, rawApr.denominator)
  }

  const gmxBoostAprForNativeToken = gmxAprForNativeToken.multiply(boostPercentage).divide(BASIS_POINTS_DIVISOR)
  const gmxAprTotal = gmxAprForNativeToken.add(gmxAprForEsGmx)
  const gmxAprTotalWithBoost = gmxAprForNativeToken.add(gmxBoostAprForNativeToken).add(gmxAprForEsGmx)
  const gmxAprForNativeTokenWithBoost = gmxAprForNativeToken.add(gmxBoostAprForNativeToken)

  const totalGmxRewardsUsd = stakedGmxTrackerRewardsUsd.add(feeGmxTrackerRewardsUsd)

  const glpSupply = supplyData.glp
  const glpPrice = glpSupply.greaterThan(ZERO_FRACTION) ? aum.divide(glpSupply) : ZERO_FRACTION

  const glpSupplyUsd = supplyData.glp.multiply(glpPrice)

  const glpBalance = depositBalanceData.glpInStakedGlp
  const glpBalanceUsd = depositBalanceData.glpInStakedGlp.multiply(glpPrice)

  const stakedGlpTrackerRewards = stakingData.stakedGlpTracker.claimable
  const stakedGlpTrackerRewardsUsd = stakingData.stakedGlpTracker.claimable.multiply(gmxPrice)

  const feeGlpTrackerRewards = stakingData.feeGlpTracker.claimable
  const feeGlpTrackerRewardsUsd = stakingData.feeGlpTracker.claimable.multiply(nativeTokenPrice)

  const stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
    .multiply(SECONDS_PER_YEAR)
    .multiply(gmxPrice)

  let glpAprForEsGmx = ZERO_PERCENT
  if (glpSupplyUsd.greaterThan(ZERO_FRACTION)) {
    const rawApr = stakedGlpTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(glpSupplyUsd)
    glpAprForEsGmx = new Percent(rawApr.numerator, rawApr.denominator)
  }
  const feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
    .multiply(SECONDS_PER_YEAR)
    .multiply(nativeTokenPrice)

  let glpAprForNativeToken = ZERO_PERCENT
  if (glpSupplyUsd.greaterThan(ZERO_FRACTION)) {
    const rawApr = feeGlpTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(glpSupplyUsd)
    glpAprForNativeToken = new Percent(rawApr.numerator, rawApr.denominator)
  }
  const glpAprTotal = glpAprForNativeToken.add(glpAprForEsGmx)

  const totalGlpRewardsUsd = stakedGlpTrackerRewardsUsd.add(feeGlpTrackerRewardsUsd)

  const totalEsGmxRewards = stakedGmxTrackerRewards.add(stakedGlpTrackerRewards)
  const totalEsGmxRewardsUsd = stakedGmxTrackerRewardsUsd.add(stakedGlpTrackerRewardsUsd)

  const gmxVesterRewards = vestingData.gmxVester.claimable
  const glpVesterRewards = vestingData.glpVester.claimable
  const totalVesterRewards = gmxVesterRewards.add(glpVesterRewards)
  const totalVesterRewardsUsd = totalVesterRewards.multiply(gmxPrice)

  const totalNativeTokenRewards = feeGmxTrackerRewards.add(feeGlpTrackerRewards)
  const totalNativeTokenRewardsUsd = feeGmxTrackerRewardsUsd.add(feeGlpTrackerRewardsUsd)

  const extendedGmxTokenRewards = stakingData.extendedGmxFeeTracker.claimable
  const extendedGmxTokenRewardsUsd = extendedGmxTokenRewards.multiply(gmxPrice)

  const totalRewardsUsd = totalEsGmxRewardsUsd
    .add(totalNativeTokenRewardsUsd)
    .add(totalVesterRewardsUsd)
    .add(extendedGmxTokenRewardsUsd)

  // bonusGmxTrackerRewards updates every block which messes up the calculations below with `divisor`. To compensate for
  // this, we truncate the value to the nearest 1000 and add 1000 to it. This ensures that the `divisor` is always
  // slightly larger, and we understate the max unstakeable amount.
  const truncationFactor = 1000
  const multiplierPointsAmount = bonusGmxTrackerRewards
    .divide(truncationFactor)
    .multiply(truncationFactor)
    .add(truncationFactor)
    .add(bnGmxInFeeGmx)

  const maxUnstakeableGmx = gmxInStakedGmx.lessThan(sbfGmxBalance) ? gmxInStakedGmx : sbfGmxBalance
  return {
    gmxBalance,
    gmxBalanceUsd,
    gmxSupply,
    gmxSupplyUsd,
    stakedGmxSupply,
    stakedGmxSupplyUsd,
    gmxInStakedGmx,
    gmxInStakedGmxUsd,
    esGmxBalance,
    esGmxBalanceUsd,
    stakedGmxTrackerSupply,
    stakedGmxTrackerSupplyUsd,
    stakedEsGmxSupply,
    stakedEsGmxSupplyUsd,
    esGmxInStakedGmx,
    esGmxInStakedGmxUsd,
    maxUnstakeableGmx,
    bnGmxInFeeGmx,
    bonusGmxInFeeGmx,
    feeGmxSupply,
    feeGmxSupplyUsd,
    stakedGmxTrackerRewards,
    stakedGmxTrackerRewardsUsd,
    bonusGmxTrackerRewards,
    feeGmxTrackerRewards,
    feeGmxTrackerRewardsUsd,
    boostPercentage,
    stakedGmxTrackerAnnualRewardsUsd,
    gmxAprForEsGmx,
    feeGmxTrackerAnnualRewardsUsd,
    gmxAprForNativeToken,
    gmxBoostAprForNativeToken,
    gmxAprTotal,
    gmxAprTotalWithBoost,
    gmxAprForNativeTokenWithBoost,
    totalGmxRewardsUsd,
    glpSupply,
    glpPrice,
    glpSupplyUsd,
    glpBalance,
    glpBalanceUsd,
    stakedGlpTrackerRewards,
    stakedGlpTrackerRewardsUsd,
    feeGlpTrackerRewards,
    feeGlpTrackerRewardsUsd,
    stakedGlpTrackerAnnualRewardsUsd,
    glpAprForEsGmx,
    feeGlpTrackerAnnualRewardsUsd,
    glpAprForNativeToken,
    glpAprTotal,
    totalGlpRewardsUsd,
    totalEsGmxRewards,
    totalEsGmxRewardsUsd,
    gmxVesterRewards,
    glpVesterRewards,
    totalVesterRewards,
    totalVesterRewardsUsd,
    totalNativeTokenRewards,
    totalNativeTokenRewardsUsd,
    extendedGmxTokenRewards,
    extendedGmxTokenRewardsUsd,
    totalRewardsUsd,
  }
}
