import React, { Attributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { StyledTooltip, StyledTooltipWithIcon } from '../common/StyledTooltip'
import {
  useAllActiveTokensArray,
  useAllActiveTokensArrayWithExternalTokens,
  useEther,
  useWethToken,
} from '../../hooks/Tokens'
import {
  useCurrencyBalance,
  useDolomiteBalancesWithLoadingIndicator,
  useTokenBalance,
  useTokenBalances,
} from '../../state/wallet/hooks'
import {
  useDefaultFiatValuesWithLoadingIndicator,
  useFiatValuesWithExternalAssetsMap,
  useFiatValuesWithLoadingIndicator,
  useFiatPricesWithLoadingIndicator,
} from '../../hooks/useFiatValue'
import { ChainId, FORMATTER, ONE_BIPS, ONE_FRACTION, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { InterestRate, useInterestRateData } from '../../types/interestRateData'
import { useHistory } from 'react-router-dom'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import getLogoOrDefault from '../common/TokenLogos'
import { Currency, Ether, Fraction, Percent } from '@dolomite-exchange/sdk-core'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Modal from '../Modal'
import SyncAltIcon from '@mui/icons-material/Autorenew'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import GLPBottomSection from '../ManageAssetRewardsPopover/assets/GLP/GLPBottomSection'
import { getSpecialAsset, SpecialAssetSymbol } from '../../constants/isolation/special-assets'
import PLVGLPBottomSection from '../ManageAssetRewardsPopover/assets/PLVGLP/PLVGLPBottomSection'
import YtGLPBottomSection from '../ManageAssetRewardsPopover/assets/YT-GLP/YtGLPBottomSection'
import { formatAmount } from '../../utils/formatAmount'
import Input from '@material-ui/core/Input'
import { X } from 'react-feather'
import { useShowYieldAsApr, useUserHideZeroBalances } from '../../state/user/hooks'
import { filterTokens, FilterType, useFilterTypes } from '../../constants/tokenLists/FilterTokens'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import JUSDCBottomSection from '../ManageAssetRewardsPopover/assets/JUSDC/JUSDCBottomSection'
import VArbBottomSection from '../ManageAssetRewardsPopover/assets/vARB/VArbBottomSection'
import { lighten } from 'polished'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import ZapModal from '../../pages/Borrow/ZapModal'
import BoltIcon from '@mui/icons-material/Bolt'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import DepositWithdrawModal from '../DepositWithdrawModal'
import { hasExternalRewards } from '../../state/graphql/useLiquidityMiningRewardsInterestRates'
import invariant from 'tiny-invariant'
import { InterestRatePart } from '../../types/InterestRatePart'
import { mapExternalTokenToListedAddress } from '../../utils'
import {
  isExternalToken,
  mapExternalCurrencyOptToListedToken,
  useAddEtherBalanceToWalletBalances,
  useAddExternalTokensToDolomiteData,
} from '../../utils/externalTokens'
import ClimbingBearImg from '../../assets/images/climbing-bear.png'
import WMNTBottomSection from '../ManageAssetRewardsPopover/assets/WMNT/WMNTBottomSection'
import { useJUsdcPendingRewards } from '../../hooks/jusdc/useJonesProtocol'
import { usePlvGlpPendingRewards } from '../../hooks/plutus/usePlutusProtocol'
import useGmxPendingRewards from '../../hooks/gmx/useGmxRewards'

const CUSTOM_BOTTOM_SECTION_COMPONENT_MAP: Record<string, JSX.Element | undefined> = {
  [SpecialAssetSymbol.dARB]: <VArbBottomSection />,
  [SpecialAssetSymbol.dGMX]: <GLPBottomSection />,
  [SpecialAssetSymbol.dfsGLP]: <GLPBottomSection />,
  [SpecialAssetSymbol.djUSDC]: <JUSDCBottomSection />,
  [SpecialAssetSymbol.dplvGLP]: <PLVGLPBottomSection />,
  [SpecialAssetSymbol.dYtGLPMarch2024]: <YtGLPBottomSection />,
  [SpecialAssetSymbol.dWMNT]: <WMNTBottomSection />,
}

const LendingPanelWrapper = styled.div`
  background-color: #292938;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 30px;
  padding: 15px 35px 20px;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  position: relative;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 55px;
    padding: 15px 35px 20px;
  }

  @media screen and (max-width: 500px) {
    margin: 0 auto 85px;
  }
`

const ColumnHeaders = styled.div`
  color: #d5d6e1;
  width: 100%;

  @media screen and (max-width: 800px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media screen and (max-width: 500px) {
    width: calc(100% + 70px);
    margin-left: -35px;
    padding: 8px 15px;
  }

  @media screen and (max-width: 470px) {
    font-size: 12px;
  }
`

const Column = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
  cursor: pointer;
`

const LoadingRates = styled.div`
    color: #d5d6e1;
color(text, tertiary);
    font-weight: 100;
    margin-top: 100px;
    text-align: center;

    > div {
        margin: 0 auto;
    }
`

const TickerColumn = styled(Column)`
  text-align: left;
  width: 27%;

  @media screen and (max-width: 800px) {
    width: 29%;
  }

  @media screen and (max-width: 750px) {
    width: 32%;
  }

  @media screen and (max-width: 600px) {
    width: 35%;
  }

  @media screen and (max-width: 550px) {
    width: 36%;
  }

  @media screen and (max-width: 500px) {
    width: 37%;
  }
`

const RateColumn = styled(Column)`
  text-align: right;
  width: calc(12% + 20px);
  margin-left: -20px !important;

  @media screen and (max-width: 800px) {
    width: calc(11% + 20px);
  }

  @media screen and (max-width: 750px) {
    width: calc(13% + 20px);
  }

  @media screen and (max-width: 700px) {
    width: calc(14% + 20px);
  }

  @media screen and (max-width: 650px) {
    width: calc(16% + 20px);
  }

  @media screen and (max-width: 550px) {
    width: calc(18% + 20px);
  }

  @media screen and (max-width: 500px) {
    width: 18%;
  }
`

const BalanceColumn = styled(Column)<{ slideRight?: boolean }>`
  text-align: right;
  ${({ slideRight }) => slideRight && `transform: translateX(20px) !important;`}
  width: calc(29% - 24px);

  @media screen and (max-width: 800px) {
    width: calc(28% - 24px);
  }

  @media screen and (max-width: 750px) {
    width: 27.5%;
  }

  @media screen and (max-width: 700px) {
    width: 27%;
  }

  @media screen and (max-width: 650px) {
    width: 26%;
  }

  @media screen and (max-width: 600px) {
    width: 24.5%;
  }

  div {
    text-align: right;
  }

  @media screen and (max-width: 550px) {
    width: 23%;
  }

  @media screen and (max-width: 500px) {
    ${({ slideRight }) => slideRight && `transform: translateX(0) !important;`}
    direction: rtl;
    width: 24%;
  }
`

const DepositWithdrawColumn = styled(Column)`
  text-align: right;
  width: calc(3% + 48px);
  font-size: 14px;

  > div {
    display: inline-block;
    vertical-align: top;
  }

  svg {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
`

const ButtonsColumn = styled(Column)``

const EarnRow = styled.div<{ expanded: boolean; hasExpandedContent: boolean | undefined }>`
  padding: 8px 35px;
  cursor: ${({ hasExpandedContent }) => (hasExpandedContent ? 'pointer' : 'default')};
  width: calc(100% + 70px);
  margin-left: -35px;
  height: ${({ expanded }) => (expanded ? '108px' : '58px')};
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  background: ${({ expanded, theme }) => (expanded ? theme.bg2 : 'none')};

  :hover {
    background: ${({ theme, hasExpandedContent }) => (hasExpandedContent ? theme.bg2 : 'none')};
  }

  > div {
    cursor: ${({ hasExpandedContent }) => (hasExpandedContent ? 'pointer' : 'default')};
  }

  @media screen and (max-width: 625px) {
    height: auto;
    transition: max-height 0.2s ease-in-out;
    max-height: ${({ expanded }) => (expanded ? '150px' : '63px')};
    min-height: 58px;
  }

  @media screen and (max-width: 560px) {
    padding: 8px 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 8px 15px;
  }
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 15px;
  margin-top: 2px;
  vertical-align: top;
  width: 25px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 20px;
    margin-right: 8px;
    margin-top: 5px;
  }

  @media screen and (max-width: 500px) {
    max-height: 22px;
  }
`

const TickerWrapper = styled.div<{ pointer?: boolean }>`
  display: inline-block;
  vertical-align: top;
  ${({ pointer }) => pointer && `cursor: pointer !important`};

  @media screen and (max-width: 500px) {
    width: calc(80% - 30px);
  }
`

const Ticker = styled.div`
  color: #f9f9f9;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  a {
    color: #8f91ad;

    svg {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 740px) {
    font-size: 16px;
  }

  @media screen and (max-width: 680px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;

    a {
      svg {
        font-size: 16px !important;
        margin-top: 1px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const TickerIcons = styled.div`
  display: inline-block;
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const Name = styled.div`
    font-size: 12px;
    font-weight: 100;
    margin-top: -3px;
        /*font-weight: 400;
  color: ${({ theme }) => theme.text2}; Possible alternative styling*/

    @media screen and (max-width: 420px) {
        font-size: 11px;
        max-width: 90px;
    }

    @media screen and (max-width: 680px) {
        font-size: 10px;
        width: 100%;
    }
`

const RateWrapper = styled.div`
  color: #8fc942;
  font-weight: 600;
  /*margin-top: 2px;*/

  @media screen and (max-width: 740px) {
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const OutsideRateWrapper = styled(RateWrapper)`
  color: ${({ theme }) => theme.blue2};
  width: fit-content;
  display: inline-block;

  @media screen and (max-width: 500px) {
    transform: translateY(-4px);
    margin-left: -20px;
  }
`

const Balance = styled.div`
  font-size: 16px;
  font-weight: 400;
  width: fit-content;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    direction: ltr;
  }
`

const DollarBalance = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: ${({ theme }) => theme.text3};
  margin-top: -2px;

  @media screen and (max-width: 740px) {
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    font-size: 11px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    margin-top: -6px;
  }
`

const RewardsText = styled.div<{ longText?: boolean }>`
  color: ${({ theme }) => theme.text1};
  font-size: ${({ longText }) => (longText ? 11 : 12)}px;

  @media screen and (max-width: 600px) {
    font-size: 11px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    margin-top: -6px;
  }
`

const BottomSection = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 36px;
  text-align: right;

  @media screen and (max-width: 625px) {
    margin-top: 15px;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    margin-top: 12px;
  }
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  display: inline !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 3px;
  margin-bottom: -1px;

  path {
    fill: ${({ theme }) => theme.blue2};
  }

  @media screen and (max-width: 500px) {
    margin-bottom: -2px;
  }
`

const NoTradingIconWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  transform: scale(0.7);
  color: #8f91ad;

  svg:nth-of-type(1) {
    transform: rotate(45deg);
    width: 25px;
    height: 25px;
    color: inherit;
  }

  svg:nth-of-type(2) {
    margin-left: -20px;
    margin-bottom: -5px;
    width: 25px;
    height: 25px;
    display: none;
  }
`

const Slash = styled.div`
  height: 24px;
  width: 2.5px;
  transform: rotate(-40deg);
  background-color: #8f91ad;
  margin-top: -30px;
  margin-left: 11px;
`

const NoTradingIcon = () => {
  return (
    <StyledTooltip
      title={'This asset cannot be borrowed or added to a liquidity pool, it can only act as collateral for borrowing.'}
      position={'top'}
    >
      <NoTradingIconWrapper>
        <SyncAltIcon />
        <DoNotDisturbIcon />
        <Slash />
      </NoTradingIconWrapper>
    </StyledTooltip>
  )
}

const TooltipText = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  color: #f9f9f9;
  padding: 12px 6px;
`

const TooltipLine = styled.div`
  margin: 2px 0;
  width: 100%;
`

const TooltipLineTitle = styled.span<{ disabled?: boolean }>`
  font-weight: 400;
  color: ${({ disabled, theme }) => disabled && theme.bg4};
`

const TooltipLineValue = styled.span<{ disabled?: boolean }>`
  font-weight: 600;
  color: ${({ disabled, theme }) => (disabled ? theme.bg4 : theme.green1)};
  float: right;
  margin-left: 10px;
`

const TopRow = styled.div`
  width: 100%;
  margin: 10px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 750px) {
    display: block;
    margin-bottom: 10px;
  }
`

const FilterSection = styled.div`
  position: relative;
  width: 50%;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 900px) {
    width: 40%;
  }

  @media screen and (max-width: 800px) {
    width: 265px;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const InputWrapper = styled.div`
  position: relative;
  vertical-align: top;
  width: calc(100% - 110px);

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 375px) {
    input {
      font-size: 12px !important;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 4px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const ZeroBalances = styled.div<{ visible: boolean }>`
  text-align: right;
  vertical-align: top;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
  margin-bottom: 2px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};

  @media screen and (max-width: 750px) {
    /*text-align: left;*/
    margin-top: 18px;
  }

  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 14px;
  width: 14px;
  border: 1px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 3px;
  margin-top: 2px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 8px;
  width: 8px;
  background: ${({ isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 2px;
  margin-top: 2px;
`

const AprSelect = styled.div`
  display: inline-block;
  float: right;
  margin-top: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg2};
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }

  @media screen and (max-width: 750px) {
    vertical-align: top;
    margin-top: 10px;
    margin-left: 10px;
  }
`

const AprSelector = styled.div<{ active: boolean }>`
  width: 50px;
  display: inline-block;
  padding: 7px 2px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ active, theme }) => active && theme.bg4};
`

const FilterSelect = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-radius: 4px;
  cursor: pointer;
  height: 33px;
  overflow: hidden;
  width: 90px;
  left: calc(100% - 90px);
  bottom: 0;
  position: absolute;
  z-index: 1;

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 4px;
    height: fit-content;
  `}
`

const FilterSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 10px;
  height: 33px;

  &:hover {
    background-color: #474956;
  }
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;
`

const HideOnSmall = styled.span`
  @media screen and (max-width: 750px) {
    display: none;
  }
`

const NoTokens = styled.div`
  height: 40px;
  margin: 25px auto 15px;
  width: 80px;
`

const OArbRewardsBreakdown = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  color: ${({ disabled, theme }) => (disabled ? theme.bg4 : theme.text2)};

  > div > div:nth-of-type(1) {
    width: 60%;
    text-align: right;
    display: inline-block;
    vertical-align: top;
  }

  > div > div:nth-of-type(2) {
    width: 40%;
    text-align: right;
    display: inline-block;
    vertical-align: top;
  }
`

const Button = styled.div`
  width: fit-content;
  text-align: center;
  margin-top: 4px;
  padding: 7px 12px;
  height: 33px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;

  svg {
    color: ${({ theme }) => theme.blue1};
    margin-right: -5px;
    margin-left: -5px;
    margin-top: -2px;
  }

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  @media screen and (max-width: 750px) {
    margin-top: 10px;
    margin-bottom: 0;
    float: right;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 0;
  }
`

const DepositWithdrawButton = styled(Button)`
  width: calc(50% - 5px) !important;
  background-color: ${({ theme }) => theme.blue1};
  color: ${({ theme }) => theme.text1};

  :hover {
    background-color: ${({ theme }) => theme.blue2};
  }
`

const DepositWithdrawButtons = styled.div`
  width: 100%;
  display: none;

  @media screen and (max-width: 750px) {
    display: flex;
    justify-content: space-between;
  }
`

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: top;
`

const Exclamation = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  position: absolute;
  right: 6px;
  top: 2px;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  display: none;
`

const ClimbingBear = styled.img<{ didSlide: boolean }>`
  cursor: ${({ didSlide }) => (didSlide ? 'default' : 'pointer')};
  width: 90px;
  position: absolute;
  right: -27px;
  z-index: 2;
  top: ${({ didSlide }) => (didSlide ? 'calc(100% - 75px)' : '20px')};
  transform: rotate(6deg);
  transition: top 1s cubic-bezier(0.82, 0, 0.86, 1.01);

  ${({ didSlide }) =>
    !didSlide &&
    `:active {
    transform: rotate(8deg) scale(0.96);
    
    + ${Exclamation} {
      display: inline-block;
    }
  }`}
`

interface YieldTooltipProps {
  interestRateParts: InterestRatePart[]
  lendingYield: Percent | undefined
  totalSupplyYield: Percent | undefined
  showYieldAsApr: boolean
  isBorrowRate: boolean
  leverage?: number
  includeRates?: boolean
  rateParts?: string[]
  newRate?: Percent
  pendleFixedRate?: Fraction
}

export const YieldTooltip = ({
  interestRateParts,
  lendingYield,
  showYieldAsApr,
  isBorrowRate,
  leverage,
  includeRates,
  rateParts,
  newRate,
  pendleFixedRate,
}: YieldTooltipProps) => {
  return (
    <TooltipText>
      {lendingYield && !lendingYield.equalTo(ZERO_PERCENT) && (
        <TooltipLine>
          <TooltipLineTitle>
            {isBorrowRate ? 'Borrow' : 'Lending'} {showYieldAsApr ? 'APR' : 'APY'}:{' '}
          </TooltipLineTitle>
          <StyledTooltip
            title={
              leverage
                ? `Base rate of ${(newRate ?? lendingYield).toFixed(2)}% with a leverage of ${leverage}x`
                : undefined
            }
            placement={'top'}
          >
            <TooltipLineValue>
              {formatAmount((newRate ?? lendingYield).multiply(leverage ? leverage : 1))}
            </TooltipLineValue>
          </StyledTooltip>
        </TooltipLine>
      )}
      {interestRateParts.map((part, index) => {
        const disabled = includeRates && !rateParts?.includes(part.label)
        if (!part.interestRate) {
          return (
            <TooltipLine key={index}>
              <TooltipLineTitle disabled={disabled}>
                {leverage && part.metadata
                  ? `+${part.metadata.units * leverage} ${part.metadata.unitsLabel}`
                  : part.label}
              </TooltipLineTitle>
            </TooltipLine>
          )
        }
        return (
          <TooltipLine key={index}>
            <TooltipLineTitle disabled={disabled}>{part.label}: </TooltipLineTitle>
            <StyledTooltip
              title={
                leverage
                  ? `Base rate of ${
                      part.label.includes('Pendle Fixed') && pendleFixedRate
                        ? Percent.fromFraction(pendleFixedRate.asFraction).toFixed(2)
                        : part.interestRate.toFixed(2)
                    }% with a leverage of ${leverage}`
                  : undefined
              }
              placement={'top'}
            >
              <TooltipLineValue disabled={disabled}>
                {formatAmount(
                  part.label.includes('Pendle Fixed') && pendleFixedRate
                    ? Percent.fromFraction(pendleFixedRate.asFraction).multiply(leverage ? leverage : 1)
                    : part.interestRate.multiply(leverage ? leverage : 1),
                )}
              </TooltipLineValue>
            </StyledTooltip>
            {part.label.startsWith('oARB Rewards') && (
              <OArbRewardsBreakdown disabled={disabled}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>100% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>50% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(2))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>25% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(4))}</div>
                </div>
              </OArbRewardsBreakdown>
            )}
            {part.label.startsWith('goARB Rewards') && (
              <OArbRewardsBreakdown disabled={disabled}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>100% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>20% (instant vest):</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(5))}</div>
                </div>
              </OArbRewardsBreakdown>
            )}
          </TooltipLine>
        )
      })}
    </TooltipText>
  )
}
const ExpandedContentWrapper = styled.div``

export interface TokenRowProps extends Attributes {
  currencyOrToken: Currency | Token
  interestRateData: Record<string, InterestRate | undefined>
  dolomiteBalanceData: Record<string, CurrencyAmount<Token> | undefined>
  dolomiteFiatData: Record<string, Fraction | undefined>
  walletBalanceData: Record<string, CurrencyAmount<Currency> | undefined>
  walletFiatData: Record<string, Fraction | undefined>
  hasRewards: boolean
  hasPowder: boolean
  hasRewardStation: boolean
  hasMinerals: boolean
  hasPoints: boolean
  expanded: boolean
  isBorrowingEnabled: boolean
  docsLink: string | undefined
  setExpanded: () => void
  expandedContent: JSX.Element | undefined
  setPopoverComponent: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
  setCustomPopoverWidth?: (width: number) => void
  showYieldAsApr: boolean
  depositModal: (presetCurrency?: Currency) => void
  withdrawModal: (presetCurrency?: Currency) => void
}

export const TokenRow = ({
  currencyOrToken,
  interestRateData,
  dolomiteBalanceData,
  dolomiteFiatData,
  walletBalanceData,
  walletFiatData,
  hasRewards,
  hasPowder,
  hasRewardStation,
  hasMinerals,
  hasPoints,
  expanded,
  isBorrowingEnabled,
  docsLink,
  setExpanded,
  expandedContent,
  setPopoverComponent,
  setCustomPopoverWidth,
  showYieldAsApr,
  depositModal,
  withdrawModal,
}: TokenRowProps) => {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = currencyOrToken.wrapped.address
  const remappedTokenAddress = mapExternalTokenToListedAddress(currencyOrToken.wrapped)
  const history = useHistory()
  const lendingSupplyInterestRate = interestRateData[remappedTokenAddress]?.supplyInterestRate
  const totalSupplyInterestRate = interestRateData[remappedTokenAddress]?.totalSupplyInterestRate
  const tokenInterestRateParts = interestRateData[remappedTokenAddress]?.outsideSupplyInterestRateParts
  const currencyKey = currencyOrToken.isNative ? currencyOrToken.symbol ?? '' : currencyOrToken.address
  const handleOpenDeposit = useCallback(() => {
    depositModal(currencyOrToken)
  }, [depositModal, currencyOrToken])
  const handleOpenWithdraw = useCallback(() => {
    withdrawModal(currencyOrToken)
  }, [currencyOrToken, withdrawModal])
  //test
  return (
    <EarnRow
      key={`lending-${currencyKey}`}
      hasExpandedContent={!!expandedContent}
      expanded={expanded}
      onClick={() => setExpanded()}
    >
      <TickerColumn>
        <div>
          <TokenLogo>
            <img
              src={getLogoOrDefault(cleanCurrencySymbol(currencyOrToken, false) ?? '')}
              alt={`${currencyOrToken.symbol} logo`}
            />
          </TokenLogo>
          <TickerWrapper onClick={() => history.push(`/stats/token/${chainId}/${tokenAddress}`)} pointer>
            <Ticker>
              {cleanCurrencySymbol(currencyOrToken, false)}
              <TickerIcons>
                {!isBorrowingEnabled && <NoTradingIcon />}
                {docsLink && (
                  <StyledTooltip title={'Link to the documentation on this asset'}>
                    <a href={docsLink} target={'_blank'} rel={'noreferrer'} onClick={e => e.stopPropagation()}>
                      <ImportContactsIcon fontSize={'small'} />
                    </a>
                  </StyledTooltip>
                )}
              </TickerIcons>
            </Ticker>
            <Name>{cleanCurrencyName(currencyOrToken, false)}</Name>
          </TickerWrapper>
        </div>
      </TickerColumn>
      <RateColumn>
        {tokenInterestRateParts && tokenInterestRateParts.length > 0 ? (
          <StyledTooltip
            title={
              <YieldTooltip
                interestRateParts={tokenInterestRateParts}
                lendingYield={lendingSupplyInterestRate}
                totalSupplyYield={totalSupplyInterestRate}
                showYieldAsApr={showYieldAsApr}
                isBorrowRate={false}
              />
            }
            placement={'top'}
            arrow={true}
          >
            <OutsideRateWrapper>
              {totalSupplyInterestRate
                ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                  ? '< 0.01%'
                  : formatAmount(totalSupplyInterestRate)
                : '-'}
              <StyledAutoAwesomeIcon />
            </OutsideRateWrapper>
          </StyledTooltip>
        ) : (
          <RateWrapper>
            {totalSupplyInterestRate
              ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                ? '< 0.01'
                : totalSupplyInterestRate.toFixed(2)
              : '-'}
            %
          </RateWrapper>
        )}
        {hasPowder ? (
          <StyledTooltip
            title={'This asset earns Mantle Powder. Visit https://meth.mantle.xyz/campaigns for more information.'}
            placement={'top'}
          >
            <RewardsText>+20x Mantle Powder</RewardsText>
          </StyledTooltip>
        ) : hasRewardStation ? (
          <StyledTooltip
            title={
              'This asset earns rewards from the Mantle Rewards Station. Visit https://rewards.mantle.xyz for more information.'
            }
            placement={'top'}
          >
            <RewardsText longText>+Mantle Ecosystem Rewards</RewardsText>
          </StyledTooltip>
        ) : hasMinerals ? (
          <StyledTooltip
            title={'This asset earns Dolomite Minerals. Visit the Minerals page to view more information.'}
            placement={'top'}
          >
            <RewardsText>+Minerals</RewardsText>
          </StyledTooltip>
        ) : hasPoints ? (
          <StyledTooltip
            title={`This asset earns points that can be tracked on the project's web app.`}
            placement={'top'}
          >
            <RewardsText>+Points</RewardsText>
          </StyledTooltip>
        ) : (
          hasRewards && (
            <StyledTooltip
              title={
                'This asset accumulates additional rewards. Hover your mouse over the blue APR to see a breakdown.'
              }
              placement={'top'}
            >
              <RewardsText>+Rewards</RewardsText>
            </StyledTooltip>
          )
        )}
      </RateColumn>
      <BalanceColumn>
        {dolomiteBalanceData?.[tokenAddress] && dolomiteFiatData[tokenAddress] ? (
          <div>
            <StyledTooltip
              title={dolomiteBalanceData[tokenAddress]?.toFixed(Math.min(currencyOrToken.decimals, 8), FORMATTER)}
              placement={'right'}
            >
              <Balance>
                {dolomiteBalanceData[tokenAddress]?.greaterThan(ZERO_FRACTION) &&
                formatAmount(dolomiteBalanceData[tokenAddress]) === '0.000000'
                  ? '< 0.000001'
                  : formatAmount(dolomiteBalanceData[tokenAddress])}
              </Balance>
            </StyledTooltip>
            <DollarBalance>{'$' + dolomiteFiatData[tokenAddress]?.toFixed(2, FORMATTER)}</DollarBalance>
          </div>
        ) : (
          <div>
            {/* TODO - if the balance is above a certain number of characters long (around 10), have it apply another css class that reduces the padding on the whole thing from 35px -> 20px so it will fit*/}
            <Balance>0.0000</Balance>
            <DollarBalance>$0.00</DollarBalance>
          </div>
        )}
      </BalanceColumn>
      <BalanceColumn>
        {walletBalanceData?.[currencyKey] && walletFiatData[currencyKey] ? (
          <div>
            <StyledTooltip
              title={walletBalanceData[currencyKey]?.toFixed(Math.min(currencyOrToken.decimals, 8), FORMATTER)}
              placement={'right'}
            >
              <Balance>
                {walletBalanceData[currencyKey]?.greaterThan(ZERO_FRACTION) &&
                formatAmount(walletBalanceData[currencyKey]) === '0.000000'
                  ? '< 0.000001'
                  : formatAmount(walletBalanceData[currencyKey])}
              </Balance>
            </StyledTooltip>
            <DollarBalance>{'$' + walletFiatData[currencyKey]?.toFixed(2, FORMATTER)}</DollarBalance>
          </div>
        ) : (
          <div>
            {/* TODO - if the balance is above a certain number of characters long (around 10), have it apply another css class that reduces the padding on the whole thing from 35px -> 20px so it will fit*/}
            <Balance>0.0000</Balance>
            <DollarBalance>$0.00</DollarBalance>
          </div>
        )}
      </BalanceColumn>
      <DepositWithdrawColumn>
        <StyledTooltip title={`Deposit ${cleanCurrencySymbol(currencyOrToken, false)}`} placement={'top'}>
          <div onClick={handleOpenDeposit}>
            <AddCircleIcon />
          </div>
        </StyledTooltip>
        <StyledTooltip title={`Withdraw ${cleanCurrencySymbol(currencyOrToken, false)}`} placement={'top'}>
          <div onClick={handleOpenWithdraw}>
            <RemoveCircleOutlineIcon />
          </div>
        </StyledTooltip>
      </DepositWithdrawColumn>
      <ButtonsColumn />
      {!!expandedContent && (
        <BottomSection>
          <ExpandedContentWrapper>
            {React.cloneElement(expandedContent, {
              setPopoverComponent,
              setCustomPopoverWidth,
            })}
          </ExpandedContentWrapper>
        </BottomSection>
      )}
    </EarnRow>
  )
}

const initialExpandedMap = Object.values(SpecialAssetSymbol).reduce<Record<string, boolean>>((memo, symbol) => {
  memo[symbol] = !!CUSTOM_BOTTOM_SECTION_COMPONENT_MAP[symbol]
  return memo
}, {})

enum SortField {
  DolomiteBalance = 'DolomiteBalance',
  WalletBalance = 'WalletBalance',
  InterestRate = 'InterestRate',
  Name = 'Name',
}

export default function BalancesPanel() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const ether = useEther()
  const allTokenList = useAllActiveTokensArray()
  const allTokenListWithExternalTokens = useAllActiveTokensArrayWithExternalTokens()
  const allCurrencyList = useMemo(() => [ether, ...allTokenListWithExternalTokens], [
    ether,
    allTokenListWithExternalTokens,
  ])
  const [didSlide, setDidSlide] = useState(false)

  const [rawDolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, allTokenList)
  const dolomiteBalanceData = useAddExternalTokensToDolomiteData(rawDolomiteBalanceData)

  const [rawDolomiteFiatBalanceData] = useFiatValuesWithLoadingIndicator(
    dolomiteBalanceData,
    allTokenListWithExternalTokens,
  )
  const dolomiteFiatBalanceData = useAddExternalTokensToDolomiteData(rawDolomiteFiatBalanceData)

  const ethBalance = useCurrencyBalance(account, ether)
  const rawWalletBalanceMap = useTokenBalances(account, allTokenListWithExternalTokens)
  const walletFiatBalanceMap = useFiatValuesWithExternalAssetsMap(
    rawWalletBalanceMap,
    allTokenListWithExternalTokens,
    ethBalance,
  )
  const [priceBalanceMap] = useFiatPricesWithLoadingIndicator(allTokenListWithExternalTokens)
  const walletBalanceMap = useAddEtherBalanceToWalletBalances(ethBalance, rawWalletBalanceMap)

  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const [expandedRowMap, setExpandedRowMap] = useState<Record<string, boolean | undefined>>(initialExpandedMap)
  const [popoverComponent, setPopoverComponent] = useState<JSX.Element | undefined>(undefined)
  const [customPopoverWidth, setCustomPopoverWidth] = useState<number | undefined>(undefined)
  const [sortField, setSortField] = useState<SortField>(SortField.DolomiteBalance)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [hideZeroBalances, setHideZeroBalances] = useUserHideZeroBalances()
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [filterSelectOpen, setFilterSelectOpen] = useState<boolean>(false)
  const filterTypes = useFilterTypes()
  const [tokenFilterType, setTokenFilterType] = useState<FilterType>(FilterType.NONE)
  const isDepositModalOpen = useModalOpen(ApplicationModal.DEPOSIT_WITHDRAW)
  const toggleShowDepositModal = useToggleModal(ApplicationModal.DEPOSIT_WITHDRAW)
  const [isWithdrawModal, setWithdrawModal] = useState<boolean>(false)
  const [depositModalCurrency, setDepositModalCurrency] = useState<Currency | undefined>(undefined)
  const node = useRef<HTMLDivElement>()
  const isSwapModalOpen = useModalOpen(ApplicationModal.SWAP)
  const toggleShowSwapModal = useToggleModal(ApplicationModal.SWAP)

  const [gmxRewards] = useGmxPendingRewards()
  const [jUSDCRewards] = useJUsdcPendingRewards()
  const [plvRewards] = usePlvGlpPendingRewards()
  const totalGmxRewards = useMemo(
    () =>
      gmxRewards?.totalNativeTokenRewardsUsd
        .add(gmxRewards?.totalVesterRewardsUsd)
        .add(gmxRewards?.extendedGmxTokenRewards),
    [gmxRewards],
  )
  const RewardValues: Record<string, Fraction | undefined> = useMemo(() => {
    return {
      [SpecialAssetSymbol.dGMX]: totalGmxRewards,
      [SpecialAssetSymbol.dfsGLP]: totalGmxRewards,
      [SpecialAssetSymbol.djUSDC]: jUSDCRewards,
      [SpecialAssetSymbol.dplvGLP]: plvRewards,
    }
  }, [totalGmxRewards, jUSDCRewards, plvRewards])

  const { loading: isInterestRateMapLoading, data: interestRateMap } = useInterestRateData()

  /* TODO - split color in top half vs bottom half */

  /* TODO - update styling on tooltips */

  const setExpandedCallback = useCallback((token: Token) => {
    setExpandedRowMap(prevMap => {
      return {
        ...prevMap,
        [token.symbol ?? '']: !prevMap[token.symbol ?? ''],
      }
    })
  }, [])

  const handleSort = useCallback(
    (newField: SortField) => {
      setSortField(newField)
      setSortDirection(oldSortDirection => {
        if (sortField !== newField) {
          // Reset it. For name fields, we want to default to ascending
          return newField !== SortField.Name
        }
        // Flip it
        return !oldSortDirection
      })
    },
    [sortField],
  )

  const getArrow = useCallback(
    (field: SortField) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const selectFilter = (field: FilterType) => {
    setTokenFilterType(field)
    setFilterSelectOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target as Node)) {
      setFilterSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  useEffect(() => {
    setTokenFilterType(FilterType.NONE)
  }, [chainId])

  const filteredTokens = useMemo(() => {
    return filterTokens(allCurrencyList, tokenFilterType, inputValue, chainId)
  }, [inputValue, allCurrencyList, tokenFilterType, chainId])

  const depositModal = useCallback(
    (presetCurrency?: Currency) => {
      setDepositModalCurrency(presetCurrency)
      setWithdrawModal(false)
      toggleShowDepositModal()
    },
    [toggleShowDepositModal],
  )
  const withdrawModal = useCallback(
    (presetCurrency?: Currency) => {
      setDepositModalCurrency(presetCurrency)
      setWithdrawModal(true)
      toggleShowDepositModal()
    },
    [toggleShowDepositModal],
  )

  const handleOpenDepositModal = useCallback(() => {
    depositModal()
  }, [depositModal])

  const handleOpenWithdrawModal = useCallback(() => {
    withdrawModal()
  }, [withdrawModal])

  const handleCloseDepositModal = useCallback(() => {
    toggleShowDepositModal()
    setDepositModalCurrency(undefined)
  }, [toggleShowDepositModal])

  const sortedCurrencies = useMemo(() => {
    const nativeSymbol = Ether.onChain(chainId).symbol ?? ''
    return [...filteredTokens].sort((a: Currency, b: Currency) => {
      const symbolA = cleanCurrencySymbol(a, false)?.toUpperCase() ?? ''
      const symbolB = cleanCurrencySymbol(b, false)?.toUpperCase() ?? ''
      const addressA = nativeSymbol === cleanCurrencySymbol(a, false) ? nativeSymbol : a.wrapped.address
      const addressB = nativeSymbol === cleanCurrencySymbol(b, false) ? nativeSymbol : b.wrapped.address
      const aRewards = RewardValues[a.symbol ?? ''] ?? ZERO_FRACTION
      const aDolomiteFiat = dolomiteFiatBalanceData[addressA] ?? ZERO_FRACTION
      const bDolomiteFiat = dolomiteFiatBalanceData[addressB] ?? ZERO_FRACTION
      const aWalletFiat = walletFiatBalanceMap[addressA] ?? ZERO_FRACTION
      const bWalletFiat = walletFiatBalanceMap[addressB] ?? ZERO_FRACTION
      const interestRateA = interestRateMap[addressA]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const interestRateB = interestRateMap[addressB]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const sort = sortDirection ? 1 : -1

      const symbolSorter = symbolA.localeCompare(symbolB) < 0 ? -1 : 1
      const interestRateSorter = interestRateA.lessThan(interestRateB) ? 1 : -1

      if (sortField === SortField.Name) {
        return symbolA === symbolB ? interestRateSorter : symbolSorter * -sort
      } else if (sortField === SortField.InterestRate) {
        return interestRateA.equalTo(interestRateB) ? symbolSorter : interestRateSorter * sort
      } else if (sortField === SortField.DolomiteBalance) {
        if (aRewards.greaterThan(ZERO_FRACTION)) {
          const aPrice = priceBalanceMap[a.wrapped.address ?? ''] ?? ZERO_FRACTION
          const aRewardsFiat =
            SpecialAssetSymbol.dfsGLP === a.symbol || SpecialAssetSymbol.dGMX === a.symbol
              ? aRewards
              : aRewards.multiply(aPrice)
          if (aRewardsFiat.greaterThan(ONE_FRACTION)) return -1
        }
        if (aDolomiteFiat.equalTo(ZERO_FRACTION) && bDolomiteFiat.equalTo(ZERO_FRACTION)) {
          if (aRewards.greaterThan(ZERO_FRACTION)) return -sort
          return aWalletFiat.equalTo(bWalletFiat) ? symbolSorter : (aWalletFiat.lessThan(bWalletFiat) ? 1 : -1) * sort
        } else {
          return (aDolomiteFiat.lessThan(bDolomiteFiat) ? 1 : -1) * sort
        }
      } else {
        invariant(sortField === SortField.WalletBalance, `Invalid sort field, found ${sortField}`)
        if (aWalletFiat.equalTo(ZERO_FRACTION) && bWalletFiat.equalTo(ZERO_FRACTION)) {
          return aDolomiteFiat.equalTo(bDolomiteFiat)
            ? symbolSorter
            : (aDolomiteFiat.lessThan(bDolomiteFiat) ? 1 : -1) * sort
        } else {
          return (aWalletFiat.lessThan(bWalletFiat) ? 1 : -1) * sort
        }
      }
    })
  }, [
    sortField,
    sortDirection,
    chainId,
    filteredTokens,
    dolomiteFiatBalanceData,
    walletFiatBalanceMap,
    interestRateMap,
    RewardValues,
    priceBalanceMap,
  ])

  return (
    <LendingPanelWrapper ref={node as any}>
      {chainId === ChainId.BERACHAIN && (
        <>
          <ClimbingBear didSlide={didSlide} onClick={() => setDidSlide(true)} src={ClimbingBearImg} />
          <Exclamation>!</Exclamation>
        </>
      )}
      <ZapModal open={isSwapModalOpen} closeZap={toggleShowSwapModal} />
      <DepositWithdrawModal
        open={isDepositModalOpen}
        close={handleCloseDepositModal}
        isUnwrapInitiallySelected={isExternalToken(depositModalCurrency?.wrapped)}
        isWithdraw={isWithdrawModal}
        presetCurrency={
          isWithdrawModal ? mapExternalCurrencyOptToListedToken(depositModalCurrency) : depositModalCurrency
        }
      />
      {popoverComponent && (
        <Modal
          isOpen={!!popoverComponent}
          onDismiss={() => {
            setPopoverComponent(undefined)
            setCustomPopoverWidth(undefined)
          }}
          maxHeight={90}
          maxWidthPx={customPopoverWidth ?? 400}
        >
          {popoverComponent}
        </Modal>
      )}
      <TopRow>
        <FilterSection>
          <InputWrapper>
            <InputOverflowFix>
              <StyledInput
                onChange={(e: any) => setInputValue(e.target.value)}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'Search for Token'}
                value={inputValue}
                variant=''
                disableUnderline={true}
                endAdornment={''}
              />
            </InputOverflowFix>
            <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
              <Close />
            </Clear>
          </InputWrapper>
          <FilterSelect
            expanded={filterSelectOpen}
            ref={wrapperRef}
            onClick={() => !filterSelectOpen && setFilterSelectOpen(true)}
          >
            {!filterSelectOpen && (
              <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>
                {tokenFilterType === FilterType.NONE ? 'Filter' : tokenFilterType}
              </FilterSelectRow>
            )}
            {filterTypes
              .filter(filter => filter !== tokenFilterType)
              .map((filter, index) => {
                return (
                  <FilterSelectRow key={`filter-dropdown-${index}`} onClick={() => selectFilter(filter as FilterType)}>
                    {filter}
                  </FilterSelectRow>
                )
              })}
            {filterSelectOpen && (
              <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>{tokenFilterType}</FilterSelectRow>
            )}
            <ArrowDown />
          </FilterSelect>
        </FilterSection>
        <ZeroBalances visible={/*!!account*/ true} onClick={() => setHideZeroBalances(!hideZeroBalances)}>
          <Checkbox>
            <Checked isChecked={hideZeroBalances} />
          </Checkbox>
          Hide Zero Balances
        </ZeroBalances>
        <AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
          <AprSelector active={showYieldAsApr}>APR</AprSelector>
          <AprSelector active={!showYieldAsApr}>APY</AprSelector>
        </AprSelect>
        <Button onClick={toggleShowSwapModal}>
          <ButtonText>Swap</ButtonText> <BoltIcon />
        </Button>
        <DepositWithdrawButtons>
          <DepositWithdrawButton onClick={handleOpenDepositModal}>
            <ButtonText>Deposit</ButtonText>
          </DepositWithdrawButton>
          <DepositWithdrawButton onClick={handleOpenWithdrawModal}>
            <ButtonText>Withdraw</ButtonText>
          </DepositWithdrawButton>
        </DepositWithdrawButtons>
      </TopRow>
      <div>
        <ColumnHeaders>
          <TickerColumn onClick={() => handleSort(SortField.Name)}>
            <StyledTooltip title={t('assetFromWallet')} placement={'top'}>
              <span>
                {t('token')} {getArrow(SortField.Name)}
              </span>
            </StyledTooltip>
          </TickerColumn>
          <RateColumn onClick={() => handleSort(SortField.InterestRate)}>
            <StyledTooltip title={t('interestFromAsset')} placement={'top'}>
              <span>
                {showYieldAsApr ? t('apr') : t('apy')} {getArrow(SortField.InterestRate)}
              </span>
            </StyledTooltip>
          </RateColumn>
          <BalanceColumn onClick={() => handleSort(SortField.DolomiteBalance)} slideRight>
            <span>
              <HideOnSmall>{t('dolomite')} </HideOnSmall>
              {t('balanceText')} {getArrow(SortField.DolomiteBalance)}
            </span>
            <TickerIcons>
              <StyledTooltipWithIcon tooltipText={t('availableBalance')} placement={'top'} />
            </TickerIcons>
          </BalanceColumn>
          <BalanceColumn onClick={() => handleSort(SortField.WalletBalance)}>
            <span>
              {t('inWallet')} {getArrow(SortField.WalletBalance)}
            </span>
          </BalanceColumn>
          <ButtonsColumn />
        </ColumnHeaders>
        {!isInterestRateMapLoading || interestRateMap ? (
          sortedCurrencies.length > 0 ? (
            sortedCurrencies.map(currency => {
              const specialAsset = getSpecialAsset(currency.chainId, currency.wrapped)
              const tokenAddress = mapExternalTokenToListedAddress(currency.wrapped)
              const interestRateParts = interestRateMap[tokenAddress]?.outsideSupplyInterestRateParts
              const hasRewards =
                hasExternalRewards(chainId, interestRateParts) || (specialAsset?.hasExternalRewards ?? false)
              const hasPowder = interestRateParts?.some(part => /^.*Powder/.test(part.label)) ?? false
              const hasRewardStation =
                interestRateParts?.some(part => part.label.includes('Mantle Ecosystem Rewards')) ?? false
              const hasMinerals = interestRateParts?.some(part => /^.*Minerals/.test(part.label)) ?? false
              const hasPoints = interestRateParts?.some(part => /^\+.*Points/.test(part.label)) ?? false
              const isBorrowingEnabled = marketRiskInfoMap && marketRiskInfoMap[tokenAddress]?.isBorrowingDisabled
              if (
                hideZeroBalances &&
                !dolomiteBalanceData[tokenAddress]?.greaterThan(ZERO_FRACTION) &&
                !RewardValues[currency.symbol ?? '']?.greaterThan(0)
              ) {
                return null
              }
              return (
                <TokenRow
                  key={currency.isNative ? 'NATIVE' : currency.address}
                  currencyOrToken={currency}
                  interestRateData={interestRateMap}
                  dolomiteBalanceData={dolomiteBalanceData}
                  dolomiteFiatData={dolomiteFiatBalanceData}
                  walletBalanceData={walletBalanceMap}
                  walletFiatData={walletFiatBalanceMap}
                  hasRewards={hasRewards}
                  hasPowder={hasPowder}
                  hasRewardStation={hasRewardStation}
                  hasMinerals={hasMinerals}
                  hasPoints={hasPoints}
                  expanded={expandedRowMap[currency.symbol ?? ''] ?? false}
                  isBorrowingEnabled={specialAsset ? specialAsset.isBorrowingEnabled : isBorrowingEnabled ?? false}
                  docsLink={specialAsset?.documentationUrl}
                  setExpanded={() =>
                    initialExpandedMap[specialAsset?.symbol ?? ''] ? setExpandedCallback(currency.wrapped) : undefined
                  }
                  expandedContent={CUSTOM_BOTTOM_SECTION_COMPONENT_MAP[currency.symbol ?? '']}
                  setPopoverComponent={setPopoverComponent}
                  setCustomPopoverWidth={setCustomPopoverWidth}
                  showYieldAsApr={showYieldAsApr}
                  depositModal={depositModal}
                  withdrawModal={withdrawModal}
                />
              )
            })
          ) : (
            <NoTokens>{t('noTokens')}</NoTokens>
          )
        ) : (
          <LoadingRates>{t('loadingRates')}</LoadingRates>
        )}
      </div>
    </LendingPanelWrapper>
  )
}
