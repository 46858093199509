import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import Input from '@material-ui/core/Input'
import { Currency, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import CircularProgress from '@material-ui/core/CircularProgress'
import { tryParseAmount } from '../../state/trade/hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useTranslation } from 'react-i18next'
import { ChainId, ONE_BIPS, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import ReactGA from 'react-ga'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import CurrencyModal from '../../components/CurrencyModal'
import { useDolomiteBalancesWithLoadingIndicator, useTokenBalances } from '../../state/wallet/hooks'
import BoltIcon from '@mui/icons-material/Bolt'
import { useZapExactTokensForTokens, ZapType } from '../../hooks/useExecuteZap'
import { useDolomiteMarginContract } from '../../hooks/useContract'
import { useGetZapExactTokensForTokensParams } from '../../hooks/useGetZapParams'
import BorrowTradeDetails from './BorrowPosition/BorrowTradeDetails'
import { formatAmount } from '../../utils/formatAmount'
import { useCreateVaultIsolationModeVault } from '../../hooks/useDolomiteIsolationModeProtoocol'
import { useMarketsTotalWeiData } from '../../hooks/useDolomiteMarginProtocol'
import useIsolationModeUserVaultAddressIfCreated from '../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useIsTransactionPending, useIsTransactionSuccessful } from '../../state/transactions/hooks'
import Modal from '../../components/Modal'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useInterestRateData } from '../../types/interestRateData'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { YieldTooltip } from '../../components/Balances/BalancesPanel'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { X } from 'react-feather'
import { NewValue, NewValueWrapper } from './BorrowPosition/BorrowExpandedContent'
import ArrowRight from '@material-ui/icons/ArrowForward'
import { ReactComponent as ShieldIcon } from '../../assets/images/shieldIcon.svg'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import { useAllActiveTokensArray, useSerializedTokens } from '../../hooks/Tokens'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import VpnLock from '@material-ui/icons/VpnLock'
import { NETWORK_LABELS } from '../../constants/chainId'

const NewBorrowWrapper = styled.div<{
  showTradeDetails: boolean
  tall: boolean
  extraTall: boolean
  disabled: boolean
}>`
  width: 100%;
  height: 100%;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 24px 32px;
  position: relative;
  transition: ${({ tall }) => (tall ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s')};
  text-align: left;
`

const DisabledOverlay = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 40px 40px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(61, 62, 84, 0.75);
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OpenText = styled.div`
  font-size: 22px;
  font-weight: 100;
`

const SubtitleText = styled.div`
  color: ${({ theme }) => theme.text6};
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 6px;
`

const DescriptionText = styled.div<{
  visible: boolean
  bottom?: boolean
  extraTall?: boolean
  extraExtraTall?: boolean
  extraMargin?: boolean
}>`
  font-size: 12px;
  font-weight: 100;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  top: ${({ bottom, extraTall, extraExtraTall }) =>
    bottom ? (extraTall ? (extraExtraTall ? 163 : 149) : extraExtraTall ? 163 : 97) : 51}px;
  left: 35px;
  color: ${({ theme }) => theme.text2};
  width: calc(100% - 64px);
  transition: ${({ visible }) => (visible ? 'opacity 0.3s ease-in-out 0.2s' : 'opacity 0.3s ease-in-out')};

  ${({ bottom }) =>
    bottom &&
    `
      width: calc(100% - 71px);
      background: #2f2f40;
      padding: 6px;
      border-radius: 5px;
      padding-left: 35px;
  `}
  svg {
    margin-right: 2px;
    margin-bottom: -1px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    position: absolute;
    left: 6px;
    top: 8px;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 660px) {
    top: ${({ bottom, extraTall }) => (bottom ? (extraTall ? 145 : 85) : 55)}px;
  }

  @media screen and (max-width: 480px) {
    left: 32px;
    font-size: 10px;
    line-height: 12px;
    margin-top: ${({ extraMargin }) => (extraMargin ? 6 : 2)}px;
    top: ${({ bottom, extraTall }) => (bottom ? (extraTall ? 145 : 85) : 55)}px;
  }
`

const DescriptionTextTall = styled(DescriptionText)`
  line-height: 28px;

  @media screen and (max-width: 480px) {
    line-height: 24px;
  }
`

const BalanceRow = styled.div`
  width: 100%;
  font-size: 13px;
  margin-bottom: 1px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

const BalanceWrapper = styled.div`
  display: block;
  cursor: auto;
  text-align: left;
`

const MaxButton = styled.div<{ disabled: boolean }>`
  align-self: flex-end;
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  vertical-align: bottom;

  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.text3 : theme.text2)};
  }
`

const NewAmountWrapper = styled.div`
  display: inline-block;
`

const Balance = styled.span`
  font-weight: 200;
`

const Ticker = styled.span`
  font-weight: 200;
  margin-left: 3px;
`

const InputWrapper = styled.div<{ tall: boolean; extraTall: boolean }>`
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  margin-top: ${({ extraTall, tall }) => (extraTall ? '120px' : tall ? '70px' : '10px')};
  transition: ${({ tall }) => (tall ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s')};
  pointer-events: all;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:nth-child(2) {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div:nth-child(2) > div {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 660px) {
    margin-top: ${({ extraTall, tall }) => (extraTall ? '190px' : tall ? '127px' : '10px')};
  }
`

const ZapInputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:first-child {
    margin-top: 0;
    padding-top: 0;
    /*margin-bottom: 0 !important;
            padding-bottom: 0 !important;*/
  }

  > div:first-child > div {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const TokenSelector = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  height: 33px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  width: fit-content;
  z-index: 1;
  text-align: left;

  ${({ expanded }) =>
    expanded &&
    `
    /*border-top-left-radius: 4px;*/
    height: fit-content;
  `}
`

const TokenSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 25px 5px 10px;
  height: 33px;

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
  position: relative;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    input {
      background: #262535 !important;
    }
  `}

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};

  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

const BottomRowWrapper = styled.div`
  text-align: right;

  @media screen and (max-width: 660px) {
    bottom: 24px;
  }

  @media screen and (max-width: 480px) {
    right: 32px;
  }
`

const DisclaimerWrapper = styled.div`
    display: inline-block;
    color: ${({ theme }) => theme.text3};
    font-size: 9px;
    line-height: 11px;
    max-width: 51%;
    pointer-events: all;

    a {
        color: ${({ theme }) => theme.text3};
        text-decoration: none;
        font-weight: 700;
    }

    @media screen and (max-width: 660px) {
        max-width: calc(100% - 64px);
        text-align: center;
`

const DepositedButtonWrapper = styled.div`
  margin-top: 0;
`

const DepositedValuesWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  margin-right: 8px;
`

const DepositedCrypto = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.text1};
  font-weight: 100;
`

const DepositedUSD = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  font-weight: 100;
`

const SubmitButtonWrapper = styled.div<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? 140 : 90)}px;
  margin: 1px 0 0 0;
  display: inline-block;
  vertical-align: top;
  pointer-events: all;
`

const SubmitButton = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.green2};
  text-align: center;
  line-height: 36px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  :hover {
    background-color: ${({ theme }) => theme.green1};
  }

  > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 7px;
  }

  svg {
    color: white !important;
  }
`

const ZapButtonWrapper = styled.span`
  display: contents;
  vertical-align: top;
  font-weight: 700;

  svg {
    height: 20px;
    width: 16px;
    margin-right: -3px;
    margin-bottom: -5px;
    margin-left: -5px;
  }
`

const DetailsWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 10px;
`

const SwitchArrow = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text3};

  svg {
    color: ${({ theme }) => theme.text3};
    transition: color 0.1s ease-in-out;
  }

  + div > div > div:nth-of-type(1) {
    padding-top: 0;
  }
`

const SwitchArrowInner = styled.div`
  width: fit-content;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    transform: rotate(180deg);

    svg {
      color: ${({ theme }) => theme.text2};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    :hover {
      transform: none;
  
      svg {
        color: inherit;
      }
    }
  `};
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  display: inline !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 3px;
  margin-bottom: -1px;

  path {
    fill: ${({ theme }) => theme.blue2};
  }
`

const RateWrapper = styled.div<{ bottom?: boolean }>`
  font-size: 12px;
  text-align: left;
  width: 50%;
  margin-top: ${({ bottom }) => (bottom ? '-3' : '1')}px;
  cursor: default;
`

const OutsideRate = styled.span`
  color: ${({ theme }) => theme.blue2};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const WarningIconWrapper = styled.div`
  display: contents;
  vertical-align: top;
  font-weight: 600;

  svg {
    color: ${({ theme }) => theme.text2};
    height: 20px;
    width: 16px;
    margin-right: -1px;
    margin-bottom: -5px;
    margin-left: -5px;
  }
`

const ErrorMessage = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  color: ${({ theme }) => theme.red1};
  height: ${({ isVisible }) => (isVisible ? 'auto' : 0)};
  width: 100%;
  text-align: left;
  margin-top: -5px;
  margin-bottom: 5px;
`

const ErrorText = styled.div`
  font-size: 12px;
`

const IsolationModeExplainer = ({ visible }: { visible: boolean }) => {
  return (
    <DescriptionText visible={visible} bottom>
      <ShieldIcon />
      {`Isolation mode assets cannot be swapped to another isolation mode asset. `}
      <a href={'https://docs.dolomite.io/risk-mitigation#isolation-mode'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

export const GeoRestrictionExplainer = ({ visible }: { visible: boolean }) => {
  return (
    <DescriptionTextTall visible={visible} bottom>
      <VpnLock />
      {`This asset is not available in your region.`}
    </DescriptionTextTall>
  )
}

const GmFeeExplainer = ({ visible }: { visible: boolean }) => {
  return (
    <DescriptionText visible={visible} bottom>
      <ShieldIcon />
      {`GM and GLV zaps have a minimum trade size of $1 and require a small ETH fee to be paid to GMX relayers, paid from your wallet. `}
      <a href={'https://docs.dolomite.io/integrations/gmx/gm#fees'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

const VaultExplainer = ({
  visible,
  bothIso,
  gmExplainer,
}: {
  visible: boolean
  bothIso: boolean
  gmExplainer: boolean
}) => {
  return (
    <DescriptionText visible={visible} bottom extraTall={bothIso} extraExtraTall={gmExplainer}>
      <ShieldIcon />
      {`Vault creation is necessary for first time trades on isolation mode assets. `}
      <a href={'https://docs.dolomite.io/risk-mitigation#isolation-mode'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

function ZapModalComparator(prevProps: { open: boolean }, nextProps: { open: boolean }) {
  return prevProps.open === nextProps.open
}

const ZAP_EITHER = {
  zapType: ZapType.Swap,
  transfers: undefined,
}

interface ZapModalProps {
  open: boolean
  closeZap: () => void
  presetToken?: Token
}

const ZapModal = React.memo(function ZapModalContent({ open, closeZap, presetToken }: ZapModalProps) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const isInvalidChain = chainId === ChainId.X_LAYER
  const tokens = useAllActiveTokensArray()
  const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const [inputValue, setInputValue] = useState<string>('')
  const [zapInputValue, setZapInputValue] = useState('')
  const [selectedToken, setSelectedToken] = useState<Token>(tokens[0])
  const [tokenSelectOpen, setTokenSelectOpen] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const dolomiteBalanceUsdData = useMemo(() => {
    const map: Record<string, Fraction | undefined> = {}
    tokens.map(t => {
      const fiatValue = fiatValueMap[t.address]
      if (fiatValue) {
        map[t.address] = dolomiteBalanceData[t.address]?.asFraction.multiply(fiatValue)
      }
    })
    return map
  }, [tokens, fiatValueMap, dolomiteBalanceData])
  const [zapTokenSelectOpen, setZapTokenSelectOpen] = useState(false)
  const [selectedZapToken, setSelectedZapToken] = useState<Token>(tokens[1] ?? presetToken)
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [, setPendingHash] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [zapErrorMessage, setZapErrorMessage] = useState<string | undefined>(undefined)
  const selectedSpecialAsset = useSpecialAsset(selectedToken)
  const selectedZapSpecialAsset = useSpecialAsset(selectedZapToken)
  const { callback: createVaultCallback, error: createVaultError } = useCreateVaultIsolationModeVault(selectedZapToken)
  const isolationModeUserVaultAddress = useIsolationModeUserVaultAddressIfCreated(selectedZapToken)
  const selectedTokenIsolationMode = !!selectedSpecialAsset?.isIsolationMode ?? false
  const selectedZapTokenIsolationMode = !!selectedZapSpecialAsset?.isIsolationMode ?? false
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const isTxPending = useIsTransactionPending(txHash)
  const [, setTransactionPendingText] = useState('')
  const { data: interestRateMap } = useInterestRateData()
  const [showYieldAsApr] = useShowYieldAsApr()
  const [isMaxSelected, setIsMaxSelected] = useState(false)
  const dolomiteMarginContract = useDolomiteMarginContract()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const [marketTotalWeiMap] = useMarketsTotalWeiData()
  const isTokenGeoRestricted = useIsTokenGeoRestricted(selectedToken.wrapped.address)
  const isZapTokenGeoRestricted = useIsTokenGeoRestricted(selectedZapToken.wrapped.address)
  const isGeoRestricted = isTokenGeoRestricted || isZapTokenGeoRestricted

  const allowableInputTokens = useMemo(
    () => selectedZapSpecialAsset?.isolationModeInfo?.allowableInputTokensForZap?.(chainId),
    [chainId, selectedZapSpecialAsset],
  )
  const allowableOutputTokens = useMemo(
    () => selectedSpecialAsset?.isolationModeInfo?.allowableOutputTokensForZap?.(chainId),
    [chainId, selectedSpecialAsset],
  )

  useEffect(() => {
    if (inputValue === '') {
      setIsMaxSelected(false)
    }
  }, [inputValue])
  useEffect(() => {
    if (selectedToken) {
      setIsMaxSelected(false)
    }
  }, [selectedToken])

  useEffect(() => {
    setSelectedToken(inputToken => {
      if (
        allowableInputTokens &&
        allowableInputTokens.length > 0 &&
        !allowableInputTokens.some(t => t.equals(inputToken))
      ) {
        return allowableInputTokens[0]
      }
      return inputToken
    })
  }, [selectedZapSpecialAsset, allowableInputTokens])
  useEffect(() => {
    setSelectedZapToken(outputToken => {
      if (
        allowableOutputTokens &&
        allowableOutputTokens.length > 0 &&
        !allowableOutputTokens.some(t => t.equals(outputToken))
      ) {
        return allowableOutputTokens[0]
      }
      return outputToken
    })
  }, [selectedToken, allowableOutputTokens])

  const noVault = useMemo(() => {
    return selectedZapTokenIsolationMode && !isolationModeUserVaultAddress && !!zapInputValue
  }, [selectedZapTokenIsolationMode, isolationModeUserVaultAddress, zapInputValue])

  const ether = useMemo(() => Ether.onChain(chainId), [chainId])
  const inputCurrencyList = useMemo(() => {
    if (allowableInputTokens && selectedZapSpecialAsset) {
      return allowableInputTokens
    }
    return [ether, ...tokens]
  }, [ether, tokens, allowableInputTokens, selectedZapSpecialAsset])
  const outputCurrencyList = useMemo(() => {
    if (allowableOutputTokens && selectedSpecialAsset) {
      return allowableOutputTokens
    }
    return [ether, ...tokens]
  }, [ether, tokens, allowableOutputTokens, selectedSpecialAsset])
  const rawTokenList = useMemo(
    () =>
      inputCurrencyList.reduce<Token[]>((memo, currency) => {
        if (currency.isToken) {
          memo.push(currency.wrapped)
        }
        return memo
      }, []),
    [inputCurrencyList],
  )
  const tokenList = useSerializedTokens(rawTokenList)
  const protocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, tokenList)

  const closeModal = useCallback(() => {
    setTokenSelectOpen(false)
  }, [])
  const closeZapModal = useCallback(() => {
    setZapTokenSelectOpen(false)
  }, [])
  const selectToken = useCallback(
    (currency: Currency) => {
      const token = currency.wrapped
      if (selectedZapToken.address === token.address) {
        setSelectedZapToken(selectedToken)
      }
      setSelectedToken(token)
      setTokenSelectOpen(false)
    },
    [selectedToken, selectedZapToken],
  )

  useEffect(() => {
    setSelectedToken(tokens[0])
    setSelectedZapToken(tokens[1])
  }, [chainId, tokens])
  useEffect(() => {
    if (presetToken) {
      setSelectedZapToken(presetToken)
    }
  }, [presetToken])
  const selectZapToken = useCallback(
    (currency: Currency) => {
      const token = currency.wrapped
      if (selectedToken.address === token.address) {
        setSelectedToken(selectedZapToken)
      }
      setSelectedZapToken(token)
      setZapTokenSelectOpen(false)
      setZapInputValue('')
    },
    [selectedToken, selectedZapToken],
  )

  const onSwitchTokens = useCallback(() => {
    const prevToken = selectedToken
    setSelectedToken(selectedZapToken)
    setSelectedZapToken(prevToken)
  }, [selectedToken, selectedZapToken])

  const updateAdvancedInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^\d*(\.\d*)?$/ // Only allow numbers and a single decimal point

      if (e.target.value === '' || re.test(e.target.value)) {
        setZapInputValue(e.target.value)
      }
    },
    [setZapInputValue],
  )

  const updateInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      setInputValue(e.target.value)
      setIsMaxSelected(false)
      setErrorMessage(undefined)
    }
  }, [])

  const inputAmount = useMemo(() => {
    return tryParseAmount(inputValue, selectedToken)
  }, [inputValue, selectedToken])

  const bothIso = useMemo(() => {
    return selectedZapTokenIsolationMode && selectedTokenIsolationMode
  }, [selectedZapTokenIsolationMode, selectedTokenIsolationMode])

  const decimalsExceeded = useMemo(() => {
    return (inputValue.split('.')[1]?.length ?? 0) > (selectedToken.decimals ?? 0)
  }, [selectedToken, inputValue])

  const isInsufficientBalance = useMemo(() => {
    if (!inputAmount) {
      return true
    }

    const balance = dolomiteBalanceData[inputAmount.currency.address]
    return !balance || inputAmount.greaterThan(balance)
  }, [dolomiteBalanceData, inputAmount])

  const [, setNonce] = useState(0)

  const isTransactionSuccessful = useIsTransactionSuccessful(txHash)
  useEffect(() => {
    if (isTransactionSuccessful === false) {
      setTxHash(undefined)
      setNonce(prev => prev - 1)
    }
  }, [isTransactionSuccessful, setTxHash])

  const defaultMarginAccount = useDefaultMarginAccount()
  const selectedTokenDolomiteBalance = useMemo(() => {
    return selectedToken ? dolomiteBalanceData[selectedToken.address] : undefined
  }, [dolomiteBalanceData, selectedToken])
  const selectedZapTokenDolomiteBalance = useMemo(() => {
    return selectedZapToken ? dolomiteBalanceData[selectedZapToken.address] : undefined
  }, [dolomiteBalanceData, selectedZapToken])

  const parsedInputValue = useMemo(() => tryParseAmount(inputValue, selectedToken), [inputValue, selectedToken])
  const inputFiatValueAmount = useMemo(() => {
    const fiatValue = fiatValueMap[parsedInputValue?.currency.address ?? '']
    if (!parsedInputValue || !fiatValue) {
      return undefined
    }
    return parsedInputValue.asFraction.multiply(fiatValue)
  }, [fiatValueMap, parsedInputValue])
  const changedBalanceValue = useMemo(
    () => (parsedInputValue ? (selectedTokenDolomiteBalance ?? ZERO_FRACTION).subtract(parsedInputValue) : undefined),
    [selectedTokenDolomiteBalance, parsedInputValue],
  )
  const showGmExplainer = useMemo(() => {
    return (
      selectedToken.name?.substring(0, 3) === 'GM:' ||
      selectedZapToken.name?.substring(0, 3) === 'GM:' ||
      selectedToken.name?.substring(0, 3) === 'GLV' ||
      selectedZapToken.name?.substring(0, 3) === 'GLV'
    )
  }, [selectedToken, selectedZapToken])

  /*const gmFeeError = useMemo(() => {
    return showGmExplainer && userEthBalance && userEthBalance.lessThan(new Fraction(1, 1000))
  }, [showGmExplainer, userEthBalance])*/

  const minGmTradeError = useMemo(() => {
    return showGmExplainer && inputFiatValueAmount && inputFiatValueAmount.lessThan(1)
  }, [inputFiatValueAmount, showGmExplainer])
  const tall = isGeoRestricted || bothIso || showGmExplainer || noVault
  const extraTall = (bothIso || showGmExplainer) && noVault
  const subAccountNumber = useDefaultMarginAccount().accountNumber
  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    parsedInputValue,
    selectedZapToken,
    true,
    zapRefreshIncrementor,
    subAccountNumber.toString(),
    fiatValueMap,
  )

  const bestZap = useMemo(() => zaps?.[0], [zaps])
  const isLoadingZapTradeData =
    !!selectedZapToken && !!inputValue && !bestZap && !!parsedInputValue && parsedInputValue.greaterThan(0)
  useEffect(() => {
    if (bestZap && selectedZapToken) {
      const expectedAmountOut = CurrencyAmount.fromRawAmount(selectedZapToken, bestZap.expectedAmountOut.toFixed(0))
      setZapInputValue(expectedAmountOut.toFixed(selectedZapToken.decimals))
    } else {
      setZapInputValue('')
    }
  }, [bestZap, selectedZapToken])
  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(zapRefreshIncrementor + 1)
  }, [zapRefreshIncrementor])

  const setMax = useCallback(() => {
    setInputValue(selectedTokenDolomiteBalance?.toFixed(selectedTokenDolomiteBalance.currency.decimals ?? 6) ?? '0')
    setIsMaxSelected(true)
  }, [selectedTokenDolomiteBalance])

  const parsedZapInputValue = useMemo(() => {
    const rawParsedZapAmount = tryParseAmount(zapInputValue, selectedZapToken)
    if (!rawParsedZapAmount) {
      return undefined
    }
    return rawParsedZapAmount
  }, [zapInputValue, selectedZapToken])
  const changedZapBalanceValue = useMemo(
    () =>
      parsedZapInputValue ? (selectedZapTokenDolomiteBalance ?? ZERO_FRACTION).add(parsedZapInputValue) : undefined,
    [selectedZapTokenDolomiteBalance, parsedZapInputValue],
  )
  const defaultAccountNumber = defaultMarginAccount.accountNumber
  const { callback: submitZap, error: submitZapError } = useZapExactTokensForTokens(
    defaultAccountNumber,
    defaultAccountNumber,
    zaps,
    ZAP_EITHER,
    selectedSpecialAsset?.isIsolationMode
      ? selectedSpecialAsset
      : selectedZapSpecialAsset?.isIsolationMode
      ? selectedZapSpecialAsset
      : undefined,
    isMaxSelected,
  )

  const insufficientLiquidityError = useMemo(() => {
    const protocolBalance = protocolBalanceInfoMap[selectedToken.address ?? '']
    if (!parsedInputValue || !protocolBalance || selectedSpecialAsset?.isIsolationMode) {
      return false
    }
    return protocolBalance.lessThan(parsedInputValue)
  }, [parsedInputValue, protocolBalanceInfoMap, selectedToken, selectedSpecialAsset?.isIsolationMode])

  const showSupplyCapError = useMemo(() => {
    const totalSupplyWei = marketTotalWeiMap[selectedZapToken?.address ?? '']
    const supplyMaxWei = marketRiskInfoMap[selectedZapToken?.address ?? '']?.supplyMaxWei
    if (!supplyMaxWei || !totalSupplyWei || supplyMaxWei.equalTo(ZERO_FRACTION) || !parsedZapInputValue) {
      return false
    }
    return totalSupplyWei.supplyWei.add(parsedZapInputValue).greaterThan(supplyMaxWei)
  }, [parsedZapInputValue, marketRiskInfoMap, marketTotalWeiMap, selectedZapToken])

  const handleZap = useCallback(async () => {
    if (!submitZap || !parsedInputValue || !parsedZapInputValue) {
      console.error('Callback is not defined for submitting borrow action:', submitZapError)
      return
    }

    const amount = formatAmount(parsedInputValue)
    const unwrappedSymbol = cleanCurrencySymbol(parsedInputValue.currency)
    const unwrappedZapSymbol = cleanCurrencySymbol(parsedZapInputValue.currency)
    const zapAmount = formatAmount(parsedZapInputValue)
    const actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol}`
    setIsAttemptingTx(true)
    setTransactionPendingText(actionText)

    submitZap()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message?.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [
    parsedInputValue,
    parsedZapInputValue,
    submitZap,
    submitZapError,
    setIsAttemptingTx,
    setTransactionPendingText,
    setTxHash,
  ])

  const createVault = useCallback(async () => {
    if (!createVaultCallback || !parsedZapInputValue) {
      console.error('Callback is not defined for creating a vault:', createVaultError)
      return
    }
    const symbol = cleanCurrencySymbol(selectedZapToken)
    const actionText = `Creating ${symbol} vault`
    setIsAttemptingTx(true)
    createVaultCallback()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
      })
      .catch(e => {
        if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Transaction Rejected')
        } else {
          setErrorMessage(e.message)
        }
        setIsAttemptingTx(false)
      })
  }, [
    createVaultCallback,
    createVaultError,
    setTxHash,
    setPendingHash,
    setIsAttemptingTx,
    setErrorMessage,
    parsedZapInputValue,
    selectedZapToken,
  ])

  const onDismiss = useCallback(() => {
    setInputValue('')
    closeZap()
  }, [closeZap, setInputValue])

  const handleSubmit = useCallback(() => {
    if (noVault) {
      createVault()
    }
    handleZap()
  }, [handleZap, noVault, createVault])

  const lendingSupplyInterestRate = selectedToken
    ? interestRateMap[selectedToken.address]?.supplyInterestRate
    : undefined
  const totalSupplyInterestRate = selectedToken
    ? interestRateMap[selectedToken.address]?.totalSupplyInterestRate
    : undefined
  const tokenInterestRateParts = selectedToken
    ? interestRateMap[selectedToken.address]?.outsideSupplyInterestRateParts
    : undefined
  const lendingZapSupplyInterestRate = selectedZapToken
    ? interestRateMap[selectedZapToken.address]?.supplyInterestRate
    : undefined
  const totalZapSupplyInterestRate = selectedZapToken
    ? interestRateMap[selectedZapToken.address]?.totalSupplyInterestRate
    : undefined
  const tokenZapInterestRateParts = selectedZapToken
    ? interestRateMap[selectedZapToken.address]?.outsideSupplyInterestRateParts
    : undefined

  const submitButtonDisabled = useMemo(() => {
    return (
      isGeoRestricted ||
      isAttemptingTx ||
      isTxPending ||
      !inputValue ||
      decimalsExceeded ||
      insufficientLiquidityError ||
      showSupplyCapError ||
      isInsufficientBalance ||
      isInvalidChain ||
      //gmFeeError ||
      !!minGmTradeError ||
      isLoadingZapTradeData ||
      bothIso
    )
  }, [
    isGeoRestricted,
    isAttemptingTx,
    isTxPending,
    inputValue,
    decimalsExceeded,
    insufficientLiquidityError,
    showSupplyCapError,
    isInsufficientBalance,
    isInvalidChain,
    minGmTradeError,
    isLoadingZapTradeData,
    bothIso,
  ])

  const submitTooltipText = useMemo(() => {
    return isGeoRestricted
      ? 'Asset not available in your region'
      : isAttemptingTx
      ? 'Awaiting signature...'
      : bothIso
      ? 'Cannot zap an isolation mode asset to another isolation mode asset'
      : isTxPending
      ? 'Awaiting confirmation...'
      : !inputValue
      ? 'No amount entered'
      : decimalsExceeded
      ? 'Too many numbers after decimal'
      : isInsufficientBalance
      ? 'Insufficient balance'
      : insufficientLiquidityError
      ? 'Insufficient supply liquidity for token'
      : showSupplyCapError
      ? 'Supply cap exceeded'
      : isLoadingZapTradeData
      ? 'Trade Loading'
      : /*: gmFeeError
      ? 'Insufficient ETH in wallet for GM fee'*/
      minGmTradeError
      ? 'Min trade size for GM assets is $1'
      : 'Open borrow'
  }, [
    isGeoRestricted,
    isAttemptingTx,
    bothIso,
    isTxPending,
    inputValue,
    decimalsExceeded,
    isInsufficientBalance,
    insufficientLiquidityError,
    showSupplyCapError,
    isLoadingZapTradeData,
    minGmTradeError,
  ])

  useEffect(() => {
    if (zapError?.includes('slippageTolerance')) {
      setZapErrorMessage(t('zapSlippageError'))
    }
  }, [zapError, t])

  const displayErrorMessage = !isLoadingZapTradeData && !!parsedInputValue && !!errorMessage

  return (
    <Modal isOpen={open} onDismiss={onDismiss} maxHeight={700} maxWidthPx={400}>
      <NewBorrowWrapper
        showTradeDetails={!!bestZap || isLoadingZapTradeData}
        tall={tall}
        extraTall={extraTall}
        ref={wrapperRef}
        disabled={isInvalidChain}
      >
        {isInvalidChain && (
          <DisabledOverlay>Zap is coming soon to Dolomite on {NETWORK_LABELS[chainId]}!</DisabledOverlay>
        )}
        <OpenText>Swap Assets</OpenText>
        <SubtitleText>
          Dolomite utilizes DEX aggregators and direct protocol integrations to get you the best swap price available.
        </SubtitleText>
        <CloseWrapper onClick={() => onDismiss()}>
          <Close />
        </CloseWrapper>
        <IsolationModeExplainer visible={bothIso} />
        <GeoRestrictionExplainer visible={isGeoRestricted && !bothIso} />
        <GmFeeExplainer visible={showGmExplainer && !bothIso} />
        <VaultExplainer visible={noVault} bothIso={bothIso} gmExplainer={showGmExplainer && !bothIso} />
        <InputWrapper tall={tall} extraTall={extraTall}>
          <BalanceRow>
            <BalanceWrapper>
              {t('dolomiteBalanceText')}
              {` `}
              <Balance>{formatAmount(selectedTokenDolomiteBalance)}</Balance>
              {changedBalanceValue && selectedTokenDolomiteBalance && (
                <NewValueWrapper
                  isNegative={changedBalanceValue.asFraction.lessThan(
                    selectedTokenDolomiteBalance.asFraction ?? ZERO_FRACTION,
                  )}
                >
                  <ArrowRight />
                </NewValueWrapper>
              )}
              <NewAmountWrapper>
                {changedBalanceValue && selectedTokenDolomiteBalance && (
                  <NewValueWrapper
                    isNegative={changedBalanceValue.asFraction.lessThan(
                      selectedTokenDolomiteBalance.asFraction ?? ZERO_FRACTION,
                    )}
                  >
                    <NewValue error={changedBalanceValue.lessThan(ZERO_FRACTION)}>
                      {formatAmount(changedBalanceValue)}
                    </NewValue>
                  </NewValueWrapper>
                )}
                <Ticker>{cleanCurrencySymbol(selectedToken) ?? '-'}</Ticker>
              </NewAmountWrapper>
            </BalanceWrapper>
            <MaxButton disabled={isGeoRestricted} onClick={() => !isGeoRestricted && setMax()}>
              Max
            </MaxButton>
          </BalanceRow>
          <InputOverflowFix>
            <StyledInput
              onChange={updateInput}
              multiline={false}
              fullWidth
              spellCheck={false}
              placeholder={'0.00'}
              value={inputValue}
              variant='amountInput'
              disableUnderline={true}
              endAdornment={''}
              disabled={isGeoRestricted || isAttemptingTx || isTxPending || isInvalidChain}
            />
          </InputOverflowFix>
          <TokenSelector
            expanded={tokenSelectOpen}
            onClick={() => !tokenSelectOpen && !isAttemptingTx && setTokenSelectOpen(true)}
            ref={wrapperRef}
          >
            <TokenSelectRow onClick={() => selectToken(selectedToken)}>
              {cleanCurrencySymbol(selectedToken)}
            </TokenSelectRow>
            <ArrowDown flipped={tokenSelectOpen} />
          </TokenSelector>
        </InputWrapper>
        <>
          {tokenInterestRateParts && tokenInterestRateParts.length > 0 ? (
            <StyledTooltip
              title={
                <YieldTooltip
                  interestRateParts={tokenInterestRateParts}
                  lendingYield={lendingSupplyInterestRate}
                  totalSupplyYield={totalSupplyInterestRate}
                  showYieldAsApr={showYieldAsApr}
                  isBorrowRate={false}
                />
              }
              placement={'top'}
              arrow={true}
            >
              <RateWrapper>
                {`${cleanCurrencySymbol(selectedToken)} APR: `}
                <OutsideRate>
                  {totalSupplyInterestRate
                    ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                      ? '< 0.01'
                      : totalSupplyInterestRate.toFixed(2)
                    : '-'}
                  %
                  <StyledAutoAwesomeIcon />
                </OutsideRate>
              </RateWrapper>
            </StyledTooltip>
          ) : (
            <RateWrapper>
              APR:{' '}
              <span>
                {totalSupplyInterestRate
                  ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                    ? '< 0.01'
                    : totalSupplyInterestRate.toFixed(2)
                  : '-'}
                %
              </span>
            </RateWrapper>
          )}
        </>
        <SwitchArrow>
          <SwitchArrowInner>
            <ArrowDownwardIcon onClick={() => onSwitchTokens()} />
          </SwitchArrowInner>
        </SwitchArrow>
        <BottomRowWrapper>
          <ZapInputWrapper>
            <BalanceRow>
              <BalanceWrapper>
                {t('dolomiteBalanceText')}
                {` `}
                <Balance>{formatAmount(selectedZapTokenDolomiteBalance)}</Balance>
                {changedZapBalanceValue && selectedZapTokenDolomiteBalance && (
                  <NewValueWrapper
                    isNegative={changedZapBalanceValue.asFraction.lessThan(
                      selectedZapTokenDolomiteBalance.asFraction ?? ZERO_FRACTION,
                    )}
                  >
                    <ArrowRight />
                  </NewValueWrapper>
                )}
                <NewAmountWrapper>
                  {changedZapBalanceValue && selectedZapTokenDolomiteBalance && (
                    <NewValueWrapper
                      isNegative={changedZapBalanceValue.asFraction.lessThan(
                        selectedZapTokenDolomiteBalance.asFraction ?? ZERO_FRACTION,
                      )}
                    >
                      <NewValue error={changedZapBalanceValue.lessThan(ZERO_FRACTION)}>
                        {formatAmount(changedZapBalanceValue)}
                      </NewValue>
                    </NewValueWrapper>
                  )}
                  <Ticker>{cleanCurrencySymbol(selectedZapToken) ?? '-'}</Ticker>
                </NewAmountWrapper>
              </BalanceWrapper>
            </BalanceRow>
            <InputOverflowFix>
              <StyledInput
                onChange={updateAdvancedInput}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'0.00'}
                value={zapInputValue}
                variant={'amountInput'}
                disableUnderline={true}
                endAdornment={''}
                disabled={true /*!selectedAdvancedToken || isTxPending || isAttemptingTx*/}
              />
            </InputOverflowFix>
            <TokenSelector
              expanded={zapTokenSelectOpen}
              onClick={() => !zapTokenSelectOpen && selectedZapToken && setZapTokenSelectOpen(true)}
            >
              <TokenSelectRow>{cleanCurrencySymbol(selectedZapToken)}</TokenSelectRow>
              <ArrowDown flipped={false} />
            </TokenSelector>
          </ZapInputWrapper>
          <>
            {tokenZapInterestRateParts && tokenZapInterestRateParts.length > 0 ? (
              <StyledTooltip
                title={
                  <YieldTooltip
                    interestRateParts={tokenZapInterestRateParts}
                    lendingYield={lendingZapSupplyInterestRate}
                    totalSupplyYield={totalZapSupplyInterestRate}
                    showYieldAsApr={showYieldAsApr}
                    isBorrowRate={false}
                  />
                }
                placement={'top'}
                arrow={true}
              >
                <RateWrapper bottom>
                  {`${cleanCurrencySymbol(selectedZapToken)} APR: `}
                  <OutsideRate>
                    {totalZapSupplyInterestRate
                      ? totalZapSupplyInterestRate.greaterThan(ZERO_PERCENT) &&
                        totalZapSupplyInterestRate.lessThan(ONE_BIPS)
                        ? '< 0.01'
                        : totalZapSupplyInterestRate.toFixed(2)
                      : '-'}
                    %
                    <StyledAutoAwesomeIcon />
                  </OutsideRate>
                </RateWrapper>
              </StyledTooltip>
            ) : (
              <RateWrapper bottom>
                APR:{' '}
                <span>
                  {totalZapSupplyInterestRate
                    ? totalZapSupplyInterestRate.greaterThan(ZERO_PERCENT) &&
                      totalZapSupplyInterestRate.lessThan(ONE_BIPS)
                      ? '< 0.01'
                      : totalZapSupplyInterestRate.toFixed(2)
                    : '-'}
                  %
                </span>
              </RateWrapper>
            )}
          </>
          <DetailsWrapper>
            <BorrowTradeDetails
              bestZap={bestZap}
              isLoading={!bestZap && isLoadingZapTradeData}
              refreshZapData={refreshZapData}
              showError={!!zapError}
              open={true}
              small={true}
              loadingDisabled={!bestZap && !isLoadingZapTradeData}
              priceImpact={bestZap?.priceImpact}
            />
          </DetailsWrapper>
          <ErrorMessage isVisible={displayErrorMessage || !!zapErrorMessage}>
            <ErrorText>{!!errorMessage || !!zapErrorMessage || ' '}</ErrorText>
          </ErrorMessage>
          <DepositedButtonWrapper>
            <DepositedValuesWrapper>
              <DepositedCrypto>
                {formatAmount(parsedInputValue)}
                {` `}
                {cleanCurrencySymbol(selectedToken) ?? '-'}
              </DepositedCrypto>
              <DepositedUSD>{formatAmount(inputFiatValueAmount, 2, true, '$0.00', true)}</DepositedUSD>
            </DepositedValuesWrapper>
            <StyledTooltip placement='top' title={submitTooltipText} hideTooltip={!submitButtonDisabled}>
              <SubmitButtonWrapper wide={!(isAttemptingTx || isTxPending) && noVault}>
                <SubmitButton disabled={submitButtonDisabled} onClick={handleSubmit}>
                  {isAttemptingTx || isTxPending ? (
                    <CircularProgress />
                  ) : noVault ? (
                    <WarningIconWrapper>
                      <ShieldIcon /> Create Vault
                    </WarningIconWrapper>
                  ) : (
                    <ZapButtonWrapper>
                      <BoltIcon /> ZAP
                    </ZapButtonWrapper>
                  )}
                </SubmitButton>
              </SubmitButtonWrapper>
            </StyledTooltip>
          </DepositedButtonWrapper>
        </BottomRowWrapper>
        {!(zapTokenSelectOpen || tokenSelectOpen) ? null : (
          <CurrencyModal
            tokens={tokenSelectOpen ? inputCurrencyList : outputCurrencyList}
            balances={dolomiteBalanceData}
            fiatBalances={dolomiteBalanceUsdData}
            isOpen={zapTokenSelectOpen || tokenSelectOpen}
            onDismiss={zapTokenSelectOpen ? closeZapModal : closeModal}
            currencySelect={zapTokenSelectOpen ? selectZapToken : selectToken}
            balanceTitle={t('dolomiteBalance')}
            borrow
          />
        )}
      </NewBorrowWrapper>
    </Modal>
  )
}, ZapModalComparator)

function ZapModalWrapper({ open, closeZap, presetToken }: ZapModalProps) {
  return (
    <Modal isOpen={open} onDismiss={closeZap} maxHeight={90} maxWidthPx={800}>
      {!open ? <></> : <ZapModal open={open} closeZap={closeZap} presetToken={presetToken} />}
    </Modal>
  )
}

export default ZapModalWrapper
