import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import Modal from '../../components/Modal'
import { useTranslation } from 'react-i18next'
import { BorrowPosition, BorrowPositionAmount } from '../../types/borrowPositionData'
import { StrategyInfo, useStrategyAmounts } from './StrategiesList'
import { Asset, AssetList, AssetTitle, THREE_DAYS_MILLIS, TokenLogo } from './index'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { useHistoricalInterestRateTimestamp, useInterestRateData } from '../../types/interestRateData'
import { formatAmount } from '../../utils/formatAmount'
import { getPositionHealth, PositionHealthTooltip } from '../Borrow/BorrowPositionRow'
import { CurrencyAmount, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import toDate from '../../utils/toDate'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Button from '@material-ui/core/Button'
import {
  useZapExactTokensForTokens,
  ZapEither,
  ZapEventType,
  ZapTransferType,
  ZapType,
} from '../../hooks/useExecuteZap'
import { useActiveDolomiteZapClient } from '../../apollo/client'
import { useGetZapExactTokensForTokensParams } from '../../hooks/useGetZapParams'
import { useDefaultFiatValuesWithLoadingIndicator, useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import BorrowTradeDetails from '../Borrow/BorrowPosition/BorrowTradeDetails'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { ZERO_FRACTION, ZERO_PERCENT, ChainId } from '../../constants'
import ReactGA from 'react-ga'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import getLogoOrDefault from '../../components/common/TokenLogos'
import {
  Column,
  Header,
  ModalInner,
  StrategyDescription,
  TokenValue,
  YieldBreakdown,
  CloseSteps,
  CloseStrategyStep,
  StrategyProgress,
  StepSubtitle,
  StrategyText,
  Circle,
  ProgressLine,
} from './CreatePositionModal'
import calculateAprToApy from '../../utils/calculateAprToApy'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { Transfer } from '../../types/transferData'
import { createFraction } from '../../types/gqlTypeHelpers'
import { InterestRatePartCategory } from '../../types/InterestRatePart'
import useNetInterestRate, { useStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { RefreshFrequency } from '../../state/chain/hooks'
import { useActiveWeb3React } from '../../hooks'
import { tryParseAmount } from '../../state/trade/hooks'
import { PageSizeContext } from '../App'
import { useMarketsTotalWeiData } from '../../hooks/useDolomiteMarginProtocol'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useTokenBalances } from '../../state/wallet/hooks'
import { useDolomiteMarginContract } from '../../hooks/useContract'
import Checkmark from '@material-ui/icons/Check'
import { useCloseBorrowPosition } from '../../hooks/useBorrowPositionProtocol'
import CircularProgress from '@material-ui/core/CircularProgress'

enum StrategyStage {
  ZAP = 0,
  CLOSE = 1,
  COMPLETE = 2,
}

const ManageStrategyWrapper = styled.div`
  width: 100%;
  padding: 24px 32px;
  margin-bottom: 15px;
`

export const StrategyStats = styled.div`
  width: 100%;
  /*display: flex;
    justify-content: space-between;*/
`

export const EquityStats = styled.div`
  width: 100%;
  text-align: center;
`

export const BasicStats = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
`

const StatsLeft = styled.div`
  width: fit-content;
`

const StatsRight = styled.div`
  width: fit-content;
  text-align: right;
`

export const CurrentYieldTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -8px;
  margin-top: 5px;

  svg {
    transform: translateX(-3px) !important;
  }
`

export const CurrentYield = styled.div<{ isNegative?: boolean }>`
  font-size: 40px;
  color: ${({ theme, isNegative }) => (isNegative ? theme.yellow2 : theme.green1)};
  margin-top: -3px;
  width: 100%;
  display: table;
  vertical-align: bottom;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.text1};
  }
`

export const StatRow = styled.div`
  width: fit-content;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;

  > * {
    font-size: 14px !important;
  }
`

export const InterestRateStats = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const ExternalRewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  margin-top: -5px;
`

export const ExternalRewardsTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: -2px;
  color: ${({ theme }) => theme.text2};
`

export const ExternalRewardsSubtitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.text3};
`

export const ExternalRewardsHeader = styled.div`
  display: flex;
  width: 100%;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 2px;
`

export const NameColumn = styled.div`
  width: 39%;
`

export const TypeColumn = styled.div`
  width: 25%;
`

export const YieldColumn = styled.div`
  width: 18%;
`

export const ClaimLink = styled.div`
  width: 18%;
`

export const ClaimButton = styled(Button)`
  width: 100%;
  font-size: 10px !important;
  color: ${({ theme }) => theme.text1} !important;
  background-color: ${({ theme }) => theme.bg5} !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: -3px !important;
  text-transform: capitalize !important;
`

export const ExternalRewardsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 11px;
`

const ExternalReward = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`

const ExternalRewardTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-left: 8px;
`

const ExternalRewardValue = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-left: 8px;
`

export const InterestRateLeft = styled.div`
  width: 60%;
  text-align: left;
`

export const InterestRateRight = styled.div`
  width: 40%;
  text-align: right;
  padding-top: 23px;
`

const PendleDisclaimer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 11px;
  color: #d5d6e1;
  font-weight: 500;
  margin-bottom: 17px;
  margin-top: -23px;

  span {
    color: ${({ theme }) => theme.green1};
  }

  svg {
    transform: translate(-3px, 2px);
  }
`

const AprText = styled.span`
  margin-left: 3px;
`

export const AvgYieldTitle = styled.div`
        /*font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -10px;*/
    font-size: 13px;
    font-weight: 600;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
    margin-bottom: -10px;
`

export const AvgYieldValue = styled.div<{ isNegative?: boolean }>`
{
        /*font-size: 18px;
  color: ${({ theme, isNegative }) => (isNegative ? theme.yellow1 : theme.green1)};
  margin-top: -10px;
  width: 100%;
  display: table;
  vertical-align: bottom;
  margin-bottom: 5px;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }*/
} font-size: 24px;
    color: ${({ theme, isNegative }) => (isNegative ? theme.yellow1 : theme.green1)};
    margin-top: -10px;
    width: 100%;
    display: table;
    vertical-align: bottom;
    margin-bottom: 5px;

    span {
        font-size: 13px;
        color: ${({ theme }) => theme.text2};
    }
`

const CurrentPosition = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 0 18px 18px;
  margin-bottom: 8px;

  > div {
    height: fit-content;
  }
`

const DetailsWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  max-height: ${({ isVisible }) => (isVisible ? '500' : '0')}px;
  overflow: hidden;
  height: fit-content;
  transition: max-height 0.3s ease-in-out;

  > div:nth-of-type(1) {
    margin-top: 0 !important;
    padding-top: 6px !important;
  }
`

const DescriptionTextWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
`

const SupplyCapWarning = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.yellow1};

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 28px;
  margin-bottom: 15px;
`

const StrategyTitle = styled.div`
  font-size: 22px;
  font-weight: 100;
`

const StrategySubtitle = styled.div`
  margin-top: -6px;
  margin-bottom: 6px;
  height: 12px;
`

const ChainLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 3px;
  margin-left: 4px;
  vertical-align: top;
`

const Expiration = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  font-size: 12px;
  /*margin: 3px 4px 1px 8px;*/
  margin-left: 10px;
  vertical-align: top;
`

const ExpirationTitle = styled.span`
  color: ${({ theme }) => theme.text3};
`

const TagSpacer = styled.div``

const EquityWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
`

export const EquityTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -3px;

  + div {
    width: 100%;
    margin-top: -5px;
    display: inline-block;
    vertical-align: top;
  }

  svg {
    transform: translate(-3px, 2px);
    margin-right: -8px;
  }
`

export const EquityValue = styled.div`
  /*font-size: 28px;*/
  font-size: 38px;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  display: inline-block;
  vertical-align: top;
  width: 100%;

  span {
    /*font-size: 20px;*/
    font-size: 28px;
    color: ${({ theme }) => theme.text2};
  }
`

export const EquityValueUsd = styled.div`
  /*font-size: 13px;*/
  font-size: 21px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: -5px;
`

const HealthWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
`

const HealthFactorTitle = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
`

export const HealthFactor = styled.div<{ health: number }>`
  font-size: 12px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};

  svg {
    margin-bottom: -4px;
  }
`

export const StrategyHealth = styled(HealthFactor)`
  font-weight: 600;
`

export const InfinityWrapper = styled.div<{ expanded: boolean }>`
  width: 14px;

  svg {
    transition: all 0.3s ease-in-out;
    width: 0.8em;
    height: 0.8em;
  }
`

const OpenedWrapper = styled.div`
  width: fit-content;
  margin-top: 0;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
`

const OpenedTitle = styled.span`
  color: ${({ theme }) => theme.text3};
`

const OpenedValue = styled.span``

const CloseWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.bg3} !important;
  color: ${({ theme }) => theme.text1} !important;
  width: 100% !important;
  text-align: center !important;
  padding: 8px 0 !important;
  text-transform: capitalize !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  margin-bottom: 15px !important;

  :hover {
    background-color: ${({ theme }) => theme.bg4};
  }

  ${({ disabled }) =>
    disabled ? `pointer-events: none; cursor: none !important;` : `pointer-events: auto !important;`}
  > span > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 3px;
    margin-bottom: 2px;
  }

  svg {
    color: white !important;
  }
`

export function getInterestRateCategoryString(interestRateCategory: InterestRatePartCategory): string {
  switch (interestRateCategory) {
    case InterestRatePartCategory.REWARDS:
      return 'Rewards'
    case InterestRatePartCategory.POINTS:
      return 'Points'
    case InterestRatePartCategory.NATIVE_YIELD:
      return 'Native Yield'
    case InterestRatePartCategory.MINERALS:
      return 'Minerals'
    case InterestRatePartCategory.NONE:
      return 'None'
  }
}

export interface AverageYieldData {
  rate: Fraction | undefined
  period: number | undefined
}

interface ManageStrategyProps {
  isOpen: boolean
  memoizedOnDismiss: () => void
  position: BorrowPosition
  strategy: StrategyInfo
  totalYieldSelected: boolean
  interestRateMap: Record<string, (Percent | undefined)[]>
  positionTransfers: Transfer[]
  transactionLoading: () => void
}

function ManageStrategyModalComparator(prevProps: ManageStrategyProps, nextProps: ManageStrategyProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.memoizedOnDismiss === nextProps.memoizedOnDismiss &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD &&
    prevProps.strategy === nextProps.strategy
  )
}

const ManageStrategyModal = React.memo(function ManageStrategyModalComponent({
  isOpen,
  memoizedOnDismiss,
  position,
  strategy,
  totalYieldSelected,
  interestRateMap,
  positionTransfers,
  transactionLoading,
}: ManageStrategyProps) {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [totalYield, setTotalYield] = useState(totalYieldSelected)
  const [, setPendingHash] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [zapErrorMessage, setZapErrorMessage] = useState<string | undefined>(undefined)
  const isGmAsset =
    strategy.collateralAssets.length > 0 &&
    (strategy?.collateralAssets[0]?.name?.includes('GM: ') || strategy?.collateralAssets[0]?.name?.includes('GLV: '))
  const incompleteClosing = useMemo(() => position.borrowAmounts.length === 0, [position.borrowAmounts.length])
  const [strategyStage, setStrategyStage] = useState<StrategyStage>(
    incompleteClosing ? StrategyStage.CLOSE : StrategyStage.ZAP,
  )
  const allTokens = useMemo(() => [...strategy.collateralAssets, ...strategy.debtAssets], [strategy])
  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(allTokens)
  // TODO - temp, while designed only for a single collateral and debt asset
  const collateralAsset = strategy.collateralAssets[0]
  const collateralSymbol = cleanCurrencySymbol(collateralAsset)
  const debtAsset = strategy.debtAssets[0]
  const debtSymbol = cleanCurrencySymbol(strategy.debtAssets[0])
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const isTxPending = useIsTransactionPending(txHash)
  const isGeoRestrictedCollateral = useIsTokenGeoRestricted(collateralAsset.wrapped.address)
  const isGeoRestrictedDebt = useIsTokenGeoRestricted(debtAsset.wrapped.address)
  const isGeoRestricted = isGeoRestrictedCollateral || isGeoRestrictedDebt
  const [showYieldAsApr] = useShowYieldAsApr()
  const initialDeposit = positionTransfers[0]
  const [showClose, setShowClose] = useState(strategyStage === StrategyStage.CLOSE)
  const [zapAmount, setZapAmount] = useState<CurrencyAmount<Token> | undefined>(undefined)
  const [supplyAmounts, borrowAmounts] = useStrategyAmounts(strategy)
  const { data: interestRateMapData } = useInterestRateData(strategy.chain)
  const [fiatPriceMap] = useFiatPricesWithLoadingIndicator(undefined, strategy.chain, RefreshFrequency.Medium)
  const [marketTotalWeiMap] = useMarketsTotalWeiData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const dolomiteMarginContract = useDolomiteMarginContract()
  const protocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, [collateralAsset, debtAsset])
  const ratesAtOpen = useHistoricalInterestRateTimestamp(
    position?.supplyAmounts[0].token,
    position?.openTimestamp,
    chainId,
  )
  const pendleFixedRate = ratesAtOpen?.find(rate => rate.label === 'Pendle Fixed APR')?.supplyInterestRate

  const historicalInterestRate = useStrategyInterestRate(
    supplyAmounts,
    borrowAmounts,
    fiatPriceMap,
    interestRateMapData,
    true,
    true,
    undefined,
    undefined,
    undefined,
    pendleFixedRate,
  )
  const historicalRateConverted = showYieldAsApr
    ? historicalInterestRate
    : calculateAprToApy(historicalInterestRate ?? ZERO_PERCENT)
  const historicalPeriod = useMemo(() => {
    const collateralDataDate = interestRateMapData[strategy.collateralAssets[0].address]?.historicalRates.startTimestamp
    const debtDataDate = interestRateMapData[strategy.debtAssets[0].address]?.historicalRates.startTimestamp
    if (collateralDataDate === undefined || debtDataDate === undefined) {
      return undefined
    }
    const moreRecentDate = collateralDataDate > debtDataDate ? collateralDataDate : debtDataDate
    const today = new Date()
    const differenceInMs = today.getTime() - moreRecentDate.getTime()
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))
    return (differenceInDays < 30 ? differenceInDays : 30).toString()
  }, [interestRateMapData, strategy.collateralAssets, strategy.debtAssets])
  const parsedHistoricalRate = useMemo(() => {
    return parseFloat(historicalRateConverted?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalRateConverted, 2)
      : '0.00%'
  }, [historicalRateConverted])

  const steps = strategy.steps ?? 1
  const leverage = strategy.leverage ?? 1
  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])
  const expirationDateParsed = expirationDate ? new Date(expirationDate) : undefined
  const showExpirationWarning = expirationDateParsed
    ? expirationDateParsed.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false

  const { data: assetInterestRateMap } = useInterestRateData(strategy.chain)
  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  //const interestRate = interestRateMap[strategy.collateralAssets[0].address]
  const positionInterestRate = useNetInterestRate(position, pendleFixedRate)
  const parsedPositionInterestRate = useMemo(() => {
    return parseFloat(positionInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(positionInterestRate, 2)
      : '0.00%'
  }, [positionInterestRate])
  const parts = assetInterestRateMap?.outsideSupplyInterestRateParts
  const collateralInterestRateParts =
    assetInterestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const collateralLendingYield = showYieldAsApr
    ? assetInterestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate
    : calculateAprToApy(assetInterestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate ?? ZERO_PERCENT)
  const collateralTotalYield = showYieldAsApr
    ? assetInterestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate
    : calculateAprToApy(
        assetInterestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate ?? ZERO_PERCENT,
      )
  const debtInterestRate = showYieldAsApr
    ? assetInterestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate
    : calculateAprToApy(assetInterestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate ?? ZERO_PERCENT)
  const partsFormatted = useMemo(() => {
    return collateralInterestRateParts
      ? collateralInterestRateParts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [collateralInterestRateParts, leverage, mineralBonus])

  const [positionEquity, positionEquityUsd] = useMemo(() => {
    const positionEquity = position.supplyAmounts[0].amountTokenWei.asFraction.subtract(
      position.totalBorrowUSD.divide(
        position.totalSupplyUSD.divide(position.supplyAmounts[0].amountTokenWei.asFraction),
      ),
    )
    const positionEquityUsd = position.totalSupplyUSD.subtract(position.totalBorrowUSD)
    return [positionEquity, positionEquityUsd]
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD])
  const profit = positionEquityUsd.asFraction.subtract(initialDeposit.amountUSD.asFraction)
  // /*const profitApr = useMemo(() => {
  //   const currentDate = new Date()
  //   const initialDepositDate = new Date(initialDeposit.transaction.timestamp)
  //   const daysBetween = createFraction(
  //     ((currentDate.getTime() - initialDepositDate.getTime()) / (1000 * 60 * 60 * 24)).toFixed(16),
  //   )
  //   if (!daysBetween || daysBetween.equalTo(ZERO_FRACTION)) return undefined
  //   return profit
  //     .divide(initialDeposit.amountUSD.asFraction)
  //     .multiply(365)
  //     .divide(daysBetween)
  //     .multiply(100)
  // }, [profit, initialDeposit])*/

  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position.positionHealth)
  }, [position.positionHealth])

  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(prev => prev + 1)
  }, [])

  //const zapClient = useActiveDolomiteZapClient()
  useEffect(() => {
    if (showClose) {
      const newZapAmount =
        strategyStage !== StrategyStage.CLOSE
          ? position.supplyAmounts[0].amountTokenWei.multiply(
              position.totalBorrowUSD
                .divide(position.totalSupplyUSD)
                .multiply(isGmAsset ? 101 : 104)
                .divide(100),
            )
          : undefined
      if (
        (strategyStage !== StrategyStage.CLOSE && !zapAmount) ||
        (newZapAmount &&
          zapAmount &&
          newZapAmount.currency.equals(zapAmount.currency) &&
          newZapAmount
            .subtract(zapAmount)
            .divide(zapAmount)
            .abs.multiply(1000)
            .greaterThan(5))
      ) {
        setZapAmount(newZapAmount)
      }
    }
  }, [
    isGmAsset,
    position.supplyAmounts,
    position.totalBorrowUSD,
    position.totalSupplyUSD,
    showClose,
    strategyStage,
    zapAmount,
  ])

  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    showClose ? zapAmount : undefined,
    showClose ? debtAsset : undefined,
    true,
    zapRefreshIncrementor,
    position.marginAccount.accountNumber.toString(),
    fiatValueMap,
  )
  const bestZap = useMemo(() => zaps?.[0], [zaps])

  const defaultAccountNumber = useDefaultMarginAccount().accountNumber
  const { callback: submitCloseBorrowPosition, error: submitCloseError } = useCloseBorrowPosition(
    position.marginAccount.accountNumber,
    defaultAccountNumber,
    useMemo(
      () =>
        collateralAsset
          ? strategyStage === StrategyStage.CLOSE
            ? [collateralAsset, debtAsset]
            : [collateralAsset]
          : undefined,
      [collateralAsset, debtAsset, strategyStage],
    ),
    position.specialInfo.isolationModeVaultAddress,
  )

  const zapEither = useMemo<ZapEither | undefined>(() => {
    if (steps === 1) {
      return {
        transfers: [
          {
            amount: position.supplyAmounts[0].amountTokenWei.multiply(ZERO_FRACTION),
            eventType: ZapEventType.None,
            isToTradeAccount: false,
            transferOpts: ZapTransferType.ALL_BALANCE,
          },
          {
            amount: position.borrowAmounts[0].amountTokenWei.multiply(ZERO_FRACTION),
            eventType: ZapEventType.None,
            isToTradeAccount: false,
            transferOpts: ZapTransferType.ALL_BALANCE,
          },
        ],
        zapType: undefined,
      }
    }
    return { zapType: ZapType.SwapCollateral, transfers: undefined }
  }, [position, steps])
  const remaining = useMemo(() => {
    if (!zapAmount || steps > 1) return ZERO_FRACTION
    return position.supplyAmounts[0].amountTokenWei.subtract(zapAmount)
  }, [zapAmount, steps, position.supplyAmounts])
  const zapOutput = useMemo(() => {
    return bestZap ? CurrencyAmount.fromRawAmount(debtAsset, bestZap.expectedAmountOut.toFixed(0)) : undefined
  }, [bestZap, debtAsset])
  const outputRemainder = useMemo(() => {
    return zapOutput && strategyStage !== StrategyStage.CLOSE && position.borrowAmounts[0] && position.borrowAmounts[0]
      ? zapOutput.subtract(position.borrowAmounts[0].amountTokenWei)
      : ZERO_FRACTION
  }, [zapOutput, position.borrowAmounts, strategyStage])
  const summary = useMemo(() => {
    const amount0 = position.supplyAmounts[0]
    const amount1 = position.borrowAmounts[0]
    if (steps > 1 && strategyStage === StrategyStage.ZAP) {
      return `Zapping ${formatAmount(zapAmount)} ${collateralSymbol} to repay borrowed ${formatAmount(
        amount1.amountTokenWei,
      )} ${cleanCurrencySymbol(amount1.token)}`
    }
    if (steps > 1) {
      const secondSupply = position.supplyAmounts[1]
      return `Closed ${strategy.title} ${leverage}x and withdrew ${formatAmount(
        amount0?.amountTokenWei,
      )} ${cleanCurrencySymbol(amount0?.token)} and ${formatAmount(secondSupply?.amountTokenWei)} ${cleanCurrencySymbol(
        secondSupply?.token,
      )} to Dolomite Balance`
    }
    return `Closed ${strategy.title} ${leverage}x and withdrew ${formatAmount(remaining)} ${cleanCurrencySymbol(
      amount0?.token,
    )} and ~${formatAmount(outputRemainder)} ${cleanCurrencySymbol(amount1.token)} to Dolomite Balance`
  }, [
    position.supplyAmounts,
    position.borrowAmounts,
    steps,
    strategyStage,
    strategy.title,
    leverage,
    remaining,
    outputRemainder,
    zapAmount,
    collateralSymbol,
  ])
  const { callback: submitCloseStrategy, error: submitCloseStrategyError } = useZapExactTokensForTokens(
    showClose ? position.marginAccount.accountNumber : undefined,
    showClose ? defaultAccountNumber : undefined,
    showClose ? zaps : undefined,
    zapEither,
    position.specialInfo.specialAsset,
    false,
    summary,
  )

  const closeMultiStepCallback = useCallback(() => {
    if (!submitCloseBorrowPosition) {
      console.error('No close position callback due to error:', submitCloseError)
      return
    }

    const actionText = `Close borrow position and withdraw collateral`
    setIsAttemptingTx(true)
    submitCloseBorrowPosition()
      .then(transactionHash => {
        setTxHash(transactionHash)
        setIsAttemptingTx(false)
        setStrategyStage(StrategyStage.COMPLETE)
        memoizedOnDismiss()
        transactionLoading()
        ReactGA.event({
          category: 'Borrow Position',
          action: 'Open borrow position',
          label: [cleanCurrencySymbol(collateralAsset)].join('/'),
        })
      })
      .catch(e => {
        if (e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient funds to pay gas fee to open transaction.')
        } else {
          console.error(e.message)
        }
        setIsAttemptingTx(false)
      })
  }, [
    submitCloseBorrowPosition,
    submitCloseError,
    collateralAsset,
    setIsAttemptingTx,
    setTxHash,
    memoizedOnDismiss,
    transactionLoading,
  ])

  const closePositionCallback = useCallback(() => {
    if (!submitCloseStrategy) {
      return
    }
    setZapErrorMessage(undefined)
    setIsAttemptingTx(true)
    submitCloseStrategy()
      .then(hash => {
        setTxHash(txHash)
        setIsAttemptingTx(false)
        setPendingHash(hash)
        if (steps > 1) {
          setStrategyStage(StrategyStage.CLOSE)
        } else {
          memoizedOnDismiss()
        }
        ReactGA.event({
          category: 'Strategy',
          action: `Closing strategy ${strategy.title} and withdrawing equity`,
        })
      })
      .catch(e => {
        setIsAttemptingTx(false)
        setTxHash(undefined)
        setPendingHash(undefined)
        if (e.message === 'insufficient-gas' || e.message?.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        } else {
          console.log(e.message)
        }
      })
  }, [steps, memoizedOnDismiss, strategy.title, submitCloseStrategy, txHash])

  // const projectedProfit =
  //   parsedInputValue && interestRate
  //     ? parsedInputValue?.asFraction.add(
  //       parsedInputValue?.asFraction.multiply(interestRate?.asFraction ?? ZERO_FRACTION),
  //     )
  //     : ZERO_FRACTION
  const onConfirm = useCallback(() => {
    if (strategy.steps) {
      if (strategyStage === StrategyStage.ZAP) return closePositionCallback()
      else {
        return closeMultiStepCallback()
      }
    }
    return closePositionCallback()
  }, [closePositionCallback, closeMultiStepCallback, strategy, strategyStage])

  const showSupplyCapError = useMemo(() => {
    if (showClose) {
      const totalSupplyWei = marketTotalWeiMap[debtAsset.address]
      const supplyMaxWei = marketRiskInfoMap[debtAsset.address]?.supplyMaxWei
      if (!supplyMaxWei || !totalSupplyWei || !bestZap) {
        return false
      }
      const amountOut = CurrencyAmount.fromRawAmount(debtAsset, bestZap.expectedAmountOut.toFixed(0))
      return totalSupplyWei.supplyWei.add(amountOut).greaterThan(supplyMaxWei)
    }

    return false
  }, [showClose, marketTotalWeiMap, debtAsset, marketRiskInfoMap, bestZap])

  const selectedSpecialAsset = useSpecialAsset(collateralAsset)
  // /*const insufficientLiquidityError = useMemo(() => {
  //   if (showClose) {
  //     const protocolBalance = protocolBalanceInfoMap[collateralAsset.address]
  //     if (!bestZap || !protocolBalance || selectedSpecialAsset?.isIsolationMode || !zapAmount) {
  //       return false
  //     }
  //     return protocolBalance.lessThan(zapAmount)
  //   }
  //   return false
  // }, [
  //   showClose,
  //   protocolBalanceInfoMap,
  //   collateralAsset.address,
  //   bestZap,
  //   selectedSpecialAsset?.isIsolationMode,
  //   zapAmount,
  // ])*/

  const handleCloseButton = useCallback(() => {
    !showClose && strategyStage !== StrategyStage.CLOSE ? setShowClose(true) : onConfirm()
  }, [onConfirm, showClose, strategyStage])

  const closeLoading = useMemo(() => {
    if (strategyStage === StrategyStage.CLOSE && position.borrowAmounts.length !== 0) return true
    return false
  }, [strategyStage, position.borrowAmounts])

  const submitButtonDisabled = useMemo(() => {
    return (
      isGeoRestricted ||
      isAttemptingTx ||
      isTxPending ||
      closeLoading ||
      //insufficientLiquidityError ||
      showSupplyCapError ||
      //gmFeeError ||
      //!!minGmTradeError ||
      (showClose && !bestZap && strategyStage < StrategyStage.CLOSE)
    )
  }, [
    isGeoRestricted,
    isAttemptingTx,
    isTxPending,
    showSupplyCapError,
    showClose,
    bestZap,
    closeLoading,
    strategyStage,
  ])

  const buttonText = useMemo(() => {
    if (strategy.steps && showClose) {
      if (strategyStage === StrategyStage.ZAP) return 'Zap and Repay'
      else if (strategyStage === StrategyStage.CLOSE) return 'Close Strategy and Withdraw'
    }
    return showClose ? 'Confirm Close' : 'Close Strategy and Withdraw'
  }, [showClose, strategyStage, strategy.steps])

  return (
    <ModalInner>
      <Column>
        <Header
          strategy={strategy}
          totalYield={totalYield}
          showExpirationWarning={showExpirationWarning}
          expirationDate={expirationDate}
          partsFormatted={partsFormatted}
        />
        <YieldBreakdown
          collateralInterestRateParts={collateralInterestRateParts}
          collateralLendingYield={collateralLendingYield}
          collateralTotalYield={collateralTotalYield}
          debtInterestRate={debtInterestRate}
          totalYield={totalYield}
          debtToken={debtAsset}
          showYieldAsApr={showYieldAsApr}
          strategy={strategy}
          leverage={leverage}
          pendleFixedRate={pendleFixedRate}
        />
        <StrategyDescription showDescription={true} strategy={strategy} />
      </Column>
      <Column right>
        <StrategyStats>
          <EquityStats>
            <EquityTitle>
              Your Equity{' '}
              <StyledTooltipWithIcon
                tooltipText={`This is the approximate amount that you will receive after closing this strategy.`}
              />
            </EquityTitle>
            <EquityValue>
              {/* TODO - add the GM asset deposit into equity, need to convert value of 0.001 ETH into the gm asset*/}
              {formatAmount(positionEquity, undefined, true, '-', false)} <span>{collateralSymbol}</span>
            </EquityValue>
            <EquityValueUsd>{formatAmount(positionEquityUsd, 2, true, '-', true)}</EquityValueUsd>
          </EquityStats>
          <BasicStats>
            <StatRow>
              Health:{' '}
              <HealthFactor health={positionHealthFactor}>
                <StyledTooltip
                  title={
                    <PositionHealthTooltip
                      positionHealth={
                        position.positionHealth
                          ? parseFloat(position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                          : undefined
                      }
                      positionHealthFactor={positionHealthFactor}
                      t={t}
                      isNew={false}
                    />
                  }
                  placement='top'
                  arrow={true}
                >
                  <span>
                    {!position.positionHealth ? (
                      <InfinityWrapper expanded={true}>
                        <AllInclusiveIcon />
                      </InfinityWrapper>
                    ) : position.positionHealth.greaterThan('10') ? (
                      '>10'
                    ) : (
                      position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                    )}
                  </span>
                </StyledTooltip>
              </HealthFactor>
            </StatRow>
            <StatRow>
              Opened:{' '}
              <StyledTooltip
                title={`${toDate(position.openTimestamp, true)}`}
                placement={'right'}
                style={{ cursor: 'pointer' }}
              >
                <>{toDate(position.openTimestamp, false, true)}</>
              </StyledTooltip>
            </StatRow>
            {position.expirationTimestamp && (
              <StatRow>
                Expiration:{' '}
                <StyledTooltip
                  title={`${toDate(position.openTimestamp, true)}`}
                  placement={'right'}
                  style={{ cursor: 'pointer' }}
                >
                  <>{toDate(position.expirationTimestamp)}</>
                </StyledTooltip>
              </StatRow>
            )}
          </BasicStats>
        </StrategyStats>
        <InterestRateStats>
          <InterestRateLeft>
            <CurrentYieldTitle>
              Your Current Yield{' '}
              <StyledTooltipWithIcon
                tooltipText={`When you begin utilizing a strategy, assets are borrowed and supplied in proportion to your deposit and the values of the assets utilized in the strategy. As time passes, asset prices change, your collateral grows, and so does your debt, and this means that each active strategy can have yield that varies depending on when you deposited. The yield shown below is what you're currently earning on this active strategy.`}
              />
            </CurrentYieldTitle>
            <CurrentYield isNegative={parsedPositionInterestRate.charAt(0) === '-'}>
              {strategyStage === StrategyStage.CLOSE ? '-' : parsedPositionInterestRate}
              <AprText>{showYieldAsApr ? 'APR' : 'APY'}</AprText>
            </CurrentYield>
          </InterestRateLeft>
          <InterestRateRight>
            <AvgYieldTitle>{historicalPeriod ?? '-'} Day Avg.</AvgYieldTitle>
            <AvgYieldValue>
              {parsedHistoricalRate}
              <AprText>{showYieldAsApr ? 'APR' : 'APY'}</AprText>
            </AvgYieldValue>
            {/*/!*<AvgYieldTitle>Avg. Since Open </AvgYieldTitle>*/}
            {/*/!*<StyledTooltip*!/*/}
            {/*  title={*/}
            {/*    'The APR since opening the position, based only on lending and borrowing interest, not including other rewards.'*/}
            {/*  }*/}
            {/*>*/}
            {/*  /!*<AvgYieldValue isNegative={profitApr && profitApr.lessThan(ZERO_FRACTION)}>*/}
            {/*  /!* TODO - account for APY, confirm veracity *!/*/}
            {/*/!*{profitApr?.toFixed(2) ?? '-'}% <span>APR</span>*/}
            {/*  </AvgYieldValue>*!/*/}
            {/*  /!*<AvgYieldValue isNegative={false}>*/}
            {/*    {'-'}% <span>{showYieldAsApr ? 'APR' : 'APY'}</span>*/}
            {/*  </AvgYieldValue>*/}
            {/*</StyledTooltip>*!/*/}
          </InterestRateRight>
        </InterestRateStats>
        {pendleFixedRate && (
          <PendleDisclaimer>
            Rates are based on a fixed yield of <span>{pendleFixedRate.multiply(100).toFixed(2)}%</span> on{' '}
            {cleanCurrencySymbol(strategy.collateralAssets[0])}{' '}
            <StyledTooltipWithIcon
              tooltipText={`Pendle PT assets have a fixed yield that is locked in when trading into the asset. As such, calculations for the yield of this strategy are based on the fixed yield that ${cleanCurrencySymbol(
                strategy.collateralAssets[0],
              )} offered when the position was opened and the rate was locked in.`}
            />
          </PendleDisclaimer>
        )}
        {collateralInterestRateParts &&
          collateralInterestRateParts.filter(part => part.category !== InterestRatePartCategory.NATIVE_YIELD).length >
            0 && (
            <ExternalRewardsWrapper>
              <ExternalRewardsTitle>Other Rewards</ExternalRewardsTitle>
              <ExternalRewardsSubtitle>
                This strategy earns rewards from sources outside of Dolomite.
              </ExternalRewardsSubtitle>
              <ExternalRewardsHeader>
                <NameColumn>Name</NameColumn>
                <TypeColumn>Type</TypeColumn>
                <YieldColumn>{showYieldAsApr ? 'APR' : 'APY'}</YieldColumn>
                <ClaimLink></ClaimLink>
              </ExternalRewardsHeader>
              {collateralInterestRateParts
                .filter(part => part.category !== InterestRatePartCategory.NATIVE_YIELD)
                .map((part, i) => (
                  <ExternalRewardsRow key={i}>
                    <NameColumn>
                      {part.metadata ? `+${part.metadata.units * leverage} ${part.metadata.unitsLabel}` : part.label}
                    </NameColumn>
                    <TypeColumn>{getInterestRateCategoryString(part.category)}</TypeColumn>
                    <YieldColumn>
                      {part.interestRate
                        ? showYieldAsApr
                          ? calculateAprToApy(part.interestRate).toFixed(2)
                          : part.interestRate.toFixed(2)
                        : '-'}
                      {part.interestRate && '%'}
                    </YieldColumn>
                    <ClaimLink>
                      {part.rewardClaimUrl && (
                        <a href={part.rewardClaimUrl} target={'_blank'} rel={'noreferrer'}>
                          <ClaimButton>Claim</ClaimButton>
                        </a>
                      )}
                    </ClaimLink>
                  </ExternalRewardsRow>
                ))}
            </ExternalRewardsWrapper>
          )}
        <CurrentPosition>
          <AssetList>
            <AssetTitle small>Collateral</AssetTitle>
            {strategyStage === StrategyStage.CLOSE
              ? position.supplyAmounts.map((amount: BorrowPositionAmount, i: number) => (
                  <Asset key={i} small>
                    <TokenLogo small>
                      <img
                        src={getLogoOrDefault(cleanCurrencySymbol(amount.token) ?? '')}
                        alt={`${cleanCurrencySymbol(amount.token)} logo`}
                      />
                    </TokenLogo>
                    {cleanCurrencySymbol(amount.token)}
                    <TokenValue>{formatAmount(amount.amountTokenWei, undefined, true, '-', false)}</TokenValue>
                  </Asset>
                ))
              : strategy.collateralAssets.map((asset: Token, i: number) => (
                  <Asset key={i} small>
                    <TokenLogo small>
                      <img
                        src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                        alt={`${cleanCurrencySymbol(asset)} logo`}
                      />
                    </TokenLogo>
                    {cleanCurrencySymbol(asset)}
                    <TokenValue>
                      {formatAmount(position.supplyAmounts[0].amountTokenWei, undefined, true, '-', false)}
                    </TokenValue>
                  </Asset>
                ))}
          </AssetList>
          <AssetList rightModal>
            <AssetTitle right small>
              Debt
            </AssetTitle>
            {strategyStage !== StrategyStage.CLOSE &&
              strategy.debtAssets.map((asset: Token, i: number) => {
                return (
                  <Asset key={i} small>
                    <TokenValue right>
                      {formatAmount(position.borrowAmounts[0]?.amountTokenWei, undefined, true, '-', false)}
                    </TokenValue>
                    <TokenLogo small>
                      <img
                        src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                        alt={`${cleanCurrencySymbol(asset)} logo`}
                      />
                    </TokenLogo>
                    {cleanCurrencySymbol(asset)}
                  </Asset>
                )
              })}
          </AssetList>
        </CurrentPosition>
        {steps > 1 && showClose && (
          <CloseSteps>
            <CloseStrategyStep expanded={strategyStage === StrategyStage.ZAP}>
              <StrategyProgress>
                <Circle complete={strategyStage > StrategyStage.ZAP}>
                  {strategyStage === StrategyStage.ZAP ? '1' : <Checkmark />}
                </Circle>
                <ProgressLine complete={strategyStage > StrategyStage.ZAP} />
              </StrategyProgress>
              <StrategyText>
                Zap and Repay
                <StepSubtitle visible={strategyStage === StrategyStage.ZAP}>
                  {`Zapping into ${zapOutput ? formatAmount(zapOutput) : '0.00'} ${debtSymbol} to repay position debt.`}
                </StepSubtitle>
              </StrategyText>
            </CloseStrategyStep>
            <CloseStrategyStep expanded={strategyStage === StrategyStage.CLOSE}>
              <StrategyProgress>
                <Circle complete={strategyStage > StrategyStage.CLOSE}>
                  {strategyStage < StrategyStage.COMPLETE ? '2' : <Checkmark />}
                </Circle>
                {steps > 2 && <ProgressLine complete={strategyStage > StrategyStage.CLOSE} />}
              </StrategyProgress>
              <StrategyText>
                Close and Withdraw
                <StepSubtitle visible={strategyStage === StrategyStage.CLOSE}>
                  {`Closing position and withdrawing ${outputRemainder ? formatAmount(outputRemainder) : '0.00'}${' '}
                  ${collateralSymbol} into Dolomite balance.`}
                </StepSubtitle>
              </StrategyText>
            </CloseStrategyStep>
          </CloseSteps>
        )}
        <DetailsWrapper isVisible={showClose && strategyStage < StrategyStage.CLOSE}>
          <BorrowTradeDetails
            bestZap={bestZap}
            isLoading={!bestZap}
            refreshZapData={refreshZapData}
            showError={!!zapError}
            open={true}
            small={true}
            loadingDisabled={!bestZap}
            priceImpact={bestZap?.priceImpact}
          />
          {zapAmount && (
            <DescriptionTextWrapper>
              Will use {formatAmount(zapAmount, undefined, true, '')} {collateralSymbol} to repay {debtSymbol} debt. The
              remaining {formatAmount(remaining, undefined, true, '')} {collateralSymbol} and any excess {debtSymbol}{' '}
              will be returned to your Dolomite Balance.
            </DescriptionTextWrapper>
          )}
        </DetailsWrapper>
        {showSupplyCapError && (
          <SupplyCapWarning>
            {cleanCurrencySymbol(debtAsset)} supply cap reached, please use the Borrow page to repay position debt.
            Guide found{' '}
            <a
              href={'https://docs.dolomite.io/guide/zap#zapping-to-repay-when-an-asset-is-at-its-supply-cap'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              here
            </a>
            .
          </SupplyCapWarning>
        )}
        <CloseWrapper>
          <CloseButton onClick={handleCloseButton} disabled={submitButtonDisabled}>
            {isAttemptingTx || isTxPending || closeLoading ? <CircularProgress /> : buttonText}
          </CloseButton>
        </CloseWrapper>
      </Column>
    </ModalInner>
  )
},
ManageStrategyModalComparator)

function ManageStrategyModalWrapper({
  isOpen,
  memoizedOnDismiss,
  position,
  strategy,
  totalYieldSelected,
  interestRateMap,
  transactionLoading,
  positionTransfers,
}: ManageStrategyProps) {
  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 960
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={memoizedOnDismiss}
      maxHeight={90}
      maxWidthPx={850}
      minWidthPx={isMobile ? 370 : 850}
    >
      {!isOpen || !position ? (
        <></>
      ) : (
        <ManageStrategyModal
          isOpen={isOpen}
          memoizedOnDismiss={memoizedOnDismiss}
          position={position}
          strategy={strategy}
          totalYieldSelected={totalYieldSelected}
          interestRateMap={interestRateMap}
          positionTransfers={positionTransfers}
          transactionLoading={transactionLoading}
        />
      )}
    </Modal>
  )
}

export default ManageStrategyModalWrapper
