import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import BalancesPanel from '../../components/Balances/BalancesPanel'
import BalancesTotal from '../../components/Balances/BalancesTotal'
import FirstVisitPopoverBalances from '../../components/FirstVisitPopoverBalances/FirstVisitPopoverBalances'
import useApprovedToS from '../../hooks/useApprovedToS'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '../../constants'
import BeraBearImg from '../../assets/images/Bera-Bear.svg'

// TODO - thoughts on adding a pie chart in the lower left (update - actually put it in the main balances panel in the upper left, with the text currently at the top moved to the right and aligned left) showing a breakdown of what percentage deposited is what
//        asset? Or do you think most users will just lend a single asset? Or would having that encourage them to
//        diversify?

// TODO - handle extra whitespace at bottom of the page that leads to unnecessary scrolling

const BalancesWrapper = styled.div`
  margin: 25px auto 0;
  max-width: 950px;
  text-align: center;
  width: calc(100% - 62px);

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 619px) {
    width: calc(100% - 32px);
  }

  @media screen and (min-width: 5000px) {
    margin-top: 0;
  }
`

const TopSection = styled.div`
  vertical-align: top;
  width: 100%;
  //max-width: 900px;
  margin-bottom: 15px;
`

const BeraBear = styled.img`
  position: fixed;
  width: 18%;
  left: 1%;
  bottom: 45px;
  z-index: -1;

  @media screen and (max-width: 1400px) {
    position: absolute;
    width: 200px;
    right: calc(50% - 406px);
    left: auto;
    bottom: auto;
    top: 220px;
    transform: scale(-1, 1);
  }

  @media screen and (max-width: 900px) {
    right: 40px;
  }

  @media screen and (max-width: 750px) {
    top: 275px;
  }

  @media screen and (max-width: 500px) {
    width: 150px;
  }
`

export default function Balances() {
  const { chainId } = useActiveWeb3React()
  const [hasVisitedBalances, setHasVisitedBalances] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = useCallback(
    () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
      }),
    [],
  )

  useEffect(() => {
    window.onresize = updateSize
    const localStorageContents = window.localStorage.getItem('dolomite_has_visited_balances')
    if (!localStorageContents || localStorageContents !== 'true') {
      setHasVisitedBalances(false)
    }
    return () => {
      window.onresize = null
    }
  }, [updateSize])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_balances', 'true')
    setHasVisitedBalances(true)
  }, [])

  return (
    <div>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | Balances' : 'Dolomite | Balances'} />
      {(!hasVisitedBalances || !hasApprovedToS) && (
        <FirstVisitPopoverBalances
          width={size.x}
          height={size.y}
          approvedToS={hasApprovedToS}
          setHasVisited={setHasVisited}
          setApprovedToS={setHasApprovedToS}
        />
      )}
      {chainId === ChainId.BERACHAIN && <BeraBear src={BeraBearImg} />}
      <BalancesWrapper>
        <TopSection>
          <BalancesTotal />
        </TopSection>
        <BalancesPanel />
      </BalancesWrapper>
    </div>
  )
}
